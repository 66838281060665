import { useEffect, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { WHITE_RIGHT_ARROW } from "../../assets/image";
import ErrorMessage from "../../components/errorMessage";
import VerificationLayout from "../../layout/apartmentVerificationLayout";
import { $api } from "../../services";
import { fetchBooking, fetchUnitBooking, setBookingId } from "../../store/slices/booking/bookingSlice";
import { AppDispatch } from "../../store/slices/properties/types";
import { RootState } from "../../store/store";

const Booking = () => {
    const router = useNavigate();
    const [ time, setTime ] = useState('')
    const [ timeValid, setTimeValid ] = useState<boolean>(false)
    const [ date, setDate ] = useState<DateObject | DateObject[] | null | any>(null);
    const [ triedToSubmit, setTriedToSubmit ] = useState<boolean>(false);
    const [ pageValid, setPageValid ] = useState<boolean>(false);
    const [ propertyId, setPropertyId ] = useState<string>(''); 
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    
    const dispatch = useDispatch<AppDispatch>();
    const { activeBooking, bookingDate } = useSelector((state: RootState) => state.booking )

    useEffect(() => {
        setDate(bookingDate.prevBookingDate || undefined)
        setTime(bookingDate.prevBookingTime || undefined)
    },[ bookingDate ])


    useEffect(() => {
        const hoursString = time?.split(':')
        const hours = parseInt(hoursString?.[0])
        if (hours >= 10 && hours < 16){
            setTimeValid(true)
        }
        else{
            setTimeValid(false)
        }
    },[ time ])

    useEffect(() => {
        date && timeValid
            ?
            setPageValid(true)
            :
            setPageValid(false)

    }, [ date, timeValid ])


    let { slug } = useParams();

    useEffect(() => {
        if (slug) {
            let slugArray = slug.split("-");
            const slugLength = slugArray.length
            const localPropertyId = slugArray[slugLength-1]
            if( slugLength === 1 ){
                localPropertyId ? dispatch(fetchBooking( localPropertyId )) : toast.error('Booking not found')
            }
            else{
                localPropertyId && setPropertyId(localPropertyId)
                localPropertyId ? dispatch(fetchUnitBooking( localPropertyId )) : toast.error('Booking not found')
            }
        }
    },[ slug, dispatch ])

    const handleValidSubmit = async() => {
        const today = new Date(date);
        const yyyy = today.getFullYear();
        let mm:any = today.getMonth() + 1; // Months start at 0!
        let dd:any = today.getDate();
        
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        
        const formattedDate = yyyy + '-' + mm + '-' + dd;
        const [hours, minutes] = time.split(':');
        const seconds = '00';
        const formattedTime = `${hours}:${minutes}:${seconds}`;

        setIsLoading(true)
        const response = await $api.post('v2/lv/booking',{
            visiting_at: formattedDate+' '+formattedTime,
            unit_id: propertyId || activeBooking.id,
        })
        if($api.isSuccessful(response)){
            router(`/booking-confirmed/${response.data.id}`)
            dispatch(setBookingId(response.data.id))
            setIsLoading(false)
        }
        else{
            setIsLoading(false)
            toast.error('Booking failed')
        }
    }

    const handleSubmit = () => {
        pageValid
            ?
            handleValidSubmit()
            :
            setTriedToSubmit(true)
    }

    const available_dates = activeBooking?.available_dates?.length > 0 ? activeBooking?.available_dates?.map((date:any) => new Date(parseInt(date)).toLocaleDateString()) : '';

    return(
        <VerificationLayout route="Booking">
            <div className="booking">
                <h3>Booking</h3>
                <p>Please select a date</p>
                <div className="booking__time-form">
                    <div className="booking__time-form__input-wrapper">
                        <label htmlFor="date">
                            Date:
                        </label>
                        <Calendar
                            format="YYYY/MM/DD"
                            value={date || undefined}
                            onChange={(value) => { setDate(value)}}
                            mapDays={({ date }: any) => {
                                const today = new Date()
                                const dateString =  new Date(date).toLocaleDateString()
                                if(today > date){
                                    return{
                                        disabled: true,
                                    }
                                }
                                else if(available_dates.includes(dateString)){
                                    return{
                                        disabled: false,
                                    }
                                }
                                else{
                                    return{
                                        disabled: true,
                                        style: { color: "#ccc" },
                                        onClick: () => toast.error("This date is not available for booking")
                                    }
                                }
                            }}
                        />
                        { triedToSubmit && !date && <ErrorMessage message="Select a date"/>}
                    </div>
                    <div className="booking__time-form__input-wrapper">
                        <label htmlFor="time">
                            Time:
                        </label>
                        <div className="time-wrapper">
                            <input
                                type="time"
                                id="time"
                                value={time || undefined}
                                onChange={(e) => setTime( e.target.value)}
                                className={ !timeValid && (time || triedToSubmit)  ? 'invalid' : ''}
                            />
                            { !timeValid && (time || triedToSubmit) && <ErrorMessage message="Time must be between 10:00 - 16:00"/>}
                        </div>
                    </div>
                    <button onClick={() => handleSubmit()}>
                        {
                            isLoading
                                ?
                                    <div className="loading"></div>
                                :
                                    <>
                                        CONFIRM BOOKING
                                        <img src={WHITE_RIGHT_ARROW} alt=""/>
                                    </>
                        }
                    </button>
                </div>
            </div>
        </VerificationLayout>
    )
}
export default Booking;
