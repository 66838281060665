import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ARROW_LEFT, CLOSE_ICON, EMAIL_IMG } from "../../assets/image";
import AuthLayout from "../../layout/authLayout"
import { setModalInactive, setPasswordActive, setSignUpActive } from "../../store/slices/modal/modalSlice";

let currentPinIndex = 0

const ConfirmEmail = () => {
    const [ pin, setPin ] = useState<string[]>(new Array(5).fill(''))
    const [ timeRemaining, setTimeRemaining ] = useState<number>(40);
    const [ activeInput, setActiveInput ] = useState<number>(0)

    const inputRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();

    pin[4] !== '' && dispatch(setPasswordActive()) 

    useEffect(() => {
        const reduceTime = setTimeout(() => {
            timeRemaining >= 1 && setTimeRemaining(timeRemaining - 1)
        },1000)
        return () => {
            clearTimeout(reduceTime)
        }
    },[timeRemaining])
    useEffect(()=> {
        //set focus on the input element with inputRef
        inputRef.current?.focus();
    },[activeInput])

    const handlePinChange = (e: any) => {
        const value = e.target.value
        if(!value){
            setActiveInput(currentPinIndex - 1)
        }else{
            setActiveInput(currentPinIndex + 1)
        }
        const newPin = [...pin]
        newPin[currentPinIndex] = value.substring(value.length - 1)
        setPin(newPin)
    }
    const handleKeyDown = (e: any, index: number) => {
        currentPinIndex = index
        const { key } = e
        if(key === 'Backspace' ){
            setActiveInput(currentPinIndex - 1)
        }
    }
    return(
        <AuthLayout>
            <div className="confirm-email">
                <div className="confirm-email__top">
                    <div className="confirm-email__top__back-button" onClick={() => dispatch(setSignUpActive())} style={{ cursor: 'pointer'}}>
                        <img src={ARROW_LEFT} alt=""/>
                        <p><span>Enter Email</span> / Confirm Email</p>
                    </div>
                    <div className="confirm-email__top__close" onClick={() => dispatch(setModalInactive())}>
                        <img src={CLOSE_ICON} alt=""/>
                    </div>
                </div>
                <div className="confirm-email__main">
                    <img src={EMAIL_IMG} alt=""/>
                    <h4>Enter the code sent to <span>farookshuaibu@gmail.com</span></h4>
                    <div className="confirm-email__main__pin">
                        {
                            pin.map((data, index) => {
                                return(
                                    <input
                                        style={{ outline: 'none'}}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        ref={activeInput === index ? inputRef : null}
                                        key={index}
                                        className="set-pin__wrapper__main__create-pin__input-wrapper--input"
                                        type="password"
                                        value={pin[index]}
                                        onChange={handlePinChange}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                )
                            })
                        }
                    </div>
                    <h5>{timeRemaining}</h5>
                    <h6>Didin’t get email ? Resend</h6>
                </div>
            </div>
        </AuthLayout>
    )
}
export default ConfirmEmail;