import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BLUE_CHECKMARK } from "../../assets/image";
import { setFrom, setSignUpActive } from "../../store/slices/modal/modalSlice";
import { RootState } from "../../store/store";

const RentButton = (props: React.HTMLAttributes<HTMLButtonElement>) => {
    const user = localStorage.getItem('liveableUser');
    const dispatch = useDispatch();
    const router = useNavigate();   
    const property = useSelector((state: RootState) => state.property);
    let { slug } = useParams();
    
    return(
        <button className="rent-now-button" {...props}
            onClick={() => { 
                user
                    ?
                    router(`/verify/${property.property.slug}`)
                    :
                    dispatch(setSignUpActive()); dispatch(setFrom(`/verify/${slug}`)) 
            }}
        >
            <img src={BLUE_CHECKMARK} alt=""/>
            RENT NOW
        </button>
    )
}
export default RentButton;