import { CLOSE_ICON } from "../assets/image";
import AuthLayout from "../layout/authLayout";

const RentAdvanceModal = (props: any) => {
    const setRentAdvanceModalActive = props.setRentAdvanceModalActive
    return(
        <AuthLayout>
            <div className="rent-advance-modal">
                <div className="rent-advance-modal__top">
                    <img src={CLOSE_ICON} alt="" onClick={() => setRentAdvanceModalActive(false)} style={{cursor: 'pointer'}}/>
                </div>
                <div className="rent-advance-modal__body">
                    <p>Mesh should provide copy for this section</p>
                    <p>Mesh should provide copy for this section</p>
                    <p>Mesh should provide copy for this section</p>
                    <p>Mesh should provide copy for this section</p>
                    <p>Mesh should provide copy for this section</p>
                    <p>Mesh should provide copy for this section</p>
                </div>
            </div>
        </AuthLayout>
    )
}
export default RentAdvanceModal;