import { useState } from "react"
import { LANDLORDS_CONNECT } from "../assets/image"
import PageLayout from "../layout/pageLayout"
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { $api } from "../services";
import { toast } from "react-toastify";

const Event = () =>{
    const [ firstName, setFirstName ] = useState<string>('');
    const [ lastName, setLastName ] = useState<string>('');
    const [ phone, setPhone ] = useState<any>('');
    const [ ownProperty, setOwnProperty ] = useState<string>('');
    const [ hearAboutUs, setHearAboutUs ] = useState<string>('');
    const [ triedToSubmit, setTriedToSubmit ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const handleSubmit = async() => {
        try{
            setIsLoading(true)
            const response = await $api.post('v2/lv/events', {
                first_name: firstName,
                last_name: lastName,
                phone,
                own_property: ownProperty,
                hear_about_us: hearAboutUs
            })
            if($api.isSuccessful(response)){
                clearForm()
                toast.success('Response submitted sucessfully')
            }
        }
        catch(err){
            console.log(err)
        }
        finally{
            setIsLoading(false)
        }
    }

    const clearForm = () => {
        setFirstName('')
        setLastName('')
        setPhone('')
        setOwnProperty('')
        setHearAboutUs('')
        setTriedToSubmit(false)
    }

    return(
        <PageLayout>
            <div className="event-wrapper">
                <div className="event-wrapper__img">
                    <img src={LANDLORDS_CONNECT} alt="" width={600} height={750}/>
                </div>
                <div className="event-wrapper__form">
                    <form onSubmit={(e) => { e.preventDefault(); firstName && lastName && phone?.length === 14 && hearAboutUs && ownProperty ? handleSubmit() : setTriedToSubmit(true)  }}>
                        <div><h2>Get Your Free Ticket</h2></div>
                        <div className="input-container">
                            <label>First Name</label>
                            <input
                                type="text"
                                placeholder="Enter your first name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className={`${triedToSubmit && !firstName && 'invalid'}`}
                            />
                        </div>
                        <div className="input-container">
                            <label>Last Name</label>
                            <input
                                type="text"
                                placeholder="Enter your last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className={`${triedToSubmit && !lastName && 'invalid'}`}
                            />
                        </div>
                        <div className="input-container">
                            <label>Phone Number</label>
                            <PhoneInputWithCountrySelect
                                defaultCountry="NG"
                                placeholder="+234"
                                value={phone || undefined}
                                onChange={(value) => setPhone(value)}
                                className={`${triedToSubmit && phone?.length !== 14 && 'invalid'}`}
                            />
                        </div>
                        <div className="input-container">
                            <label>Do you own a rental property?</label>
                            <select value={ownProperty} onChange={(e) => setOwnProperty(e.target.value)} className={`${triedToSubmit && !ownProperty && 'invalid'} ${ !ownProperty && 'undefined' }`}>
                                <option value="">Select an option</option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>
                        <div className="input-container">
                            <label>How did you hear about us?</label>
                            <select value={hearAboutUs} onChange={(e) => setHearAboutUs(e.target.value)} className={`${triedToSubmit && !hearAboutUs && 'invalid'} ${ !hearAboutUs && 'undefined' }`}>
                                <option value="">Select an option</option>
                                <option>Tenant</option>
                                <option>Instagram</option>
                                <option>Through a fellow landlord</option>
                                <option>Twitter</option>
                                <option>Whatsapp</option>
                                <option>An Agent</option>
                                <option>Other</option>
                            </select>
                        </div>
                        <button>
                            {
                                isLoading
                                    ?
                                    <div className="loading"></div>
                                    :
                                    "Connect..."
                            }
                        </button>
                    </form>
                </div>
            </div>
        </PageLayout>
    )
}
export default Event