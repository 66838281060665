import { useNavigate } from "react-router-dom";
import { DOT } from "../../assets/image"
import AvailableButton, { RecentlyRentedButton } from "../buttons/availabeButton";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {IconPickerItem} from 'react-fa-icon-picker'
import { useDispatch } from "react-redux";
import { setActivePropertyId, setPropertyLoading } from "../../store/slices/property/propertySlice";

interface IApartmentCard {
    id?: string,
    location: string,
    pricing: string,
    additionalFeatures?: IApartmentCardFeatures[],
    rooms?: string[],
    img: string,
    availableAt: string,
    unit_summary: string,
    lowResImg?: string,
    slug: string,
    isRecentlyRented?: boolean,
    isWaitlist?: boolean, 
}
interface IApartmentCardFeatures {
    feature?: string,
    icon_class?: string,
    name?: string,
}

export const ApartmentCardLoader = () => {
    return(
        <SkeletonTheme baseColor='#F5F5F5' highlightColor='#ffffff'>
            <div className="apartment-card">
                <div className="apartment-card__img">
                    <Skeleton
                        height={240}
                        width="auto"
                    />
                </div>
                <div className="apartment-card__details">
                    <h4>{<Skeleton height={30}/>}</h4>
                    <div className="apartment-card__details__summary"><Skeleton height={25} width={150}/></div>
                    <h3><Skeleton height={30}/></h3>
                </div>
            </div>
        </SkeletonTheme>
    )
}

const ApartmentCard = (props: IApartmentCard) => {
    const dispatch = useDispatch();
    const router = useNavigate();
    return(
        <div className="apartment-card" style={{ cursor:  'pointer'}} onClick={() => { 
            props.isWaitlist 
                ?
                props.id && dispatch(setActivePropertyId(props.id)) && dispatch(setPropertyLoading(true)) && router(`/join-waitlist/${props.slug}`)
                :
                props.isRecentlyRented
                ?
                    props.id && dispatch(setActivePropertyId(props.id)) && dispatch(setPropertyLoading(true)) && router(`/recently-rented/${props.slug}`)
                    :
                    props.id && dispatch(setActivePropertyId(props.id)) && dispatch(setPropertyLoading(true)) && router(`/homes/${props.slug}`);
        }}>
            {
                props.isRecentlyRented
                    ?
                    <RecentlyRentedButton style={{ position: 'absolute', marginTop: '11px', marginRight: '11px'}}/>
                    :
                    <AvailableButton availableAt={props.availableAt} style={{ position: 'absolute', marginTop: '11px', marginRight: '11px'}}/>
            }
            <div className="apartment-card__img">
                <img
                    alt={props.slug}
                    src={props.lowResImg}
                    style={{ backgroundColor: '#dedede', borderRadius: '10px'}}
                />
                {/* <img src={props.img} alt=""/> */}
            </div>
            <div className="apartment-card__details">
                <h4>{props.location}</h4>
                <div className="apartment-card__details__rooms">
                    {
                        props.rooms
                            ?
                            props.rooms.map((room, index) => {
                                return(
                                    <div key={index} className="room">
                                        <h6>{room}</h6>
                                        <img src={DOT} alt=""/>
                                    </div>
                                )
                            })
                            :
                            ''
                    }
                </div>
                <h6 className={ !props.unit_summary ? "apartment-card__details__summary" : "apartment-card__details__summary top-space" }>{props.unit_summary}</h6>
                <h3>{props.pricing}</h3>
                <div className="apartment-card__details__additional-features">
                    {
                        props.additionalFeatures
                            ?
                            props.additionalFeatures.map((feature:IApartmentCardFeatures, index) => {
                                return(
                                    <ApartmentFeature key={index} feature={feature.name} icon={feature.icon_class}/>
                                )
                            })
                            :
                            ''
                    }
                </div>
            </div>
        </div>
    )
}
export default ApartmentCard;

const ApartmentFeature = (props: any):JSX.Element => {
    const feature = props.feature
    const icon = props.icon
    return(
        <div>
            <IconPickerItem icon={icon} size={16} color="#616E9E" onClick={()=> {}} />
            <h5>{feature}</h5>
        </div>
    )
}