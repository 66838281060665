import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { VERIFY_WITH_US } from "../../assets/image"
import VerificationLayout from "../../layout/apartmentVerificationLayout"
import RentAdvanceModal from "../../modal/rentAdvanceModal";
import { toast } from "react-toastify";
import { $api } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { fetchBooking, fetchUnitBooking, setBookingStatus } from "../../store/slices/booking/bookingSlice";
import { AppDispatch } from "../../store/slices/properties/types";
import { RootState } from "../../store/store";
import PaymentSuccess from "../../modal/paymentSuccess";
import RentAdvanceButton from "../../components/buttons/rentAdvanceButton";

const Verify = () => {
    const [ rentAdvanceModalActive, setRentAdvanceModalActive ] = useState(false)
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ unitId, setunitId ] = useState<string>('');
    const [ paymentMade, setPaymentMade ] = useState<boolean>(false);
    const router = useNavigate();

    let { slug } = useParams();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (slug) {
            let bookingIdrray = slug.split("-");
            const slugLength = bookingIdrray.length
            const unitId = bookingIdrray[slugLength-1]
            if (slugLength === 1){
                unitId ? dispatch(fetchBooking( unitId )) : toast.error('Booking not found')
            }
            else{
                unitId ? dispatch(fetchUnitBooking( unitId )) : toast.error('Booking not found')
            }
            setunitId(unitId)
        }
    },[ dispatch, slug ])


    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            const response = await $api.post(`v2/lv/booking/continue-to-verification`,{
                unit_id: activeBooking.id,
            })
            if($api.isSuccessful(response)){
                setIsLoading(false)
                router(`/verify/${response?.data?.id}`)
                dispatch(setBookingStatus(response?.data?.status))
                window.open(`${process.env.REACT_APP_TENANT_VERIFICATION_LINK}?booking_id=${response.data.id}`)
            }
            else{
                setIsLoading(false)
                toast.error("Couldn't process request")
            }
        }
        catch(err){
            setIsLoading(false)
        }
    }
    const { activeBooking, bookingStatus } = useSelector((state: RootState) => state.booking)
    const rent = activeBooking.amount.replace(/[^0-9]/g, '');
    const rentAdvance = (Math.round(parseInt(rent)*10));

    return(
        <VerificationLayout>
            { rentAdvanceModalActive && <RentAdvanceModal setRentAdvanceModalActive={setRentAdvanceModalActive} />}
            { paymentMade && <PaymentSuccess setPaymentMade={setPaymentMade}/>}
            <div className="verify">
                {
                    bookingStatus === 'verification-form-completed'
                    ?
                    <div className="verify__continue">
                        <h2>Verification Form Completed</h2>
                        <p>Please wait while we make decisions on your verification. This process should not take more than 48 hours and we should send you a mail when it's done</p>
                        {/*<a className="continue-button" href={process.env.REACT_APP_TENANT_VERIFICATION_LINK + '?booking_id=' + unitId} target="_blank" rel='noreferrer'>                        */}
                        {/*    {*/}
                        {/*        isLoading*/}
                        {/*            ?*/}
                        {/*            <div className="loading"></div>*/}
                        {/*            :*/}
                        {/*            'Continue'*/}

                        {/*    }*/}
                        {/*</a>*/}
                    </div>
                    :
                    bookingStatus === 'verification-form-in-progress'
                        ?
                        <div className="verify__continue">
                            <h2>Verification Form In Progress</h2>
                            <p>Click the button below to continue to verification</p>
                            <a className="continue-button" href={process.env.REACT_APP_TENANT_VERIFICATION_LINK + '?booking_id=' + unitId} target="_blank" rel='noreferrer'>                        
                                {
                                    isLoading
                                        ?
                                        <div className="loading"></div>
                                        :
                                        'Continue'

                                }
                            </a>
                        </div>
                        :
                        bookingStatus === 'verification-form-pending'
                            ?
                            <div className="verify__continue">
                                <h2>Verification Form Pending</h2>
                                <p>You’ve come this close to your dream home. Let's complete your verification.</p>
                                <a className="continue-button" href={process.env.REACT_APP_TENANT_VERIFICATION_LINK + '?booking_id=' + unitId} target="_blank" rel='noreferrer'>                        
                                    {
                                        isLoading
                                            ?
                                            <div className="loading"></div>
                                            :
                                            'Continue'

                                    }
                                </a>
                            </div>
                            :
                            <>
                                <img src={VERIFY_WITH_US} alt=""/>
                                <div className="verify__wrapper">
                                    <div className="verify__advance">
                                        <h2>Pay rent advance</h2>
                                        <p> Pay a 10% rent advance to secure this apartment and get a full refund if your verification is unsuccessful. If you decide not to proceed with the apartment after being selected, you'll receive a 90% refund of the rent advance paid.</p>
                                        <RentAdvanceButton unit_id={activeBooking.id} rentAdvance={rentAdvance} setPaymentMade={setPaymentMade}/>
                                    </div>
                                    <div className="hr"></div>
                                    <div className="or"><h4>OR</h4></div>
                                    <div className="verify__continue">
                                        <h2>Continue to verification</h2>
                                        <p>Continue verification without paying a rent advance and risk losing the apartment to a someone else who pays the rent advance on the apartment you want</p>
                                        <button className="continue-button" onClick={() => handleSubmit()}>                        
                                            {
                                                isLoading
                                                    ?
                                                    <div className="loading"></div>
                                                    :
                                                    'Continue'
                
                                            }
                                        </button>
                                    </div>
                                </div>
                            </>
                }
                {/* <img src={VERIFY_WITH_US} alt=""/>
                <h2>Verify with us in three easy steps</h2>
                <p>
                    You only get to do this once. 
                    Our verification process ensures that both 
                    landlords and tenants feel safe
                </p>
                <div className="button-container">
                    <div className="pay">
                        <PaystackButton unit_id={unitId}/>
                        <div className="info" style={{ cursor: 'pointer'}} onClick={() => setRentAdvanceModalActive(true)}>
                            <img src={LIGHT_BLUE_INFO_ICON} alt=""/>
                            <p>what is this</p>
                        </div>
                    </div>
                    <button className="continue-button" onClick={() => handleSubmit()}>                        
                        {
                            isLoading
                                ?
                                <div className="loading"></div>
                                :
                                'Continue'

                        }
                    </button>
                </div> */}
            </div>
        </VerificationLayout>
    )
}
export default Verify;