import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IApartmentSlice {
    type: {
        name: string | undefined,
        id: string | undefined,
    },
    location: {
        name: string | undefined,
        id: string | undefined,
    }
    roomsNumber: number,
    rent: string,
    landlordContact: string | undefined,
    availableAt: string,
    firstRequestMade: boolean,
    additionalInfo: string,
}

const initialState:IApartmentSlice = {
    location: {
        name: undefined,
        id: undefined,
    },
    type: {
        name: undefined,
        id: undefined,
    },
    roomsNumber: 0,
    rent: '',
    landlordContact: undefined,
    availableAt: '',
    firstRequestMade: false,
    additionalInfo: '',
}
const apartmentInfoSlice = createSlice({
    name: 'identification',
    initialState,
    reducers : {
        setLocation : (state, action:PayloadAction<string>) => {state.location.name = action.payload},
        setLocationId : (state, action:PayloadAction<string>) => {state.location.id = action.payload},
        setType : (state, action:PayloadAction<string>) => {state.type.name = action.payload},
        setTypeId : (state, action:PayloadAction<string>) => {state.type.id = action.payload},
        setRoomsNumber : (state, action:PayloadAction<number>) => {state.roomsNumber = action.payload},
        setRent : (state, action:PayloadAction<string>) => {state.rent = action.payload},
        setLandlordContact: (state, action:PayloadAction<string | undefined >) => { state.landlordContact = action.payload },
        setDateAvailable: (state, action:PayloadAction<string>) => { state.availableAt = action.payload },
        setAdditionalInfo: (state, action:PayloadAction<string>) => { state.additionalInfo = action.payload },
        setApartmentFirstRequestMade : (state, action:PayloadAction<boolean>) => {state.firstRequestMade = action.payload}
    }
})

export const { setLocation, setType, setRoomsNumber, setRent, setLandlordContact, setDateAvailable, setAdditionalInfo, setApartmentFirstRequestMade, setLocationId, setTypeId } = apartmentInfoSlice.actions
export default apartmentInfoSlice.reducer