import { useEffect, useRef, useState } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { $api } from "../../services";
import { fetchAllAvailableTypes, fetchAvailableLocations, fetchAvailablePrice } from "../../store/slices/filter/filterSlice";
import { AppDispatch } from "../../store/slices/properties/types";
import { RootState } from "../../store/store";
import ErrorMessage from "../errorMessage";
import GoodTenantForm from "./goodTenantForm";
import MultiSearchSelect from "./multiSearchSelect";

const EMAIL_REGEX = /^(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}$/;
const GetHelpForm = () => {
    const [ firstName, setFirstName ] = useState<string>();
    const [ firstNameValid, setFirstNameValid ] = useState<boolean>(false);

    const [ lastName, setLastName ] = useState<string>();
    const [ lastNameValid, setLastNameValid ] = useState<boolean>(false);

    const [ phoneNumber, setPhoneNumber ] = useState<string>();
    const [ phoneNumberValid, setPhoneNumberValid ] = useState<boolean>(false);

    const [ email, setEmail ] = useState<string>('');
    const [ emailValid, setEmailValid ] = useState<boolean>();

    const [ location, setLocation] = useState<string[]>([]);
    const [ locationId, setLocationId ] = useState<string[]>([]);

    const [ budget, setBudget] = useState('');

    const [ type, setType] = useState('');

    const [ room, setRoom] = useState('');

    const [ requestPending, setRequestPending ] = useState<boolean>(false);
    
    const [ triedToSubmit, setTriedToSubmit ] = useState<boolean>(false);
    const [ pageValid, setPageValid ] = useState<boolean>(false);

    const [ additionalPreferences, setAdditionalPreferences ] = useState<string>('');

    const [ queryModalActive, setQueryModalActive ] = useState<boolean>(false)

    const [ goodTenantFormActive, setGoodTenantFormActive ] = useState<boolean>(false);
    const [ expandSearch, setExpandSearch ] = useState<boolean>(false);

    const locationRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch<AppDispatch>();
    const router = useNavigate();

    useEffect(() => {
        EMAIL_REGEX.test(email) ? setEmailValid(true) : setEmailValid(false);
        !firstName ? setFirstNameValid(false) : setFirstNameValid(true);
        !lastName ? setLastNameValid(false) : setLastNameValid(true);
        phoneNumber && phoneNumber.length >= 14 ? setPhoneNumberValid(true) : setPhoneNumberValid(false)
    },[ email, firstName, lastName, phoneNumber ])

    useEffect(() => {
        ( firstNameValid && lastNameValid && emailValid && phoneNumberValid && budget && location && type && room ) ? setPageValid(true) : setPageValid(false)
    },[ firstNameValid, lastNameValid, emailValid, phoneNumberValid, budget, location, type, room ])

    useEffect(() => {
        !availableLocations && dispatch(fetchAvailableLocations());
        dispatch(fetchAllAvailableTypes());
        !availablePrice && dispatch(fetchAvailablePrice());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const values ={
        first_name: firstName,
        last_name: firstName,
        phone: phoneNumber,
        email: email,
        location: locationId,
        type_id: type,
        budget_id: budget,
        room: room,
        additional_preferences: additionalPreferences,
    }

    const submitForm = async() => {
        setRequestPending(true);
        const response = await $api.post('upcoming-rent-request', values);
        setRequestPending(false);
        if ($api.isSuccessful(response)){
            toast.success('Form submitted successfully')
            setTimeout(() => {
                router('/')
            },2000);
            setTriedToSubmit(false);
            clearForm();
        }
    }

    const clearForm = () => {
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setEmail('');
        setAdditionalPreferences('');
        setLocation([]);
        setLocationId([]);
        setBudget('');
        setRoom('');
        setType('');
    }

    const handleValidSubmit = () => {
        expandSearch
            ?
            setGoodTenantFormActive(true)
            :
            submitForm()
    }

    const handleSubmit = () => {
        pageValid
            ?
            handleValidSubmit()
            :
            setTriedToSubmit(true)
    }

    const { availableLocations, availablePrice, allAvailableTypes, availableBedroom  } = useSelector((state:RootState) => state.filter)

    const handleClick = (e:any) => {
        if (queryModalActive && locationRef.current && !locationRef.current.contains(e.target)) {
            setQueryModalActive(false);
        }
    }

    return(
        <div className="get-help" id="no-result" onClick={(e) => handleClick(e)}>
            { goodTenantFormActive && <GoodTenantForm setGoodTenantFormActive={setGoodTenantFormActive}/> }
            <h2>Can't find what you are looking for? </h2>
            <p>Fill the form below and we will reach out once a suitable apartment becomes available</p>
            <div className="get-help__form">
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="First Name"
                        value={firstName || ''}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    { !firstNameValid && triedToSubmit && <ErrorMessage message="first name is required"/>}
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="Last Name"
                        value={lastName || ''}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    { !lastNameValid && triedToSubmit && <ErrorMessage message="last name is required" />}
                </div>
                <div className="input-container">
                    <PhoneInputWithCountrySelect
                        defaultCountry="NG"
                        placeholder="+234"
                        value={phoneNumber || undefined}
                        onChange={(value) => setPhoneNumber(value)}
                    />
                    { !phoneNumberValid && triedToSubmit && <ErrorMessage message={ (phoneNumber && phoneNumber.length <= 14) ? "enter a valid phone number" : "phone number is required"}/>}
                </div>
                <div className="input-container">
                    <input
                        type="email"
                        placeholder="Email Address"
                        value={email || ''}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    { !emailValid && triedToSubmit && <ErrorMessage message={ email === '' ? "email is required" : "enter a valid email"} />}
                </div>
                <div className="input-container">
                    <select onChange={(e) => setType(e.target.options[e.target.selectedIndex].value)} value={type} className={ type ? '' : 'unselected'}>
                        <option disabled value=''>Select Property Type</option>
                        {
                            allAvailableTypes?.map((type: any) => {
                                return <option key={type.id} value={type.id}>{type.name}</option>
                            })
                        }
                    </select>
                    { !type && triedToSubmit && <ErrorMessage message="property type is required"/>}
                </div>
                <div className="input-container">
                    <select onChange={(e) => setRoom(e.target.options[e.target.selectedIndex].value)} value={room} className={ room ? '' : 'unselected'}>
                        <option disabled value=''>Number of Rooms</option>
                        {
                            availableBedroom.map((bedroom: any) => {
                                return <option key={bedroom} value={bedroom}>{bedroom} Bedroom</option>
                            })
                        }
                    </select>
                    {!room && triedToSubmit && <ErrorMessage message="number of rooms is required"/>}
                </div>
                <div className="input-container">
                    <select onChange={(e) => setBudget(e.target.options[e.target.selectedIndex].value)} value={budget} className={ budget ? '' : 'unselected'}>
                        <option disabled value=''>Select Budget</option>
                        {
                            availablePrice?.map((price: any) => {
                                return <option key={price.id} value={price.id}>{price.name}</option>
                            })
                        }
                    </select>
                    { !budget && triedToSubmit && <ErrorMessage message="budget is required"/>}
                </div>
                <div className="input-container location">
                    <MultiSearchSelect placeholder="Select Locations" reference={locationRef} setQueryModalActive={setQueryModalActive} queryModalActive={queryModalActive} setLocationId={setLocationId} setLocation={setLocation} location={location} triedToSubmit={triedToSubmit}/>
                </div>
                {/* <div className="input-container">
                    <SearchSelect placeholder="Preferred Location" reference={locationRef} setQueryModalActive={setQueryModalActive} queryModalActive={queryModalActive} setLocationId={setLocationId} setLocation={setLocation} location={location} triedToSubmit={triedToSubmit}/>
                </div> */}
                {/* <div className="input-container">
                    <SearchSelect placeholder="Secondary Location 1" reference={secondaryLocation1Ref} setQueryModalActive={setSecondaryLocation1QueryModalActive} queryModalActive={secondaryLocation1QueryModalActive} setLocationId={setSecondaryLocation1Id} setLocation={setSecondaryLocation1} location={secondaryLocation1} triedToSubmit={triedToSubmit}/>
                </div>
                <div className="input-container">
                    <SearchSelect placeholder="Secondary Location 2"reference={secondaryLocation2Ref} setQueryModalActive={setSecondaryLocation2QueryModalActive} queryModalActive={secondaryLocation2QueryModalActive} setLocationId={setSecondaryLocation2Id} setLocation={setSecondaryLocation2} location={secondaryLocation2} triedToSubmit={triedToSubmit}/>
                </div> */}
                <div className="get-help__form__last-row">
                    <textarea 
                        placeholder="Additional Preferences"
                        onChange={(e) => setAdditionalPreferences(e.target.value)}
                        value={additionalPreferences}
                    ></textarea>
                </div>
            </div>
            {/* <div className="get-help__form__checkbox">
                <input 
                    type="checkbox"
                    id="expand-search"
                    onChange={(e) => setExpandSearch(e.target.checked) }
                    checked={expandSearch}
                />
                <label htmlFor="expand-search">Would you like us to expand your search to our partner real estate firm (we might require more information)</label>
            </div> */}
            <button onClick={() => handleSubmit()}>
                {
                    requestPending
                        ?
                        <div className="loading"></div>
                        :
                        "Continue"
                }
            </button>
        </div>
    )
};

export default GetHelpForm;