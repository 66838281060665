import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { VERIFICATION_SUCCESSFUL } from "../../assets/image";
import CongratulationsCard from "../../components/cards/congratulationsCard";
import VerificationLayout from "../../layout/apartmentVerificationLayout"
import { fetchBooking } from "../../store/slices/booking/bookingSlice";
import { AppDispatch } from "../../store/slices/properties/types";

const Selection = () => {

    let { id } = useParams();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (id) {
            dispatch(fetchBooking(id))
        }
    },[ dispatch, id ])
    
    return(
        <VerificationLayout>
            <div className="selection">
                <CongratulationsCard
                    title="Your verification was successful"
                    text="you will receive an email with the next steps."
                />
                <img className="selection__img" src={VERIFICATION_SUCCESSFUL} alt=""/>
                <h2>Stakeholders are selecting</h2>
                <p>The stake holders of this apartment are currently looking through your verification and making an approval, this process takes two working days.</p>
            </div>
        </VerificationLayout>
    )
}
export default Selection;