import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../../store/slices/auth/authSlice";
import { setLogoutActive } from "../../store/slices/modal/modalSlice";

const Logout = ({reference}: any) => {

    const router = useNavigate();
    const dispatch = useDispatch();

    return(
        <div className="logout-overlay">
            <div className="logout" ref={reference}>
                <div className="logout__wrapper">
                    <ul>
                        <li onClick={() => { router('/apartment'); dispatch(setLogoutActive(false)) }}>Apartments</li>
                    </ul>
                    <button onClick={() => { localStorage.clear() ;router('/'); dispatch(setLogoutActive(false)); dispatch(clearUser())}}>Logout</button>
                </div>
            </div>
        </div>
    )
}
export default Logout;