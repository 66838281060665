import { Outlet } from "react-router-dom";
import ListHome from "../components/listHome";
import ListingProgressBar from "../components/listingProgressBar";
import PageLayout from "./pageLayout"


const ListingPagesLayout = () => {
    return(
        <div className="listing-page-layout">
            <PageLayout>
                <ListHome 
                    header="Are you moving out soon? List your current apartment and earn money" 
                    subHeader="Earn extra income with ease by listing your apartment. Invite your friends to list their apartment and earn money." 
                    textWidth={792}
                    displayAbout={false}
                />
                <div className="listing-page-layout__form">
                    <ListingProgressBar/>
                    <Outlet/>
                </div>
            </PageLayout>
        </div>
    )
}
export default ListingPagesLayout;