import PhoneInputWithCountrySelect from "react-phone-number-input";
import PageLayout from "../layout/pageLayout";
import { useEffect, useRef, useState } from "react";
import locations from '../assets/data/locations.json';
import { LANDLORD_HERO_IMG, LANDLORD_HERO_IMG_2 } from "../assets/image";
import StaticSearchSelect from "../components/forms/staticSearchSelect";
import { toast } from "react-toastify";
import ErrorMessage from "../components/errorMessage";
import axios from "axios";
import useCloseOnExternalClick from "../hooks/useCloseOnExternalClick";
import { $api } from "../services";

const EMAIL_REGEX = /^(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}$/;
const Landlord = () => {
    const propertyLocationRef = useRef<HTMLInputElement>(null);

    
    const [ phone, setPhone ] = useState<any>(undefined);
    const [ fullName, setFullName ] = useState<string>('')
    const [ email, setEmail ] = useState<string>('');
    const [ numberOfUnits, setNumberOfUnits ] = useState<string>('')
    const [ isWhatsappNumber, setIsWhatsappNumber ] = useState<boolean>(false);
    const [ propertyLocationQueryModalActive, setPropertyLocationQueryModalActive ] = useCloseOnExternalClick(propertyLocationRef);
    const [ propertyLocation, setPropertyLocation ] = useState<string>('');
    const [ triedToSubmit, setTriedToSubmit ] = useState<boolean>(false);
    const [ pageValid, setPageValid ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        pageValid
            ?
            handleValidSubmit()
            :
            setTriedToSubmit(true)
    }

    const handleValidSubmit = async() => {
        setIsLoading(true)
        try{
            const response = await $api.post('v2/lv/landlord',{
                is_whatsapp: isWhatsappNumber,
                phone,
                email,
                name: fullName,
                location: propertyLocation,
                units: numberOfUnits
            })
            if($api.isSuccessful(response)){
                clearForm()
                toast.success('Form submitted successfully')
            }
        }
        catch(err){
            toast.error('Failed to submit form')
            console.log(err)
        }
        finally{
            setIsLoading(false)
        }
    }

    const clearForm = () => {
        setPhone('')
        setEmail('')
        setNumberOfUnits('')
        setFullName('')
        setPropertyLocation('')
        setIsWhatsappNumber(false)
        setTriedToSubmit(false)
    }

    useEffect(() => {
        fullName && EMAIL_REGEX.test(email) && phone?.length === 14 && propertyLocation && numberOfUnits ? setPageValid(true) : setPageValid(false)
    },[ fullName, email, propertyLocation, numberOfUnits, phone ])

    return(
        <PageLayout>
            <div className="landlord">
                <div className="landlord__hero" id="hero">
                    <div className="landlord__hero__text">
                        <div>
                            <h1>Money in the bank, peace of mind.</h1>
                            <h4>Sign up to set up a demo meeting with us.</h4>
                        </div>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="input-wrapper">
                                <div className="input">
                                    <input
                                        type="text"
                                        placeholder="Full Name"
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                    />
                                    { triedToSubmit && !fullName && <ErrorMessage message="Enter your full name" /> }
                                </div>
                                <div className="input">
                                    <input
                                        type="text"
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    { triedToSubmit && !EMAIL_REGEX.test(email) && <ErrorMessage message={ email ? "Enter valid email " : "Enter your email" } /> }
                                </div>
                                <div className="input">
                                    <PhoneInputWithCountrySelect
                                        onChange={(value) => setPhone(value)}
                                        value={phone}
                                        defaultCountry="NG"
                                        placeholder="08175435854"
                                    />
                                    { triedToSubmit && phone?.length !== 14 && <ErrorMessage message={ phone ? "Enter valid phone number" : "Enter your phone number" }/> }
                                </div>
                                <div className="input">
                                    <input
                                        type="number"
                                        placeholder="Number of properties"
                                        value={numberOfUnits}
                                        onChange={(e) => setNumberOfUnits(e.target.value)}
                                    />
                                    { triedToSubmit && !numberOfUnits && <ErrorMessage message="Enter number of units" /> }
                                </div>
                            </div>
                            <div className="landlord__hero__text__select">
                                <input type="checkbox" id="is-whatsapp-number" checked={isWhatsappNumber} onChange={(e) => setIsWhatsappNumber(e.target.checked)}/>
                                <label htmlFor="is-whatsapp-number">This is my Whatsapp Number</label>
                            </div>
                            <div className="additional-fields">
                                <select value={propertyLocation} onChange={(e) => setPropertyLocation(e.target.value)} style={!propertyLocation ? { color: '#616E9E' } : {}}>
                                    <option value="" disabled>Select property location</option>
                                    <option>Lagos</option>
                                    <option>Outside Lagos</option>
                                    <option>Lagos and Outside Lagos</option>
                                </select>
                                { triedToSubmit && !propertyLocation && <ErrorMessage message="Select property location" /> }
                                {/* <StaticSearchSelect
                                    options={locations}
                                    placeholder="Select property location"
                                    queryModalActive={propertyLocationQueryModalActive}
                                    setQueryModalActive={setPropertyLocationQueryModalActive}
                                    reference={propertyLocationRef}
                                    setValue={setPropertyLocation}
                                    triedToSubmit={triedToSubmit}
                                    value={propertyLocation}
                                /> */}
                            </div>
                            <button>
                                {
                                    isLoading
                                        ?
                                        <div className="loading"></div>
                                        :
                                        "Get Started"
                                }
                            </button>
                        </form>
                    </div>
                    <div className="landlord__hero__img">
                        <img src={LANDLORD_HERO_IMG} alt="" className="landlord__hero__img__1"/>
                        <img src={LANDLORD_HERO_IMG_2} alt="" className="landlord__hero__img__2"/>
                    </div>
                </div>
                {/* <div className="landlord__what-we-do">
                    <div>
                        <h1>Here is how we are transforming the landlord experience.</h1>
                    </div>
                    <div className="landlord__what-we-do__card-wrapper">
                        <div className="what-we-do__card">
                            <div className="what-we-do__card__img"><img src={SERVICE_1} alt=""/></div>
                            <div>
                                <h3>Guaranteed Rent</h3>
                                <p>Receive your full rental income on time, no defaults. This guarantee is made possible by our thorough tenant verification process and our financial partners.</p>
                            </div>
                        </div>
                        <div className="what-we-do__card">
                            <div className="what-we-do__card__img"><img src={SERVICE_2} alt=""/></div>
                            <div>
                                <h3>Legacy Protection</h3>
                                <p>Maintain the value of your properties over time through our quarterly inspections and audits which allows us to identify and address potential property damages early on.</p>
                            </div>
                        </div>
                        <div className="what-we-do__card">
                            <div className="what-we-do__card__img"><img src={SERVICE_3} alt=""/></div>
                            <div>
                                <h3>Landlord App</h3>
                                <p>Stay informed on the status of your property and tenants from anywhere in the world with just a few clicks.</p>
                            </div>
                        </div>
                        <div className="what-we-do__card">
                            <div className="what-we-do__card__img"><img src={SERVICE_4} alt=""/></div>
                            <div>
                                <h3>Property Upgrade Finance</h3>
                                <p>Get access to renovation finance to upgrade your property to meet current market demands. </p>
                            </div>
                        </div>
                    </div>
                </div>*/}
                <div className="landlord__testimonials">
                    <div>
                        <h1>Testimonials </h1>
                        <h3>Don't take out word for it, hear from our landlords</h3>
                    </div>
                    <div className="landlord__testimonials__testimonial-wrapper">
                        <div className="landlord__testimonials__testimonial">
                            <p>
                                "I have not had a reason to visit my block of flats in Yaba for over a year now since hiring the services of Liveable.
                                What you guys are doing at Liveable is amazing, keep it up. I receive my rent as at when due, 
                                my apartments are always occupied by level headed tenants and the property is properly managed. 
                                I particularly love the quarterly inspections which has kept my property in good shape and reduced the need to renovate when a tenant moves out.""
                            </p>
                            <h4>
                                - Mr Jimi, Finance Professional
                            </h4>
                        </div>
                        <div className="landlord__testimonials__testimonial">
                            <p>
                                "Working with Liveable was one of the best decisions I made for my properties. 
                                They delivered on their promises, and I have since told all of my friends about their services. 
                                My tenant was the first person I ever took to court, so meeting Liveable, I was
                                particularly impressed by their thorough tenant screening process. 
                                I have also been a beneficiary of their renovation finance. 
                                I don’t lose sleep over my properties because I know they are in good hands.""
                            </p>
                            <h4>
                                - Mr. Yinka Adebiyi, Entrepreneur
                            </h4>
                        </div>
                    </div>
                </div>
                {/* <div className="landlord__signup">
                    <h1>Just sit back and enjoy optimized Return on Investment on your properties, we'll do the rest</h1>
                    <h4>Our all-in-one-package takes on the hard work of tenant selection, paperwork, property care, and tenant relationship management and provides you with a dashboard to monitor transparently from anywhere in the world.</h4>
                    <div className="landlord__signup__card">
                        <h1>Premium Guaranteed Rent & Property Care Service</h1>
                        <p>Guaranteed rent annually</p>
                        <p>Detailed quarterly maintenance reports</p>
                        <p>Full property management services</p>
                        <a href="#hero">
                            List your property
                        </a>
                    </div>
                </div> */}
            </div>
        </PageLayout>
    )
}
export default Landlord;