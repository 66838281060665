import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { VERIFICATION_IN_PROGRESS } from "../../assets/image";
import VerificationLayout from "../../layout/apartmentVerificationLayout"
import { fetchBooking } from "../../store/slices/booking/bookingSlice";
import { AppDispatch } from "../../store/slices/properties/types";

const VerificationInProgress = () => {
    
    let { id } = useParams();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (id) {
            dispatch(fetchBooking(id))
        }
    },[ dispatch, id ])

    return(
        <VerificationLayout>
            <div className="verification-in-progress">
                <img src={VERIFICATION_IN_PROGRESS} alt=""/>
                <h2>Your verification is in progress</h2>
                <p>
                    Your verification is currently in progress and will take 2 business days to complete. <br/>
                    In the meantime, we have shared you neccesary details to help monitor progress.
                </p>
            </div>
        </VerificationLayout>
    )
}
export default VerificationInProgress;