import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ARROW_LEFT, CLOSE_ICON, LOGIN, LOGIN_BTN_ICON, NO_VIEW_PASSWORD, VIEW_PASSWORD } from "../../assets/image";
import ErrorMessage from "../../components/errorMessage";
import AuthLayout from "../../layout/authLayout";
import { $api } from "../../services";
import { setAccessToken, setUser, setUserEmail, setUserId } from "../../store/slices/auth/authSlice";
import { setFrom, setLoginSuccessActive, setModalInactive, setSignUpActive, setSuccessMessage } from "../../store/slices/modal/modalSlice";
import ReactGa from 'react-ga';
import { events } from "../../components/analyticsEvents";

const Login = () => {
    const dispatch = useDispatch();
    const [ passwordVisibility, setPasswordVisibility ] = useState(false);
    const [ email, setEmail ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const [ triedToSubmit, setTriedToSubmit ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ errorMessage, setErrorMessage ] = useState<string>('Login failed');

    const handleValidSubmit = async() => {
        setIsLoading(true)
        const response = await $api.post('auth/login',{ email, password})
        if ($api.isSuccessful(response)){
            dispatch(setSuccessMessage('Login'));
            dispatch(setLoginSuccessActive());
            dispatch(setUserEmail(email))
            setEmail('')
            setPassword('')
            const obj = {
                first_name: response?.data?.user?.first_name,
                last_name: response?.data?.user?.last_name,
                email: response?.data?.user?.email
            }
            localStorage.setItem('liveableUserBio', JSON.stringify(obj));
            const first_name = response?.data?.user?.first_name
            const last_name = response?.data?.user?.last_name
            dispatch(setAccessToken(response?.data?.token?.access_token))
            dispatch(setUserId(response?.data?.user?.id))
            const Capitalize = (text: string) => {
                    text.charAt(0).toUpperCase();
                return text;
            }
            const capitalizedFirstName = Capitalize(first_name)
            const capitalizedLastName = Capitalize(last_name)
            dispatch(setUser(capitalizedFirstName +" "+ capitalizedLastName))
            ReactGa.event(events.userLogin)
        }
        else{
            if (response.response.status === 422){
                setErrorMessage('The selected email is invalid')
            }
            else if(response.response.status === 401){
                setErrorMessage('Invalid pasword')
            }
            else{
                toast.error(errorMessage)
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        errorMessage !== 'Login failed' && toast.error(errorMessage)
        setErrorMessage('Login failed')
    },[ errorMessage ])

    const hanldeSubmit = () => {
        ( email && password)
            ?
            handleValidSubmit()
            :
            setTriedToSubmit(true)
    }

    return(
        <AuthLayout>
            <div className="login">
                <div className="login__top">
                    <button className="login__top__back-button" style={{ cursor: 'pointer' }} onClick={() => dispatch(setModalInactive())}>
                        <img src={ARROW_LEFT} alt=""/>
                        {/* Login */}
                    </button>
                    <div className="login__top__close-icon" style={{ cursor: 'pointer' }} onClick={() => { dispatch(setModalInactive()); dispatch(setFrom) }}>
                        <img src={CLOSE_ICON} alt=""/>
                    </div>
                </div>
                <div className="login__main">
                    <div className="login__main__img">
                        <img src={LOGIN} alt=""/>
                        <p>You need to login so that you can save and continue the process.</p>
                    </div>
                    <div className="login__main__form">
                        {/* <button className="login__main__form__google">
                            <img src={GOOGLE_ICON} alt=""/>
                            SIGN IN USING GMAIL
                        </button>
                        <div className="login__main__form__or">
                            <span></span>
                            <p>OR</p>
                            <span></span>
                        </div> */}
                        <div className="login__main__form__main">
                            <div className="login__main__form__main__email">
                                <input
                                    type="email"
                                    placeholder="Enter Email Address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                { triedToSubmit && !email && <ErrorMessage message="Enter your email"/>}
                            </div>
                            <div className="login__main__form__main__email">
                                <div className="login__main__form__main__password">
                                    <input
                                        type={ passwordVisibility ? "text" : "password" }
                                        placeholder="Enter Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <img 
                                        src={passwordVisibility ? VIEW_PASSWORD : NO_VIEW_PASSWORD} 
                                        alt=""
                                        onClick={() => setPasswordVisibility(!passwordVisibility)}
                                    />
                                </div>
                                { triedToSubmit && !password && <ErrorMessage message="Enter your password"/>}
                            </div>
                        </div>
                        {/* <h4>FORGOT PASSWORD ?</h4> */}
                        <button className="login__main__form__login" style={{ cursor: 'pointer'}} onClick={() => hanldeSubmit()}>
                            {
                                isLoading
                                    ?
                                    <div className="loading"></div>
                                    :
                                    <>
                                        <img src={LOGIN_BTN_ICON} alt=""/>
                                        LOGIN
                                    </>
                            }
                        </button>
                        <h5>Don’t have an account ? <span onClick={() => dispatch(setSignUpActive())} style={{ cursor: 'pointer'}}>SIGN UP</span></h5>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}
export default Login;