import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { $api } from "../../../services";
import { RootState } from "../../store";
import { setPicture } from "../picture/pictureSlice";

interface IPropertyPayload {
    slug: string,
    amount: string,
    additional_info: string,
    availability: string,
    description: string,
    name: string,
    address: string,
    service_charge: number,
    legal_fee: number,
    agency_fee: number,
    caution_deposit: number,
    location: {
        area: string,
        sub_area: string,
        state: string,
    },
    published: boolean,
    coordinates:{
        lng: string | undefined | any,
        lat: string | undefined | any,
    },
    ammenities: IAmenity[] | undefined,
    bedroom: string,
    unit_summary: string,
    files: IFiles[],
    booking: {
        id: null | string,
        status: null | string,
    }
}

interface IFiles {
    high: string,
    normal: string,
    source: string,
    thumbnail: string,
}
interface IAmenity {
    name: string,
    icon_class: string,
}

interface IProperty {
    property: IPropertyPayload,
    propertyLoading: boolean,
    activePropertyId: string,
    slug: string,
}

const initialState: IProperty = {
    slug: '',
    property: {
        slug: 'property',
        address: '',
        amount: '',
        additional_info: '',
        availability: '',
        description: '',
        name: '',
        location: {
            area: '',
            sub_area: '',
            state: '',
        },
        published: false,
        coordinates:{
            lng: undefined,
            lat: undefined,
        },
        ammenities: undefined,
        bedroom: '',
        unit_summary: '',
        files: [],
        service_charge: 0,
        legal_fee: 0,
        agency_fee: 0,
        caution_deposit: 0,
        booking: {
            id: null,
            status: null,
        }
    },
    propertyLoading: false,
    activePropertyId: '',

}

type AppThunk = ThunkAction<void, RootState, unknown, Action>;

const propertySlice = createSlice({
    'name': 'property',
    initialState,
    reducers: {
        setProperty: (state, action:PayloadAction<IPropertyPayload>) => { state.property = action.payload },
        setPropertyLoading: (state, action:PayloadAction<boolean>) => { state.propertyLoading = action.payload },
        setActivePropertyId: (state, action:PayloadAction<string>) => { state.activePropertyId = action.payload },
        setSlug: (state, action:PayloadAction<string>) => { state.slug = action.payload },
    }
})
const propertyReducer = propertySlice.reducer
export default propertyReducer;
export const { setProperty, setPropertyLoading, setActivePropertyId, setSlug } = propertySlice.actions

export const fetchProperty = (id: string | null):AppThunk => 
    async(dispatch) => {
        let endpoint = `v2/lv/unit/${id}`
        const response:any = await $api.fetch(endpoint)
        dispatch(setProperty(response?.data))
        dispatch(setPicture(response?.data?.files))
        dispatch(setSlug(response?.data?.slug))
        dispatch(setPropertyLoading(false));
        return response;
    }