import { Link, useNavigate } from "react-router-dom";
import { FOOTER_LOGO, INSTAGRAM_ICON, LINKEDIN_ICON, LOCATION_ICON, MAIL_ICON, TWITTER_ICON } from "../assets/image";

const Footer = ():JSX.Element => {
    const router = useNavigate();
    const date = new Date();
    const year = date.getFullYear();

    return(
        <footer className="footer-parent">
            <div className="footer">
                <div className="footer__wrapper">
                    <div className="footer__wrapper__section-1">
                        <div className="footer__wrapper__section-1__logo">
                            <img src={FOOTER_LOGO} onClick={() => router('/')} style={{ cursor: 'pointer'}} alt="logo"/>
                        </div>
                        <div className="footer__wrapper__section-1__about">
                            <p>We pride ourselves in creating thriving living communities for ambitious individuals and families.</p>
                        </div>
                        <div className="footer__wrapper__section-1__address-ng">
                            <img src={LOCATION_ICON} alt=""/>
                            <p>294 Borno Way, Yaba, Lagos</p>
                        </div>
                        <div className="footer__wrapper__section-1__address-us">
                            <img src={LOCATION_ICON} alt=""/>
                            <p>634, Mission street, San Francisco,  CA 94105</p>
                        </div>
                        <div className="footer__wrapper__section-1__mail">
                            <img src={MAIL_ICON} alt=""/>
                            <a href="mailto:support@liveable.ng"><p>support@liveable.ng</p></a>
                        </div>
                        <div className="footer__wrapper__section-1__social-icons">
                            {/* <a href="" target="_blank" rel="noreferrer"><img src={FACEBOOK_ICON} alt=""/></a> */}
                            <a href="https://twitter.com/LiveableNG" target="_blank" rel="noreferrer"><img src={TWITTER_ICON} alt=""/></a>
                            <a href="https://instagram.com/liveableng" target="_blank" rel="noreferrer"><img src={INSTAGRAM_ICON} alt=""/></a>
                            <a href="https://www.linkedin.com/company/liveableng/" target="_blank" rel="noreferrer"><img src={LINKEDIN_ICON} alt=""/></a>
                        </div>
                        <div className="footer__wrapper__section-1__copyright">
                            <h6>© {year} . All rights reserved.</h6>
                        </div>
                    </div>
                    <div className="footer__wrapper__links">
                        <h3>Explore Liveable</h3>
                        <ul>
                            {/* <Link to='/about'><li>About us</li></Link> */}
                            <a href={'/#home'}><li>Find Apartment</li></a>
                            <a href={'/list#list'}><li>Move and Earn</li></a>
                            <Link to="/monthly-rent-information"><li>Monthly Rent Options</li></Link>
                            <Link to="/landlord"><li>Landlord</li></Link>
                            <li onClick={() => router('/privacy-policy')}>Privacy Policy</li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;