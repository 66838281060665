import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { APP_DOWNLOAD_IMG } from "../../assets/image";
import CongratulationsCard from "../../components/cards/congratulationsCard";
import VerificationLayout from "../../layout/apartmentVerificationLayout"
import { fetchBooking } from "../../store/slices/booking/bookingSlice";
import { AppDispatch } from "../../store/slices/properties/types";

const Onboarding = () => {

    let { id } = useParams();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (id) {
            dispatch(fetchBooking(id))
        }
    },[ dispatch, id ])

    return(
        <VerificationLayout>
            <div className="onboarding">
                <CongratulationsCard
                    text="Insert success message and next steps right here for the user to pick up on. Communicate urgency too."
                    title="Congratulations!"
                />
                <img className="onboarding__img" src={APP_DOWNLOAD_IMG} alt=""/>
                <h2>Feedback form</h2>
                <div className="feedback-form">
                    <div className="feedback-form__input">
                        <label>
                            Question 1: On a scale of 0-10, how likely are you to recommend liveable to a friend or colleague?
                        </label>
                        <input
                            type="range"
                        />
                    </div>
                    <div className="feedback-form__input">
                        <label>
                            Question 2: Can you tell us why you gave the score you did? (Optional)
                        </label>
                        <textarea></textarea>
                    </div>
                    <button className="feedbac-form__submit">
                        submit
                    </button>
                </div>
                {/* <h2>Download your app</h2>
                <p>Mention benefits of downloading the application right here, use the oppurtunity to upsell the application.</p>
                <img className="onboarding__qr-code" src={QR_CODE} alt=""/>
                <button className="onboarding__scan">
                    <img src={BLUE_INFO_ICON} alt=""/>
                    Scan to download
                </button>
                <div className="onboarding__button-wrapper">
                    <button>
                        APPLE STORE
                        <img src={APPLE_ICON} alt=""/>
                    </button>
                    <button>
                        PLAY STORE
                        <img src={PLAYSTORE_ICON} alt=""/>
                    </button>
                </div> */}
            </div>
        </VerificationLayout>
    )
}
export default Onboarding;