import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { $api } from "../../../services";
import { RootState } from "../../store";

interface IFilter {
    availableLocations: undefined | string[],
    allAvailableTypes: any[],
    availableType: string[] | undefined,
    availablePrice: string[] | undefined, 
    location: ILocation,
    price: ILocation,
    bedroom: number | undefined,
    bathroom: number | undefined,
    availableBedroom: number[],
    availableBathroom: number[],
    service: boolean | undefined,
    type: ILocation,
}
interface ILocation {
    name: string | undefined,
    id: string | undefined,
}
const initialState:IFilter = {
    availableLocations: undefined,
    availableBedroom: [1,2,3,4,5,6,7,8,9,10],
    availableBathroom: [1,2,3,4,5,6,7,8,9,10],
    availableType: undefined,
    allAvailableTypes: [],
    location: {
        name: undefined,
        id: undefined,
    },
    price: {
        name: undefined,
        id: undefined
    },
    bedroom: undefined,
    bathroom: undefined,
    availablePrice: undefined,
    service: undefined,
    type: {
        name: '',
        id: '',
    },
} 
type AppThunk = ThunkAction<void, RootState, unknown, Action>;

const filterSlice = createSlice({
    'name': 'filter',
    initialState,
    reducers: {
        setLocationName: (state, action:PayloadAction<string>) => { state.location.name = action.payload },
        setLocationId: (state, action:PayloadAction<string>) => { state.location.id = action.payload },
        setPriceName: (state, action:PayloadAction<string>) => { state.price.name = action.payload},
        setPriceId: (state, action:PayloadAction<string>) => { state.price.id = action.payload},
        setBathroom: (state, action:PayloadAction<number>) => { state.bathroom = action.payload},
        setBedroom: (state, action:PayloadAction<number>) => { state.bedroom = action.payload},
        setAvailableLocations: (state, action:PayloadAction<string[]>) => { state.availableLocations = action.payload},
        setAvailableType: ( state, action:PayloadAction<string[]>) => { state.availableType = action.payload },
        setAllAvailableTypes: ( state, action:PayloadAction<string[]>) => { state.allAvailableTypes = action.payload },
        setAvailablePrice: (state, action:PayloadAction<string[]>) => { state.availablePrice = action.payload },
        setService: (state, action:PayloadAction<boolean>) => { state.service = action.payload },
        setTypeId: (state, action:PayloadAction<string>) => { state.type.id = action.payload },
        setTypeName: (state, action:PayloadAction<string>) => { state.type.name = action.payload },
        clearFilter: (state) => {
            state.bathroom = undefined;
            state.bedroom = undefined;
            state.location.name = undefined;
            state.location.id = undefined;
            state.price.name = undefined;
            state.price.id = undefined;
            state.type.name = undefined;
            state.type.id = undefined;
             state.service = undefined
        }
    },
})

export const { 
    setLocationName, 
    setLocationId, 
    setPriceName, 
    setPriceId, 
    setBathroom, 
    setBedroom, 
    setAvailableLocations, 
    setAvailableType, 
    setAllAvailableTypes, 
    setAvailablePrice, 
    setService, 
    setTypeId, 
    setTypeName, 
    clearFilter 
} = filterSlice.actions

const filterReducer = filterSlice.reducer
export default filterReducer;

export const fetchAvailableLocations = ():AppThunk =>
    async (dispatch) => {
        let endpoint = `form-data/locations`
        const response:any = await $api.fetch(endpoint)
        dispatch(setAvailableLocations(response?.data))
    }
export const fetchAvailablePrice = ():AppThunk =>
    async (dispatch) => {
        let endpoint = `form-data/budgets`
        const response:any = await $api.fetch(endpoint)
        dispatch(setAvailablePrice(response?.data))
    }

export const fetchAvailableType = ():AppThunk =>
    async (dispatch) => {
        let endpoint = `form-data/types`
        const response:any = await $api.fetch(endpoint)
        dispatch(setAvailableType(response?.data))
    }

export const fetchAllAvailableTypes = ():AppThunk =>
    async (dispatch) => {
        let endpoint = `form-data/all-types`
        const response:any = await $api.fetch(endpoint)
        dispatch(setAllAvailableTypes(response?.data))
    }

