import { IconPickerItem } from "react-fa-icon-picker";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BLUE_ARROW_RIGHT, DOT} from "../../assets/image";
import { RootState } from "../../store/store";
import AvailableButton, { UnavailableButton } from "../buttons/availabeButton";


interface IVerificationLayoutApartmentCard {
    location: string,
    pricing: string,
    additionalFeatures?: IVerificationLayoutApartmentCardFeatures[],
    rooms?: string[],
    img: string,
    id: string,
    availableAt: string,
    slug: string,
}
interface IVerificationLayoutApartmentCardFeatures {
    feature: string,
    icon: string
}
const VerificationLayoutApartmentCard = (props:IVerificationLayoutApartmentCard) => {
    const { isAvailableForBooking } = useSelector((state: RootState) => state.booking )
    const router = useNavigate();

    return(
        <div className="verification-layout-apartment-card">
            <span className="verification-layout-apartment-card__img-container">
                {
                    isAvailableForBooking
                        ?
                        <AvailableButton availableAt={props.availableAt} style={{ position: 'absolute', marginTop: '11px', marginRight: '11px'}}/>
                        :
                        <UnavailableButton style={{ position: 'absolute', marginTop: '11px', marginRight: '11px'}}/>
                }
                <img src={props.img} alt=""/>
            </span>
            <div className="verification-layout-apartment-card__details">
                <div className="verification-layout-apartment-card__details__text">
                    <h4>{props.location}</h4>
                    <div className="verification-layout-apartment-card__details__text__rooms">
                        {
                            props.rooms
                                ?
                                props.rooms.map((room, index) => {
                                    return(
                                        <div key={index} className="room">
                                            <h6>{room}</h6>
                                            <img src={DOT} alt=""/>
                                        </div>
                                    )
                                })
                                :
                                ''
                        }
                    </div>
                    <div className="verification-layout-apartment-card__details__text__pricing">
                        <h4>{props.pricing}</h4>
                        {/* <button>
                            RENT BREAKDOWN
                            <img src={BLUE_VIEW} alt=""/>
                        </button> */}
                    </div>
                    <div className="verification-layout-apartment-card__details__text__features">
                        {
                            props.additionalFeatures
                                ?
                                props.additionalFeatures.map((feature:any, index) => {
                                    return(
                                        <ApartmentFeature key={index} feature={feature.name} icon={feature.icon_class}/>
                                    )
                                })
                                :
                                ''
                        }
                    </div>
                </div>
                {
                    isAvailableForBooking
                        &&
                    <button className="verification-layout-apartment-card__details__button" onClick={() => router(`/homes/${props.slug}`)} style={{ cursor: 'pointer'}}>
                        VIEW FULL DETAILS
                        <img src={BLUE_ARROW_RIGHT} alt=""/>
                    </button>
                }
            </div>
        </div>
    )
}
export default VerificationLayoutApartmentCard;

const ApartmentFeature = (props: any):JSX.Element => {
    const feature = props.feature
    const icon = props.icon
    return(
        <div style={{ display: 'flex', alignItems: 'center'}}>
            <IconPickerItem icon={icon} size={16} color="#616E9E" onClick={()=> {}} />
            <h5>{feature}</h5>
        </div>
    )
}