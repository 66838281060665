import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BLUE_ARROW_DOWN, SEARCH_LOCATION } from "../../assets/image";
import { $api } from "../../services";
import ErrorMessage from "../errorMessage";

interface ILocationSearchSelect {
    setQueryModalActive: React.Dispatch<React.SetStateAction<boolean>>,
    setLocation: React.Dispatch<React.SetStateAction<string[]>>,
    setLocationId: React.Dispatch<React.SetStateAction<string[]>>,
    queryModalActive: boolean,
    location: string[],
    triedToSubmit: boolean,
    reference: React.RefObject<HTMLInputElement>,
    placeholder: string,
}
interface IOptions {
    id: string,
    name: string,
}

interface IOptionsCheckbox {
    setLocation: React.Dispatch<React.SetStateAction<string[]>>
    setLocationId: React.Dispatch<React.SetStateAction<string[]>>
    setQueryModalActive: React.Dispatch<React.SetStateAction<boolean>>,
    option: string,
    id: string,
    location: string[],
}

const MultiSearchSelect = (props: ILocationSearchSelect) => {
    const [ options, setOptions ] = useState([]);
    const setQueryModalActive = props.setQueryModalActive 
    const queryModalActive = props.queryModalActive
    const [ query, setQuery ] = useState<string>('')
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const location = props.location
    const setLocation = props.setLocation
    const setLocationId = props.setLocationId
    const triedToSubmit = props.triedToSubmit

    const source = axios.CancelToken.source();
    
    useEffect(() => {
        query.length > 2 && fetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ query ])

    const fetchLocation = async() => {
        setIsLoading(true)
        const response = await $api.fetchWithCancelToken(`form-data/all-locations?search=${query}`, source.token)
        if($api.isSuccessful(response)){
            setOptions(response?.data)
            setIsLoading(false)
        }
        else{
            setIsLoading(false)
        }
    }
    
    return(
        <div className="multi-search-select" style={{ margin: '0'}}>
            <div className="multi-search-select__location">
                <div className="multi-search-select__location__field" onClick={() => setQueryModalActive(true)} style={{ margin: '0'}}>
                    <div className="multi-search-select__location__field__input">
                        {
                            location.length > 0
                                ?
                                location.map((location: string, index) => {
                                    return(
                                        <div className="multi-search-select__location__field__input__option" 
                                            onClick={() => {
                                                setLocationId(prev => {
                                                    const newArray = [...prev];
                                                    newArray.splice(index, 1);
                                                    return newArray;
                                                });
                                                setLocation(prev => {
                                                    const newArray = [...prev];
                                                    newArray.splice(index, 1);
                                                    return newArray;
                                                });
                                            }} 
                                        >
                                            <h4>{location}</h4>
                                        </div>
                                    )
                                })
                                :
                                <h4 className="placeholder">{props.placeholder}</h4>
                        }
                    </div>
                    <img src={BLUE_ARROW_DOWN} alt=""/>
                </div>
                { triedToSubmit && location.length < 1 && <ErrorMessage message="location is required"/>}
            </div>
            {
                queryModalActive &&
                <div className="multi-search-select__dropdown" ref={props.reference}>
                    <div className="multi-search-select__dropdown__searchbar">
                        <img src={SEARCH_LOCATION} alt=""/>
                        <input
                            type="text"
                            placeholder="Search for a location"
                            onChange={(e) => { source.cancel(); setQuery(e.target.value) }}
                            value={query}
                        />
                    </div>
                    <div className="multi-search-select__dropdown__options">
                        {
                            isLoading
                                ?
                                <div className="loading"></div>
                                :
                                options.length > 0
                                    ?
                                    options.map((option:IOptions) => {
                                        return (
                                            <OptionsCheckbox key={option.id} setLocation={setLocation} location={location} setLocationId={setLocationId} option={option.name} id={option.id} setQueryModalActive={setQueryModalActive}/>
                                        )
                                    })
                                    :
                                    <p>No results...</p>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

const OptionsCheckbox = (props: IOptionsCheckbox) => {
    const setLocation = props.setLocation
    const setLocationId = props.setLocationId
    const location = props.location
    const selected = location.includes(props.option)

    const handleClick = () => {
        if(selected){
            setLocationId((prev) => prev.filter(id => id !== props.id));
            setLocation((prev) => prev.filter(id => id !== props.option));
        }
        else{
            if(location.length < 3 ){
                setLocationId((prev) => [...prev, props.id] )
                setLocation((prev) => [ ...prev, props.option ])
            }
            else{
                toast.error("You cant select more than 3 locations")
            }
        }
    }
    return(
        <div 
            onClick={() => handleClick()}
            className={ selected ? "option-card selected" : "option-card"}
        >
            <p>{props.option}</p>
        </div>
    )
}
export default MultiSearchSelect;