import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApartmentCard, { ApartmentCardLoader } from "../components/cards/apartmentCard";
import GetHelpForm from "../components/forms/getHelpForm";
import PageLayout from "../layout/pageLayout";
import { $api } from "../services";

const NoResult = () => {
    const [ isFetching, setIsFetching ] = useState<boolean>(false);
    const [ properties, setProperties ] = useState<any[]>([]);

    const fetchExpiringProperties = async() => {
        setIsFetching(true)
        const response = await $api.fetch('v2/lv/expiring-units')
        if($api.isSuccessful(response)){
            setProperties(response?.data)
        }
        else{
            toast.error('Failed to fetch properties')
        }
        setIsFetching(false)
    }

    useEffect(() => {
        fetchExpiringProperties()
    },[]);

    useEffect(() => {
        window.scrollTo(0,0)
    },[]);

    const renderProperties = () => {
        return(
        isFetching
            ?
            properties.length > 0
                ?
                properties.map((property: any) => {
                    return(
                        <ApartmentCard 
                            key={property.id}
                            id={property.id}
                            location={property.name + ", " + property.location.sub_area + ", " + property.location.state } 
                            pricing={property.amount}
                            img={property?.main_image?.high}
                            lowResImg={property?.main_image?.thumbnail}
                            unit_summary={property?.unit_summary}
                            rooms={[
                                `${property?.location?.area}`,
                                `${property.bedroom} bedroom`,
                                `${property.bathroom} bathroom`
                            ]} 
                            isWaitlist={true}
                            slug={property.slug}
                            availableAt={property?.availability}
                            additionalFeatures={property?.ammenities}
                        />
                    )
                    })
                :
                ''
            :
            <>
                <ApartmentCardLoader/>
                <ApartmentCardLoader/>
                <ApartmentCardLoader/>
                <ApartmentCardLoader/>
                <ApartmentCardLoader/>
                <ApartmentCardLoader/>
                <ApartmentCardLoader/>
                <ApartmentCardLoader/>
                <ApartmentCardLoader/>
            </>
        )
    }

    return(
        <PageLayout>
            <div className="no-result">
                <GetHelpForm/>
                {
                    (isFetching || properties.length !== 0)
                        &&
                    <div className="no-result__current-waitlist">
                        <div>
                            <h2>Join our current waitlist</h2>
                        </div>
                        <div className="no-result__current-waitlist__apartments">
                            {renderProperties()}
                        </div>
                    </div>
                }
            </div>
        </PageLayout>
    )
}
export default NoResult;