import PageLayout from "../layout/pageLayout";

const NotFound = () => {
    return(
        <PageLayout>
            <div className="not-found">
                <h2>404 | Not Found</h2>
            </div>
        </PageLayout>
    )
}
export default NotFound;