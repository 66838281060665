import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WHITE_RIGHT_ARROW } from "../../assets/image"
import { RootState } from "../../store/store";

const ViewProgress = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    const property = useSelector((state: RootState) => state.property);
    const router = useNavigate();

    return(
        <button className="continue-process" {...props} style={{cursor: 'pointer'}} 
            onClick={() => {
                property?.property?.booking?.status === 'booking' && router(`/booking/${property?.property?.booking.id}`)
                property?.property?.booking?.status === 'selected' && router(`/payment/${property?.property?.booking.id}`)
                property?.property?.booking?.status === 'booking-confirmed' && router(`/booking-confirmed/${property?.property?.booking.id}`);
                ( 
                    property?.property?.booking?.status === 'verification-form-pending' ||
                    property?.property?.booking?.status === 'verification-form-in-progress' ||
                    property?.property?.booking?.status === 'verification-restart' ||
                    property?.property?.booking?.status === 'verification-failed' ||
                    property?.property?.booking?.status === 'verification' || property?.property?.booking.status === 'verification' 
                ) && router(`/verify/${property?.property?.booking?.id}`);
                ( 
                    property?.property?.booking?.status === 'ongoing-verification' || 
                    property?.property?.booking?.status === 'verification-form-completed' 
                ) && router(`/verification-in-progress/${property?.property?.booking?.id}`)
            }}
        >
            VIEW PROGRESS
            <img src={WHITE_RIGHT_ARROW} alt=""/>
        </button>
    )
}
export default ViewProgress;