import { useState } from "react";
import { useDispatch } from "react-redux";
import { ARROW_LEFT, CLOSE_ICON, EMAIL_IMG, NO_VIEW_PASSWORD, VIEW_PASSWORD, WHITE_CHECK_ICON } from "../../assets/image";
import AuthLayout from "../../layout/authLayout"
import { setLoginSuccessActive, setModalInactive, setSignUpActive, setSuccessMessage } from "../../store/slices/modal/modalSlice";

const SetPassword = () => {
    const [ passwordVisibility, setPasswordVisibility ] = useState(false)
    const [ confirmPasswordVisibility, setConfirmPasswordVisibility ] = useState(false);
    const dispatch = useDispatch();
    return(
        <AuthLayout>
            <div className="set-password">
                <div className="set-password__top">
                    <div className="set-password__top__back-button" onClick={() => dispatch(setSignUpActive())}>
                        <img src={ARROW_LEFT} alt=""/>
                        <p><span>Enter Email</span> / Set Password</p>
                    </div>
                    <div className="set-password__top__close" onClick={() => dispatch(setModalInactive())}>
                        <img src={CLOSE_ICON} alt=""/>
                    </div>
                </div>
                <div className="set-password__main">
                    <img src={EMAIL_IMG} alt=""/>
                    <p>Create an account password</p>
                    <div className="set-password__main__password">
                        <input
                            type={ passwordVisibility ? "text" : "password" }
                            placeholder="Enter Password"
                        />
                        <img 
                            src={passwordVisibility ? VIEW_PASSWORD : NO_VIEW_PASSWORD} 
                            alt=""
                            onClick={() => setPasswordVisibility(!passwordVisibility)}
                        />
                    </div>
                    <div className="set-password__main__password">
                        <input
                            type={ confirmPasswordVisibility ? "text" : "password" }
                            placeholder="Re-enter Password"
                        />
                        <img 
                            src={confirmPasswordVisibility ? VIEW_PASSWORD : NO_VIEW_PASSWORD} 
                            onClick={() => setConfirmPasswordVisibility(!confirmPasswordVisibility)} 
                            alt=""
                        />
                    </div>
                    <button>
                        <img src={WHITE_CHECK_ICON} alt="" onClick={() => {dispatch(setLoginSuccessActive()); dispatch(setSuccessMessage('Signup'))}}/>
                        FINISH SIGN UP
                    </button>
                </div>
            </div>
        </AuthLayout>
    )
}
export default SetPassword;