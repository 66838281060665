import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { $api } from "../../services";

const WaitlistButton = ({setWaitlistSuccessfulModalActive, setJoinWaitlistModalActive } : {setWaitlistSuccessfulModalActive: React.Dispatch<React.SetStateAction<boolean>>, setJoinWaitlistModalActive: React.Dispatch<React.SetStateAction<boolean>>}) => {
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const user = localStorage.getItem('liveableUser');
    var unit_id: string

    let { slug } = useParams();

    if (slug) {
        let slugArray = slug.split("-");
        const slugLength = slugArray.length
        unit_id = slugArray[slugLength-1]
    }

    const handleClick = () => {
        user
            ?
            joinWaitlist()
            :
            setJoinWaitlistModalActive(true)
    }

    const joinWaitlist = async() => {
        setIsLoading(true)
        const response = await $api.post('v2/lv/join-waitlist',{
            unit_id
        })
        if($api.isSuccessful(response)){
            setWaitlistSuccessfulModalActive(true)
        }
        else{
            toast.error('Failed to join waitlist')
        }
        setIsLoading(false)
    }
    
    return(
        <button className="waitlist-button" onClick={() => handleClick() }>
            {
                isLoading
                    ?
                    <div className="loading"></div>
                    :
                    "Join Waitlist"
            }
        </button>
    )
}
export default WaitlistButton;