import { CLOSE_ICON } from "../assets/image";

interface IMonthlyDepositModal{
    setMonthlyDepositModalActive: React.Dispatch<React.SetStateAction<boolean>>
}

const MonthlyDepositModal = (props: IMonthlyDepositModal) => {
    const setMonthlyDepositModalActive = props.setMonthlyDepositModalActive
    return(
        <div className="overlay">
            <div className="bank-deposit">
                <div className="bank-deposit__top">
                    <img onClick={() => setMonthlyDepositModalActive(false)} src={CLOSE_ICON} alt=""/>
                </div>
                <div className="bank-deposit__main">
                    <h4 className="text-center">Thanks for choosing the monthly payment option</h4>
                    <p className="text-center">An email has been sent to you with an application form to begin the process. </p>
                </div>
            </div>
        </div>
    )
}
export default MonthlyDepositModal;