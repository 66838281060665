import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { LOGIN_SUCCESSFUL } from "../../assets/image"
import AuthLayout from "../../layout/authLayout"
import { setFrom, setModalInactive } from "../../store/slices/modal/modalSlice";
import { RootState } from "../../store/store";

interface LoginSuccessful {
    text: string,
}
const LoginSuccess = (props:LoginSuccessful) => {
    const router = useNavigate()
    const dispatch = useDispatch();
    const { from } = useSelector((state:RootState) => state.modal)
    useEffect(() => {
        const reroute = setTimeout(() => {
            router(from || '/apartment')
            dispatch(setModalInactive())
            dispatch(setFrom(''))
        },1300)
        return() => {
            clearTimeout(reroute)
        }
    },[ router, dispatch, from ])

   return(
     <AuthLayout>
        <div className="login-successful">
            <div className="login-successful__main">
                <img src={LOGIN_SUCCESSFUL} alt=""/>
                <p>Your {props.text} was successful.</p>
            </div>
        </div>
    </AuthLayout>
   )
}
export default LoginSuccess