import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store/store";

const ListingProgressBar = ():JSX.Element => {
    const progressBar = useSelector((state:RootState) => state.progressBar)
    const router = useNavigate();
    return(
        <div className="listing-progress-bar">
            <div className="listing-progress-bar__main">
                <div onClick={() => router('/list')} className={ progressBar.basicInfoStatus.status === 1 ? 'active' : 'completed'}>
                    <img src={progressBar.basicInfoStatus.img} alt=""/>
                </div>
                <span className={progressBar.basicInfoStatus.status === 2 ? 'finished' : ''}></span>
                <div onClick={() => router('/list/apartment-info')} className={ progressBar.apartmentInfoStatus.status === 1 ? 'active' : progressBar.apartmentInfoStatus.status === 2 ? 'completed' : '' }>
                    <img src={progressBar.apartmentInfoStatus.img} alt=""/>
                </div>
                <span className={progressBar.apartmentInfoStatus.status === 2 ? 'finished' : ''}></span>
                <div onClick={() => router('/list/confirmation')} className={progressBar.confirmationStatus.status === 1 ? 'active' : progressBar.confirmationStatus.status === 2 ? 'completed' : '' }>
                    <img src={progressBar.confirmationStatus.img} alt=""/>
                </div>
            </div>
            <div className="listing-progress-bar__text">
                <h4 className={ progressBar.basicInfoStatus.status >= 1 ? 'valid' : ''}>Basic Info</h4>
                <h4 className={progressBar.apartmentInfoStatus.status >= 1 ? 'valid' : ''}>Apartment Info</h4>
                <h4 className={progressBar.confirmationStatus.status >= 1 ? 'valid' : ''}>Confirmation</h4>
            </div>
        </div>
    )
}
export default ListingProgressBar;