import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ARROW_DOWN_ICON, BLUE_LOCATION_ICON, BLUE_MONEY_ICON, BLUE_ROOM_ICON, NO_FOUND_APARTMENT, WHITE_MAIL_ICON } from "../assets/image";
import ApartmentCard, { ApartmentCardLoader } from "../components/cards/apartmentCard";
import PageLayout from "../layout/pageLayout";
import { fetchAvailableLocations, fetchAvailablePrice, fetchAvailableType, setBathroom, setBedroom, setLocationId, setLocationName, setPriceId, setPriceName, setTypeId, setTypeName } from "../store/slices/filter/filterSlice";
import { fetchMoreProperties, filterProperties, setMorePropertiesLoading } from "../store/slices/properties/propertiesSlice";
import { AppDispatch } from "../store/slices/properties/types";
import { RootState } from "../store/store";

const SearchPage = () => {
    const [ locationFilterActive, setLocationFilterActive ] = useState<boolean>(false);
    const [ priceFilterActive, setPriceFilterActive ] = useState<boolean>(false);
    const [ bedroomFilterActive, setBedroomFilterActive ] = useState<boolean>(false);
    const [ bathroomFilterActive, setBathroomFilterActive ] = useState<boolean>(false);
    const [ typeFilterActive, setTypeFilterActive ] = useState<boolean>(false);
    const [ currentPage, setCurrentPage] = useState<number>(1);
    const [hasRendered, setHasRendered] = useState(false);

    const properties = useSelector((state:RootState) => state.properties );

    const dispatch = useDispatch<AppDispatch>();

    const locationRef = useRef<any>(null);
    const priceRef = useRef<any>(null);
    const bedroomRef = useRef<any>(null);
    const bathroomRef = useRef<any>(null);
    const typeRef = useRef<any>(null);

    const filter = useSelector((state:RootState) => state.filter)

    const filterData = {
        page: currentPage,
        location_id: filter.location.id,
        room: filter.bedroom,
        budget_id: filter.price.id,
        type_id: filter.type.id,
    };
    
    useEffect(() => {
        !hasRendered && window.scrollTo(0,0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if (!hasRendered) {
            setHasRendered(true);
            return;
        }    
        handleFetchMoreProperties();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage])
    
    const handleFetchMoreProperties = () => {
        dispatch(setMorePropertiesLoading(true))
        dispatch(fetchMoreProperties(filterData))
    }

    useEffect(() => {
        dispatch(filterProperties(filterData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ filter.location, filter.price, filter.bathroom, filter.bedroom, filter.service, filter.type ])


    useEffect(() => {
        properties &&
        !filter.availableLocations && dispatch(fetchAvailableLocations());
        !filter.availableType && dispatch(fetchAvailableType());
        !filter.availablePrice && dispatch(fetchAvailablePrice());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const renderProperties = () => {
        return(
            properties.properties
                ?
                properties.properties.map((property: any) => {
                    return(
                        <ApartmentCard
                            key={property.id} 
                            id={property.id}
                            location={property.name} 
                            pricing={property.amount}
                            img={property?.main_image?.high}
                            lowResImg={property?.main_image?.thumbnail}
                            unit_summary={property?.unit_summary}
                            slug={property?.slug}
                            rooms={[
                                `${property.location.area}`,
                                `${property.bedroom} bedroom`,
                                `${property.bathroom} bathroom`
                            ]} 
                            availableAt={property?.availability}
                            additionalFeatures={property?.ammenities}
                        />
                    )
                    })
                :
                ''
        )
    }

    const handleClick = (e:any) => {
        if (locationFilterActive && locationRef.current && !locationRef.current.contains(e.target)) {
            setLocationFilterActive(false);
        }
        else if (priceFilterActive && priceRef.current && !priceRef.current.contains(e.target)) {
            setPriceFilterActive(false);
        }
        else if (bedroomFilterActive && bedroomRef.current && !bedroomRef.current.contains(e.target)) {
            setBedroomFilterActive(false);
        }
        else if (bathroomFilterActive && bathroomRef.current && !bathroomRef.current.contains(e.target)) {
            setBathroomFilterActive(false);
        }
        // else if (serviceFilterActive && serviceRef.current && !serviceRef.current.contains(e.target)) {
        //     setServiceFilterActive(false);
        // }
        else if (typeFilterActive && typeRef.current && !typeRef.current.contains(e.target)) {
            setTypeFilterActive(false);
        }
    }

    return(
        <Fragment>
        {/* { filterActive && <FiltersCard setFilterActive={setFilterActive}/>} */}
        <PageLayout>
            <div className="search" onClick={(e) => handleClick(e)}>
                <div className="search__top">
                    <div className="search__top__location">
                        <div onClick={() => setLocationFilterActive(true)} style={{ cursor: 'pointer'}}>
                            <img src={BLUE_LOCATION_ICON} alt=""/>
                            <p>{ !filter.location.name ? 'Location' : filter.location.name}</p>
                            <img src={ARROW_DOWN_ICON} alt=""/>
                        </div>
                        { locationFilterActive &&
                                <ul ref={locationRef}>
                                    {
                                        filter.availableLocations
                                            ?
                                            filter.availableLocations.map((location: any) => {
                                                return <li key={location.id} style={{ cursor: 'pointer'}} onClick={() => { setLocationFilterActive(false); dispatch(setLocationId(location.id)); dispatch(setLocationName(location.name))}}>{location.name}</li>
                                            }
                                            )
                                            : 
                                            <li>No result</li>
                                    }
                                </ul>
                        } 
                    </div>
                    <div>
                        <div onClick={() => setTypeFilterActive(true)} style={{ cursor: 'pointer'}}>
                            <img src={BLUE_LOCATION_ICON} alt=""/>
                            <p>{ !filter.type.name ? 'Type' : filter.type.name}</p>
                            <img src={ARROW_DOWN_ICON} alt=""/>
                        </div>
                        { typeFilterActive &&
                                <ul ref={typeRef}>
                                    {
                                        filter.availableType
                                            ?
                                            filter.availableType.map((type: any) => {
                                                return <li key={type.id} style={{ cursor: 'pointer'}} onClick={() => { setTypeFilterActive(false); dispatch(setTypeId(type.id)); dispatch(setTypeName(type.name))}}>{type.name}</li>
                                            }
                                            )
                                            : 
                                            <li>No result</li>
                                    }
                                </ul>
                        } 
                    </div>
                    <div>
                        <div onClick={() => setPriceFilterActive(true)} style={{ cursor: 'pointer'}}>
                            <img src={BLUE_MONEY_ICON} alt=""/>
                            <p>{ !filter.price.name ? 'Price' : filter.price.name}</p>
                            <img src={ARROW_DOWN_ICON} alt=""/>
                        </div>
                        { priceFilterActive &&
                                <ul ref={priceRef}>
                                    {
                                        filter.availablePrice
                                            ?
                                            filter.availablePrice.map((price: any) => {
                                                return <li key={price.id} style={{ cursor: 'pointer'}} onClick={() => { setPriceFilterActive(false); dispatch(setPriceId(price.id)); dispatch(setPriceName(price.name))}}>{price.name}</li>
                                            }
                                            )
                                            : 
                                            <li>No result</li>
                                    }
                                </ul>
                           } 
                    </div>
                    <div>
                        <div onClick={() => setBedroomFilterActive(true)} style={{ cursor: 'pointer'}}>
                            <img src={BLUE_ROOM_ICON} alt=""/>
                            <p>{filter.bedroom} Bedroom</p>
                            <img src={ARROW_DOWN_ICON} alt=""/>
                        </div>
                        { bedroomFilterActive &&
                                <ul ref={bedroomRef}>
                                    {
                                        filter.availableBedroom
                                            ?
                                            filter.availableBedroom.map((bedroom: number,index) => {
                                                return <li key={index} style={{ cursor: 'pointer'}} onClick={() => { setBedroomFilterActive(false); dispatch(setBedroom(bedroom))}}>{bedroom} Bedroom</li>
                                            }
                                            )
                                            : 
                                            <li>No result</li>
                                    }
                                </ul>
                           } 
                    </div>
                    {/* <div>
                        <div style={{ cursor: 'pointer'}} onClick={() => setBathroomFilterActive(true)}>
                            <img src={BLUE_ROOM_ICON} alt=""/>
                            <p>{filter.bathroom} Bathroom</p>
                            <img src={ARROW_DOWN_ICON} alt=""/>
                        </div>
                        { bathroomFilterActive &&
                                <ul ref={bathroomRef}>
                                    {
                                        filter.availableBathroom
                                            ?
                                            filter.availableBathroom.map((bathroom: number,index) => {
                                                return <li key={index} style={{ cursor: 'pointer'}} onClick={() => { setBathroomFilterActive(false); dispatch(setBathroom(bathroom))}}>{bathroom} Bathroom</li>
                                            }
                                            )
                                            : 
                                            <li>No result</li>
                                    }
                                </ul>
                           } 
                    </div> */}
                    {/* <div className="search__top__location">
                        <div style={{ cursor: 'pointer'}} onClick={() => setServiceFilterActive(true)}>
                            <img src={BLUE_SERVICE_ICON} alt=""/>
                            <p>
                                {
                                    filter.service === undefined
                                        ?
                                        "Condition"
                                        :
                                        filter.service === true
                                        ?
                                        "Serviced"
                                        :
                                        "Not Serviced"
                                }
                            </p>
                            <img src={ARROW_DOWN_ICON} alt=""/>
                        </div>
                        { serviceFilterActive &&
                                <ul ref={serviceRef}>
                                        <li style={{ cursor: 'pointer'}} onClick={() => { setServiceFilterActive(false); dispatch(setService(true))}}>Serviced</li>
                                        <li style={{ cursor: 'pointer'}} onClick={() => { setServiceFilterActive(false); dispatch(setService(false))}}>Not Serviced</li>
                                </ul>
                           } 
                    </div> */}
                    {/* <button onClick={() =>  setFilterActive(true)}>
                        <img src={BLUE_FILTER_ICON} alt=""/>
                        MORE FILTERS
                    </button> */}
                </div>
                <div className="search__cards">
                    <div className="search__apartment-card-wrapper">
                        <>
                            {
                                (properties.propertiesLoading || !properties.properties)
                                    ?
                                    <>
                                        <ApartmentCardLoader/>
                                        <ApartmentCardLoader/>
                                        <ApartmentCardLoader/>
                                        <ApartmentCardLoader/>
                                        <ApartmentCardLoader/>
                                    </>
                                    :
                                    renderProperties()
                            }
                        </>
                        {
                            (properties.propertiesLoading || !properties.properties)
                                ?
                                <ApartmentCardLoader/>
                                :
                                <div className="search__apartment-card-wrapper__not-found">
                                    <img src={NO_FOUND_APARTMENT} alt=""/>
                                    <h4>Can’t find what you are looking for?</h4>
                                    <Link to="/prefer">
                                        <img src={WHITE_MAIL_ICON} alt=""/>
                                        REACH OUT TO US
                                    </Link>
                                </div>
                        }
                    </div>
                </div>
                {
                    !(properties.propertiesLoading || !properties.properties) &&
                    (
                        properties.meta.current_page === properties.meta.last_page
                            ?
                            ''
                            :
                            <button className="search__more" disabled={properties.morePropertiesLoading} onClick={() => setCurrentPage(currentPage + 1)}>
                                {
                                    properties.morePropertiesLoading
                                        ?
                                        <div className="loading"></div>
                                        :
                                        "SEE MORE"
                                }
                            </button>
                    )
                }
            </div>
        </PageLayout>
        </Fragment>
    )
}
export default SearchPage;