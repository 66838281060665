import { useEffect, useState } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ARROW_LEFT, CLOSE_ICON, LOGIN, LOGIN_BTN_ICON, NO_VIEW_PASSWORD, VIEW_PASSWORD } from "../../assets/image"
import ErrorMessage from "../../components/errorMessage";
import AuthLayout from "../../layout/authLayout"
import { $api } from "../../services";
import { setAccessToken, setUser, setUserEmail, setUserId } from "../../store/slices/auth/authSlice";
import { setLoginActive, setModalInactive, setSuccessMessage, setLoginSuccessActive, setFrom } from "../../store/slices/modal/modalSlice";
import ReactGa from 'react-ga';
import { events } from "../../components/analyticsEvents";
import { RootState } from "../../store/store";

const EMAIL_REGEX = /^(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}$/;
const SignUp = () => {
    const dispatch = useDispatch();
    const [ email, setEmail ] = useState<string>('');
    const [ firstName, setFirstName ] = useState<string>('');
    const [ lastName, setLastName ] = useState<string>('');
    const [ phoneNumber, setPhoneNumber ] = useState<string>();
    const [ phoneNumberValid, setPhoneNumberValid ] = useState<boolean>(false);
    const [ password, setPassword ] = useState<string>('');
    const [ passwordValid, setPasswordValid ] = useState<boolean>(false)
    const [ emailValid, setEmailValid ] = useState<boolean>(false)
    const [ pageValid, setPageValid ] = useState<boolean>(false)
    const [ triedToSubmit, setTriedToSubmit ] = useState<boolean>(false);
    const [ passwordVisibility, setPasswordVisibility ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const { from } = useSelector((state:RootState) => state.modal)


    useEffect(() => {
        EMAIL_REGEX.test(email) ? setEmailValid(true) : setEmailValid(false)
        password.length > 6 ? setPasswordValid(true) : setPasswordValid(false)
        phoneNumber
            ?
            phoneNumber.length >= 14 ? setPhoneNumberValid(true) : setPhoneNumberValid(false)
            :
            setPhoneNumberValid(false)
    },[ email, password, phoneNumber ])

    useEffect(() => {
        emailValid && passwordValid && firstName && lastName && phoneNumberValid ? setPageValid(true) : setPageValid(false)
    },[ emailValid, passwordValid, firstName, lastName, phoneNumberValid ])

    const handleValidSubmit = async() => {
        setIsLoading(true)
        const response = await $api.post('v2/lv/auth/register', { email, password, first_name: firstName, last_name: lastName, phone: phoneNumber })
        if ($api.isSuccessful(response)){
            dispatch(setSuccessMessage('Signup'));
            dispatch(setLoginSuccessActive());
            dispatch(setAccessToken(response?.data?.token?.access_token))
            dispatch(setUserId(response?.data?.user?.id))
            dispatch(setUserEmail(email))
            const obj = {
                first_name: response?.data?.user?.first_name,
                last_name: response?.data?.user?.last_name,
                email: response?.data?.user?.email
            }
            localStorage.setItem('liveableUserBio', JSON.stringify(obj));
            const Capitalize = (text: string) => {
                    text.charAt(0).toUpperCase();
                return text;
            }
            const capitalizedFirstName = Capitalize(firstName)
            const capitalizedLastName = Capitalize(lastName)
            dispatch(setUser(capitalizedFirstName +" "+ capitalizedLastName))
            ReactGa.event(events.userSignup)
            setEmail('')
            setPassword('')
            setFirstName('')
            setLastName('')
            setPhoneNumber('')
        }
        else{
            toast.error('Sign up failed')
        }
        setIsLoading(false)
    }
    const handleSubmit = () => {
        pageValid
            ? 
            handleValidSubmit()
            :
            setTriedToSubmit(true)
    }


    return(
        <AuthLayout>
            <div className="signup">
                <div className="signup__top">
                    <button className="signup__top__back-button" style={{ cursor: 'pointer'}} onClick={() => dispatch(setModalInactive())}>
                        <img src={ARROW_LEFT} alt=""/>
                        Enter Email
                    </button>
                    <div className="signup__top__close-icon" style={{ cursor: 'pointer'}} onClick={() => { dispatch(setModalInactive()); dispatch(setFrom(''))}}>
                        <img src={CLOSE_ICON} alt=""/>
                    </div>
                </div>
                <div className="signup__main">
                    <div className="signup__main__img">
                        <img src={LOGIN} alt=""/>
                        { !from &&  <p>You need to sign up so that you can save and continue the process.</p>}
                    </div>
                    <div className="signup__main__form">
                        {/* <button className="signup__main__form__google">
                            <img src={GOOGLE_ICON} alt=""/>
                            SIGN IN USING GMAIL
                        </button>
                        <div className="signup__main__form__or">
                            <span></span>
                            <p>OR</p>
                            <span></span>
                        </div> */}
                        <div className="signup__main__form__main">
                            <div className="signup__main__form__main__email">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                { triedToSubmit && !firstName && <ErrorMessage message="Enter your first name"/>}
                            </div>
                            <div className="signup__main__form__main__email">
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                                { triedToSubmit && !lastName && <ErrorMessage message="Enter your last name"/>}
                            </div>
                            <div className="signup__main__form__main__email">
                                <input
                                    type="email"
                                    placeholder="Email Address"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                                { triedToSubmit && !emailValid && <ErrorMessage message={ email === '' ? "Enter your email" : "Enter a valid email"}/>}
                            </div>
                            <div className="signup__main__form__main__email">
                                <PhoneInputWithCountrySelect
                                    value={phoneNumber}
                                    onChange={(value) => setPhoneNumber(value)}
                                    defaultCountry="NG"
                                    placeholder="08175435854"
                                />
                                { triedToSubmit &&  !phoneNumberValid && <ErrorMessage message={ phoneNumber === '' ? "Enter your phone number" : "Enter a valid phone number"}/>}
                            </div>
                            <div className="signup__main__form__main__email">
                                <div className="signup__main__form__main__password">
                                    <input
                                        type={ passwordVisibility ? "text" : "password" }
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <img 
                                        src={passwordVisibility ? VIEW_PASSWORD : NO_VIEW_PASSWORD} 
                                        alt=""
                                        onClick={() => setPasswordVisibility(!passwordVisibility)}
                                    />
                                </div>
                                { triedToSubmit && !passwordValid && <ErrorMessage message={ password === '' ? "Enter your password" : "Password must be more than 6 characters"}/>}
                            </div>
                        </div>
                        <button className="signup__main__form__signup" style={{ cursor: 'pointer'}} onClick={() => handleSubmit()}>
                            {
                                isLoading
                                    ? 
                                    <div className="loading"></div>
                                    :
                                    <>
                                        <img src={LOGIN_BTN_ICON} alt=""/>
                                        { from.includes('/verify') ? "RENT NOW" : from.includes('/booking') ? "CREATE BOOKING" : "SIGN UP"}
                                    </>

                            }
                        </button>
                        <h5>Already have an account ? <span style={{ cursor: 'pointer'}} onClick={() => dispatch(setLoginActive())}>LOGIN</span></h5>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}
export default SignUp;