import { BLUE_ARROW_RIGHT, ERROR, WHITE_RESCHEDULE_ICON } from "../../assets/image";

const ErrorComponent = () => {
    return(
        <div className="error-component">
            <img src={ERROR} alt=""/>
            <h2>Sorry, something went wrong. Please try refreshing the page to see if that resolves the issue. If the problem persists, please contact our support team for further assistance.</h2>
            <div className="error-component__button-wrapper">
                <button onClick={() => window.location.reload()}>Refresh
                    <img src={WHITE_RESCHEDULE_ICON} alt=""/>
                </button>
                <a rel="noreferrer" target="_blank" href={"https://api.whatsapp.com/send/?phone=2347031175938&text=I encountered an error while using your site - liveable.ng - " }>
                    Support
                    <img src={BLUE_ARROW_RIGHT} alt=""/>
                </a>
            </div>
        </div>
    )
}
export default ErrorComponent;