import { useEffect, useState } from "react";
import { CLOSE_ICON } from "../../assets/image";
import ErrorMessage from "../errorMessage";

interface IGoodTenantForm  {
    setGoodTenantFormActive: React.Dispatch<React.SetStateAction<boolean>>
}

const EMAIL_REGEX = /^(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!outlook.com)(?!aol.com)(?!icloud.com)(?!zoho.com)(?!fastmail.com)(?!mail.com)(?!rediffmail.com)(?!yandex.com)(?!protonmail.com)(?!gmx.com)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}$/;
const GoodTenantForm = (props: IGoodTenantForm) => {

    const setGoodTenantFormActive = props.setGoodTenantFormActive
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ employmentStatus, setEmploymentStatus ] = useState<string>('');
    const [ salaryRange, setSalaryRange ] = useState<string>('');
    const [ employerName, setEmployerName ] = useState<string>();

    const [ workEmail, setWorkEmail ] = useState<string>('');
    const [ workEmailValid, setWorkEmailValid ] = useState<boolean>(false);

    const [ triedToSubmit, setTriedToSubmit ] = useState<boolean>(false)
    const [ formValid, setFormValid ] = useState<boolean>(false);

    useEffect(() => {
        EMAIL_REGEX.test(workEmail)
            ?
            setWorkEmailValid(true)
            :
            setWorkEmailValid(false)
    }, [ workEmail ]) 

    useEffect(() => {
        employmentStatus !== '' && salaryRange !=='' && employerName !== '' && workEmailValid
            ?
            setFormValid(true)
            :
            setFormValid(false)
    },[ employmentStatus, salaryRange, employerName, workEmailValid ])

    const handleSubmit = () => {
        formValid
            ?
            setIsLoading(true)
            :
            setTriedToSubmit(true)
    }
    return(
        <div className="overlay">
            <div className="good-tenant-form">
                <img src={CLOSE_ICON} alt="" onClick={() => setGoodTenantFormActive(false)} className='close'/>
                <h4>Kindly provide us with the following information to aid our search</h4>
                <div className="good-tenant-form__form">
                    <div className="good-tenant-form__form__select">
                        <select className={employmentStatus ? '' : 'undefined'} value={employmentStatus} onChange={(e) => setEmploymentStatus(e.target.options[e.target.selectedIndex].value)} >
                            <option disabled value=''>Employment Status</option>
                            <option value='employed'>Employed</option>
                            <option value='self employed'>Self Employed</option>
                            <option value='unemployed'>Unemployed</option>
                        </select>
                        { !employmentStatus && triedToSubmit && <ErrorMessage message="Employment status is required"/>}
                    </div>
                    <div className="good-tenant-form__form__input">
                        <input
                            type='text'
                            placeholder='Name of employer'
                            value={employerName}
                            onChange={(e) => setEmployerName(e.target.value)}
                        />
                        { !employerName && triedToSubmit && <ErrorMessage message="Employmer's name is required"/>}
                    </div>
                    <div className="good-tenant-form__form__input">
                        <input
                            type='text'
                            placeholder='Work email address'
                            value={workEmail}
                            onChange={(e) => setWorkEmail(e.target.value)}
                        />
                        { triedToSubmit && !workEmailValid && <ErrorMessage message={ workEmail === '' ? 'Work email is required' : 'Enter valid work email' } /> }
                    </div>
                    <div className="good-tenant-form__form__select">
                        <select className={salaryRange ? '' : 'undefined'} value={salaryRange} onChange={(e) => setSalaryRange(e.target.options[e.target.selectedIndex].value)} >
                            <option disabled value=''>Salary Range</option>
                            <option>Below ₦150,000</option>
                            <option>₦151,000 - ₦250,000</option>
                            <option>₦251,000 - ₦350,000</option>
                            <option>₦351,000 - ₦450,000</option>
                            <option>Above ₦451,000</option>
                        </select>
                        { !salaryRange && triedToSubmit && <ErrorMessage message="Salary range is required"/>}
                    </div>
                </div>
                <button onClick={() => handleSubmit()}>
                    {
                        isLoading
                            ?
                            <div className="loading"></div>
                            :
                            'Submit'
                    }
                </button>
            </div>
        </div>
    )
}
export default GoodTenantForm;