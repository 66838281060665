import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardLayout from "../layout/dashboardLayout";
import ListingPagesLayout from "../layout/listingPagesLayout";
import Apartment from "../pages/dashboard/apartments";
import Booking from "../pages/dashboard/booking";
import Payment from "../pages/dashboard/payment";
import Selection from "../pages/dashboard/selection";
import VerificationInProgress from "../pages/dashboard/verificationInProgress";
import Verify from "../pages/dashboard/verify";
import Home from "../pages/home";
import ApartmentInfo from "../pages/list/apartmentInfo";
import BasicInfo from "../pages/list/basicInfo";
import Complete from "../pages/list/complete";
import Confirmation from "../pages/list/confirmation";
import Property from "../pages/property";
import SearchPage from "../pages/searchPage";
import NoResult from "../pages/noResults";
import Terms from "../pages/terms";
import PrivacyPolicy from "../pages/privacyPolicy";
import RequireAuth from "../components/requireAuth";
import BookingConfirmed from "../pages/dashboard/bookingConfirmed";
import { useEffect } from "react";
import ReactGa from 'react-ga'
import VerificationFailed from "../pages/dashboard/verificationFailed";
import NotFound from "../pages/notFound";
import MonthlyRentInfo from "../pages/monthlyRentInfo";
import Onboarding from "../pages/dashboard/onboarding";
import Landlord from "../pages/landlord";
import UpcomingApartments from "../pages/upcomingApartments";
import Event from "../pages/event";


const Router = ():JSX.Element => {
    ReactGa.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_CODE || '')
     
    useEffect(() => {
        ReactGa.pageview(window.location.pathname + window.location.search)
    },[])

    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/search' element={<SearchPage/>}/>
                <Route path='/homes/:slug' element={<Property/>}/>
                <Route path='/terms' element={<Terms/>}/>
                <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
                <Route path="/no-result" element={<NoResult/>}/>
                <Route path='/monthly-rent-information' element={<MonthlyRentInfo/>}/>
                <Route path='/landlord' element={<Landlord/>}/>
                <Route path='/upcoming-apartments' element={<UpcomingApartments/>}/>
                <Route path='/events' element={<Event/>}/>
                {/* <Route path='/about' element={<About/>}/> */}

                {/* Listing Routes */}
                <Route path='/list' element={<ListingPagesLayout/>}>
                    <Route path='/list' element={<BasicInfo/>}/>
                    <Route path='apartment-info' element={<ApartmentInfo/>}/>
                    <Route path='confirmation' element={<Confirmation/>}/>
                </Route>
                <Route path='/list/complete' element={<Complete/>}/>

                {/* Dashboard routes */}
                <Route element={<DashboardLayout/>}>
                    <Route element={<RequireAuth/>}>
                        <Route path='/apartment' element={<Apartment/>}/>
                        <Route path='/booking-confirmed/:booking_id' element={<BookingConfirmed/>}/>
                        <Route path='/booking/:slug' element={<Booking/>}/>
                        <Route path='/verify/:slug' element={<Verify/>}/>
                        <Route path='/verification-failed/:id' element={<VerificationFailed/>}/>
                        <Route path='/verification-in-progress/:id' element={<VerificationInProgress/>}/>
                        <Route path='/selection/:id' element={<Selection/>}/>
                        <Route path='/payment/:id' element={<Payment/>}/>
                        <Route path='/onboarding/:id' element={<Onboarding/>}/>
                    </Route>
                </Route>
                <Route path='/prefer' element={<NoResult/>}/>
                <Route path='*' element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    )
}
export default Router;