import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"
import { setUser } from "../store/slices/auth/authSlice"
import decryptData from "./decryptData"

const RequireAuth = () => {
    const dispatch = useDispatch();
    const user = localStorage.getItem('liveableUser') || undefined;

    useEffect(() => {
        if (user){
            const decryptedUser = decryptData(user, process.env.REACT_APP_DECRYPT_USER_SECRET_KEY)
            dispatch(setUser(decryptedUser))
        }
    },[ dispatch, user ])

    return(
        user
            ?
            <Outlet/>
            :
            <Navigate to='/'/> 
    )
}
export default RequireAuth;