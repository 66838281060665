import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    bookings: []
}
const bookingsSlice = createSlice({
    'name': 'bookings',
    initialState,
    reducers: {
        setBookings: (state, action:PayloadAction<[]>) => { state.bookings = action.payload }
    },
})
export const { setBookings } = bookingsSlice.actions
const bookingsReducer = bookingsSlice.reducer
export default bookingsReducer;