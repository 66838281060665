import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BOOKINGICON, PAYMENTICON, VERIFICATIONICON, CONFIRMATIONICON } from "../assets/icons";
import { GREEN_CHECK } from "../assets/image";

interface IProgressBar {
    status: string,
    has_booking: boolean,
}

const ProgressBar = (props:IProgressBar) => {
    const [ status, setStatus ] = useState(0);
    const [ has_booking, setHasBooking ] = useState<boolean>(props.has_booking);

    const location = useLocation();
    const currentRoute = location.pathname

    useEffect(() => {
        [
            'verification',
            'verification-form-pending', 
            'verification-form-in-progress', 
            'verification-form-ongoing', 
            'verification-failed', 
            'verification-restart', 
            'verification-form-completed', 
            'ongoing-verification',
            'verification-completed'
        ].includes(props.status) && setStatus(1)
        props.status === 'selected' && setStatus(2);
    },[ props.status, status ])

    useEffect(() => {
        props.has_booking === false && status === 0 && setStatus(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleBookingStatus = () => {
        setStatus(0);
        setHasBooking(true)
    }

    useEffect(() => {
        currentRoute.includes('/booking' || '/booking-confirmed') && handleBookingStatus();
    },[ currentRoute ])

    return(
        <div className="progress-bar">
            <div className={ has_booking ? "progress-bar__top" : "progress-bar__top--no-booking" }>
                {
                    has_booking
                        &&
                    <>
                        <div className={ status === 0 ? "active" : "completed" }>
                            <BOOKINGICON/>
                        </div>
                        <span className={ status > 0 ? "passed" : "" }></span>
                    </>
                }
                <div className={ 
                    status >= 1 
                        ? 
                        status > 1
                            ?
                            "completed"
                            :
                            "active"
                        : 
                        ""
                    }
                >
                    <VERIFICATIONICON/>
                </div>
                <span className={ status >= 2 ? "passed" : "" }></span>
                <div className={ 
                    status >= 2
                        ? 
                        status > 2
                            ?
                            "completed"
                            :
                            "active"
                        : 
                        ""
                    }
                >
                    <PAYMENTICON/>
                </div>
                <span className={ status >= 3 ? "passed" : "" }></span>
                <div className={ 
                    status >= 3
                        ? 
                        status > 3
                            ?
                            "completed"
                            :
                            "active"
                        : 
                        ""
                    }
                >
                    <CONFIRMATIONICON/>
                </div>
            </div>
            <div className={ has_booking ? "progress-bar__bottom": "no_booking"}>
                {
                    has_booking
                        &&
                    <span className="finished">
                        { status > 0 && <img src={GREEN_CHECK} alt=""/>}
                        Booking
                    </span>
                }
                <span className={status >= 1 ? "finished" : ""}>
                    { status > 1 && <img src={GREEN_CHECK} alt=""/> }
                    Verification
                </span>
                <span className={status >= 2 ? "finished" : ""}>
                    { status > 2 && <img src={GREEN_CHECK} alt=""/> }
                    Full payment
                </span>
                <span className={status >= 3 ? "finished" : ""}>
                    { status > 3 && <img src={GREEN_CHECK} alt=""/> }
                    Onborading
                </span>
            </div>
        </div>
    )
}
export default ProgressBar;