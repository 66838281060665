import { LISTING_SUCCESSFUL } from "../../assets/image"
import ListHome from "../../components/listHome"
import PageLayout from "../../layout/pageLayout"

const Complete = () => {
    return(
        <div className="listing-page-layout">
        <PageLayout>
            <ListHome 
                header="Are you moving out soon? Lets get your current apartment listed" 
                subHeader="We never ask for your exact address until you are ready to move out." 
                textWidth={792}
                displayAbout={false}
            />
            <div className="listing-page-layout__form">
                <div className="listing-complete">
                    <img src={LISTING_SUCCESSFUL} alt=""/>
                    <h3>Listing submitted successfully!</h3>
                    <p>We will reach out to communicate next steps.</p>
                </div>
            </div>
        </PageLayout>
    </div>
    )
}
export default Complete