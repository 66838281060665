import { ARROW_LEFT } from "../assets/image";
import { ReactNode } from "react";
import VerificationLayoutApartmentCard from "../components/cards/VerificationLayoutApartmentCard";
import { useNavigate } from "react-router-dom";
import {  useSelector } from "react-redux";
import { RootState } from "../store/store";
import { MyApartmentCardLoader } from "../components/cards/myApartmentCard";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ApartmentUnvailableCard from "../components/cards/apartmentUnavailableCard";
import RouteToCurrentStatus from "../components/routeToCurrentStatus";
import ProgressBar from "../components/progressBar";


interface IVerificationLayout {
    children: ReactNode,
    route?: string,
}
const VerificationLayout = ({children, route}:IVerificationLayout) => {

    const { bookingLoading, bookingStatus, bookingDate, isAvailableForBooking, activeBooking } = useSelector((state: RootState) => state.booking);

    const router = useNavigate();

    return(
        <RouteToCurrentStatus>
            <div className="verification-layout-wrapper">
                <div className="verification-layout">
                    <div className="verification-layout__top" onClick={() => router(-1)} style={{cursor: 'pointer'}}>
                        <img src={ARROW_LEFT} alt=""/>
                        <p><span>{route || "Apartments"}</span> / { bookingLoading ? <Skeleton width={200} height={12}/> : <>{" "}{activeBooking?.name + ", " + activeBooking?.location.sub_area + ", " + activeBooking?.location.state }</> }</p>
                    </div>
                    <div className="verification-layout__apartment-card">
                        {
                            bookingLoading
                                ?
                                <SkeletonTheme baseColor='#F5F5F5' highlightColor='#ffffff'>
                                    <MyApartmentCardLoader/>
                                </SkeletonTheme>
                                :
                                <VerificationLayoutApartmentCard
                                    key={activeBooking?.id}
                                    id={activeBooking?.id}
                                    slug={activeBooking?.slug}
                                    location={activeBooking?.name + ", " + activeBooking?.location.sub_area + ", " + activeBooking?.location.state } 
                                    pricing={activeBooking?.amount}
                                    img={activeBooking?.files ?  activeBooking?.files[0]?.thumbnail : activeBooking?.main_image?.thumbnail }
                                    rooms={[
                                        `${activeBooking?.location?.area}`,
                                        `${activeBooking?.bedroom} bedroom`,
                                        `${activeBooking?.bathroom} bathroom`
                                    ]} 
                                    availableAt={activeBooking?.availability}
                                    additionalFeatures={activeBooking?.ammenities}
                                />
                                
                        }
                    </div>
                    <SkeletonTheme baseColor='#F5F5F5' highlightColor='#ffffff'>
                        <div className="verification-layout__progress-bar">
                            {
                                bookingLoading
                                    ?
                                    <div style={{ display: 'flex', gap: '100px', justifyContent: 'center', padding: '20px 0'}}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
                                            <Skeleton borderRadius={50} width={37} height={37}/>
                                            <Skeleton width={70} height={10}/>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
                                            <Skeleton borderRadius={50} width={37} height={37}/>
                                            <Skeleton width={70} height={10}/>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
                                            <Skeleton borderRadius={50} width={37} height={37}/>
                                            <Skeleton width={70} height={10}/>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
                                            <Skeleton borderRadius={50} width={37} height={37}/>
                                            <Skeleton width={70} height={10}/>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
                                            <Skeleton borderRadius={50} width={37} height={37}/>
                                            <Skeleton width={70} height={10}/>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {isAvailableForBooking && <ProgressBar status={bookingStatus} has_booking={ (bookingDate.prevBookingDate === null || bookingDate.prevBookingDate === undefined || bookingDate.prevBookingDate === '1970-01-01') ? false : true }/>}
                                    </>
                            }
                        </div>
                    </SkeletonTheme>
                    {
                        !isAvailableForBooking && <ApartmentUnvailableCard property={activeBooking?.name + ", " + activeBooking?.location.sub_area + ", " + activeBooking?.location.state }/>
                    }
                    { !bookingLoading && isAvailableForBooking && children}
                </div>
            </div>
        </RouteToCurrentStatus>
    )
}
export default VerificationLayout;
