import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BLUE_ARROW_RIGHT, BOOKING_IMG, RESCHEDULE_ICON } from "../../assets/image";
import VerificationLayout from "../../layout/apartmentVerificationLayout";
import StartVerificationModal from "../../modal/startVerificationModal";
import { $api } from "../../services";
import { setActiveBooking, setBookingDate, setBookingLoading } from "../../store/slices/booking/bookingSlice";
import { AppDispatch } from "../../store/slices/properties/types";
import { RootState } from "../../store/store";


const BookingConfirmed = () => {
    const router = useNavigate();
    const { activeBooking } = useSelector((state:RootState) => state.booking);
    const [ rescheduleIsLoading, setRescheduleIsLoading ] = useState<boolean>(false);
    const [ startVerificationModalActive, setStartVerificationModalActive  ] = useState(false)
    const dispatch = useDispatch<AppDispatch>();

    const { booking_id } = useParams();

    const handleReschedule = async() => {
        setRescheduleIsLoading(true)
        try{
            const response = await $api.update(`v2/lv/booking/reschedule/${booking_id}`,{ booking_id: booking_id}
        )
        if($api.isSuccessful(response)){
            router(`/booking/${booking_id}`)
            setRescheduleIsLoading(false)
        }
    }
    catch(err){
        setRescheduleIsLoading(false)
        toast.error('Booking failed')
    }
    }

    const fetchBooking = async() => {
        dispatch(setBookingDate(null))
        const response = await $api.fetch(`v2/lv/booking/${booking_id}`)
        if($api.isSuccessful(response)){
            dispatch(setBookingDate(response?.data?.visit_date))
            dispatch(setActiveBooking(response?.data.unit))
            dispatch(setBookingLoading(false))
        }
        else{
            toast.error("Couldn't fetch booking")
        }
    }

    useEffect(() => {
        fetchBooking()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <VerificationLayout>
            { startVerificationModalActive && <StartVerificationModal setStartVerificationModalActive={setStartVerificationModalActive} slug={activeBooking?.slug} bookingId={booking_id}/>}
            <div className="booking-confirmed">
                <h3>Booking</h3>
                <img src={BOOKING_IMG} alt=""/>
                <h4>Booking confirmed</h4>
                <p>A calendar invite has been sent to you. Please ensure to show up on time.</p>
                <div className="booking-confirmed__button-wrapper">
                    <button className="booking-confirmed__button-wrapper--reschedule" onClick={() => handleReschedule()}>
                        {
                            rescheduleIsLoading
                                ?
                                <div className="reschedule-loading"></div>
                                :
                                <>
                                    RESCHEDULE
                                    <img src={RESCHEDULE_ICON} alt=""/>
                                </>
                        }
                    </button>
                    <button className="booking-confirmed__button-wrapper--verification" onClick={() => setStartVerificationModalActive(true)}>
                        START VERIFICATION
                        <img src={BLUE_ARROW_RIGHT} alt=""/>
                    </button>
                </div>
            </div>
        </VerificationLayout>
    )
}
export default BookingConfirmed;
