import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { IBasicInfo } from './types';


const initialState:IBasicInfo = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: undefined, 
    firstRequestMade: false,
    id: '',
}
const BasicInfoSlice = createSlice({
    name: 'basicInfo',
    initialState,
    reducers: {
        setFirstName: (state, action:PayloadAction<string>) => {
            state.firstName = action.payload;
        }, 
        setLastName: (state, action:PayloadAction<string>) => {
            state.lastName = action.payload;
        }, 
        setEmail: (state, action:PayloadAction<string>) => {
            state.email = action.payload;
        }, 
        setPhoneNumber: (state, action:PayloadAction<string | undefined>) => {
            state.phoneNumber = action.payload;
        },
        setFirstRequestMade: (state, action:PayloadAction<boolean>) => {
            state.firstRequestMade = action.payload
        },
        setApartmentId: (state, action:PayloadAction<string>) => {
            state.id = action.payload
        }
    }
})
export const { 
    setFirstName, 
    setLastName, 
    setEmail, 
    setPhoneNumber, 
    setFirstRequestMade,
    setApartmentId,
} = BasicInfoSlice.actions
export default BasicInfoSlice.reducer