export const BOOKINGICON = () => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.75 13.75H15V10H13.75V13.75H10V15H13.75V18.75H15V15H18.75V13.75Z" fill=""/>
            <path d="M17.5 3.75C17.5 3.0625 16.9375 2.5 16.25 2.5H13.75V1.25H12.5V2.5H7.5V1.25H6.25V2.5H3.75C3.0625 2.5 2.5 3.0625 2.5 3.75V16.25C2.5 16.9375 3.0625 17.5 3.75 17.5H8.75V16.25H3.75V3.75H6.25V5H7.5V3.75H12.5V5H13.75V3.75H16.25V8.75H17.5V3.75Z" fill=""/>
        </svg>
    )
}

export const VERIFICATIONICON = () => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 3.75V16.25H2.5V3.75H17.5ZM17.5 2.5H2.5C2.16848 2.5 1.85054 2.6317 1.61612 2.86612C1.3817 3.10054 1.25 3.41848 1.25 3.75V16.25C1.25 16.5815 1.3817 16.8995 1.61612 17.1339C1.85054 17.3683 2.16848 17.5 2.5 17.5H17.5C17.8315 17.5 18.1495 17.3683 18.3839 17.1339C18.6183 16.8995 18.75 16.5815 18.75 16.25V3.75C18.75 3.41848 18.6183 3.10054 18.3839 2.86612C18.1495 2.6317 17.8315 2.5 17.5 2.5V2.5Z" fill=""/>
            <path d="M3.75 6.25H8.125V7.5H3.75V6.25ZM3.75 8.75H6.25V10H3.75V8.75ZM14.375 11.25H10.625C10.1277 11.25 9.65081 11.4475 9.29917 11.7992C8.94754 12.1508 8.75 12.6277 8.75 13.125V14.375H10V13.125C10 12.9592 10.0658 12.8003 10.1831 12.6831C10.3003 12.5658 10.4592 12.5 10.625 12.5H14.375C14.5408 12.5 14.6997 12.5658 14.8169 12.6831C14.9342 12.8003 15 12.9592 15 13.125V14.375H16.25V13.125C16.25 12.6277 16.0525 12.1508 15.7008 11.7992C15.3492 11.4475 14.8723 11.25 14.375 11.25ZM12.5 10.625C12.9945 10.625 13.4778 10.4784 13.8889 10.2037C14.3 9.92897 14.6205 9.53853 14.8097 9.08171C14.9989 8.62489 15.0484 8.12223 14.952 7.63728C14.8555 7.15232 14.6174 6.70687 14.2678 6.35723C13.9181 6.0076 13.4727 5.7695 12.9877 5.67304C12.5028 5.57657 12.0001 5.62608 11.5433 5.8153C11.0865 6.00452 10.696 6.32495 10.4213 6.73608C10.1466 7.1472 10 7.63055 10 8.125C10 8.78804 10.2634 9.42393 10.7322 9.89277C11.2011 10.3616 11.837 10.625 12.5 10.625ZM12.5 6.875C12.7472 6.875 12.9889 6.94831 13.1945 7.08566C13.4 7.22302 13.5602 7.41824 13.6548 7.64665C13.7495 7.87505 13.7742 8.12639 13.726 8.36886C13.6778 8.61134 13.5587 8.83407 13.3839 9.00888C13.2091 9.1837 12.9863 9.30275 12.7439 9.35098C12.5014 9.39921 12.2501 9.37446 12.0216 9.27985C11.7932 9.18524 11.598 9.02503 11.4607 8.81946C11.3233 8.6139 11.25 8.37223 11.25 8.125C11.25 7.79348 11.3817 7.47554 11.6161 7.24112C11.8505 7.0067 12.1685 6.875 12.5 6.875V6.875Z" fill=""/>
        </svg>
    )
}

export const SELECTIONICON = () => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 18.75H2.5C2.16858 18.7497 1.85083 18.6179 1.61648 18.3835C1.38213 18.1492 1.25033 17.8314 1.25 17.5V13.75C1.25033 13.4186 1.38213 13.1008 1.61648 12.8665C1.85083 12.6321 2.16858 12.5003 2.5 12.5H17.5C17.8314 12.5003 18.1492 12.6321 18.3835 12.8665C18.6179 13.1008 18.7497 13.4186 18.75 13.75V17.5C18.7497 17.8314 18.6179 18.1492 18.3835 18.3835C18.1492 18.6179 17.8314 18.7497 17.5 18.75ZM2.5 13.75V17.5H17.5V13.75H2.5Z" fill=""/>
            <path d="M4.375 16.25C4.72018 16.25 5 15.9702 5 15.625C5 15.2798 4.72018 15 4.375 15C4.02982 15 3.75 15.2798 3.75 15.625C3.75 15.9702 4.02982 16.25 4.375 16.25Z" fill=""/>
            <path d="M11.875 7.24125L10.625 5.99125V3.75H9.375V6.50875L10.9913 8.125L11.875 7.24125Z" fill=""/>
            <path d="M10 11.25C9.0111 11.25 8.0444 10.9568 7.22215 10.4074C6.39991 9.85794 5.75904 9.07705 5.3806 8.16342C5.00217 7.24979 4.90315 6.24446 5.09608 5.27455C5.289 4.30465 5.76521 3.41373 6.46447 2.71447C7.16373 2.01521 8.05465 1.539 9.02455 1.34608C9.99446 1.15315 10.9998 1.25217 11.9134 1.6306C12.8271 2.00904 13.6079 2.64991 14.1574 3.47215C14.7068 4.2944 15 5.2611 15 6.25C14.9983 7.57558 14.471 8.84639 13.5337 9.78371C12.5964 10.721 11.3256 11.2483 10 11.25V11.25ZM10 2.5C9.25832 2.5 8.5333 2.71994 7.91661 3.13199C7.29993 3.54405 6.81928 4.12972 6.53545 4.81494C6.25163 5.50016 6.17736 6.25416 6.32206 6.98159C6.46675 7.70902 6.8239 8.37721 7.34835 8.90165C7.8728 9.4261 8.54098 9.78325 9.26841 9.92795C9.99584 10.0726 10.7498 9.99838 11.4351 9.71455C12.1203 9.43072 12.706 8.95008 13.118 8.33339C13.5301 7.71671 13.75 6.99168 13.75 6.25C13.7488 5.2558 13.3534 4.30264 12.6504 3.59963C11.9474 2.89662 10.9942 2.50116 10 2.5V2.5Z" fill=""/>
        </svg>
    )
}

export const PAYMENTICON = () => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.75 10.625H15V11.875H13.75V10.625Z" fill=""/>
            <path d="M17.5 5H2.5V3.125H16.25V1.875H2.5C2.16848 1.875 1.85054 2.0067 1.61612 2.24112C1.3817 2.47554 1.25 2.79348 1.25 3.125V16.25C1.25 16.5815 1.3817 16.8995 1.61612 17.1339C1.85054 17.3683 2.16848 17.5 2.5 17.5H17.5C17.8315 17.5 18.1495 17.3683 18.3839 17.1339C18.6183 16.8995 18.75 16.5815 18.75 16.25V6.25C18.75 5.91848 18.6183 5.60054 18.3839 5.36612C18.1495 5.1317 17.8315 5 17.5 5ZM2.5 16.25V6.25H17.5V8.125H12.5C12.1685 8.125 11.8505 8.2567 11.6161 8.49112C11.3817 8.72554 11.25 9.04348 11.25 9.375V13.125C11.25 13.4565 11.3817 13.7745 11.6161 14.0089C11.8505 14.2433 12.1685 14.375 12.5 14.375H17.5V16.25H2.5ZM17.5 9.375V13.125H12.5V9.375H17.5Z" fill=""/>
        </svg>
    )
}

export const DOWNLOADICON = () => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.25 15V17.5H3.75V15H2.5V17.5C2.5 17.8315 2.6317 18.1495 2.86612 18.3839C3.10054 18.6183 3.41848 18.75 3.75 18.75H16.25C16.5815 18.75 16.8995 18.6183 17.1339 18.3839C17.3683 18.1495 17.5 17.8315 17.5 17.5V15H16.25ZM16.25 8.75L15.3687 7.86875L10.625 12.6062V1.25H9.375V12.6062L4.63125 7.86875L3.75 8.75L10 15L16.25 8.75Z" fill=""/>
        </svg>
    )
}

export const WARNING_ICON = () => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1.25C5.125 1.25 1.25 5.125 1.25 10C1.25 14.875 5.125 18.75 10 18.75C14.875 18.75 18.75 14.875 18.75 10C18.75 5.125 14.875 1.25 10 1.25ZM10 17.5C5.875 17.5 2.5 14.125 2.5 10C2.5 5.875 5.875 2.5 10 2.5C14.125 2.5 17.5 5.875 17.5 10C17.5 14.125 14.125 17.5 10 17.5Z" fill="#D92D20"/>
            <path d="M9.375 5H10.625V11.25H9.375V5ZM10 12.75C9.5 12.75 9 13.125 9 13.75C9 14.375 9.375 14.75 10 14.75C10.5 14.75 11 14.375 11 13.75C11 13.125 10.5 12.75 10 12.75Z" fill="#D92D20"/>
        </svg>
    )
}

export const RED_ARROW_LEFT = () => {
    return(
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.16602 7.00008H12.8327M12.8327 7.00008L6.99935 1.16675M12.8327 7.00008L6.99935 12.8334" stroke="#B42318" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const CONFIRMATIONICON = () => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.75 13.3837L5.625 10.2581L6.50813 9.375L8.75 11.6163L13.4906 6.875L14.375 7.75937L8.75 13.3837Z" fill="#C8C7CF"/>
        <path d="M10 1.25C8.26942 1.25 6.57769 1.76318 5.13876 2.72464C3.69983 3.6861 2.57832 5.05267 1.91606 6.65152C1.25379 8.25037 1.08051 10.0097 1.41813 11.707C1.75575 13.4044 2.58911 14.9635 3.81282 16.1872C5.03653 17.4109 6.59563 18.2442 8.29296 18.5819C9.9903 18.9195 11.7496 18.7462 13.3485 18.0839C14.9473 17.4217 16.3139 16.3002 17.2754 14.8612C18.2368 13.4223 18.75 11.7306 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25ZM10 17.5C8.51664 17.5 7.0666 17.0601 5.83323 16.236C4.59986 15.4119 3.63856 14.2406 3.07091 12.8701C2.50325 11.4997 2.35473 9.99168 2.64411 8.53682C2.9335 7.08197 3.64781 5.74559 4.6967 4.6967C5.7456 3.64781 7.08197 2.9335 8.53683 2.64411C9.99168 2.35472 11.4997 2.50325 12.8701 3.0709C14.2406 3.63856 15.4119 4.59985 16.236 5.83322C17.0601 7.06659 17.5 8.51664 17.5 10C17.5 11.9891 16.7098 13.8968 15.3033 15.3033C13.8968 16.7098 11.9891 17.5 10 17.5Z" fill="#C8C7CF"/>
        </svg>
    )
}