import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {  BLUE_INFO_ICON, PAYMENT_IMG } from "../../assets/image";
import FullPaymentButton from "../../components/buttons/fullPaymentButton";
import CongratulationsCard from "../../components/cards/congratulationsCard";
import VerificationLayout from "../../layout/apartmentVerificationLayout";
import BankDepositModal from "../../modal/bankDepositModal";
import MonthlyDepositModal from "../../modal/monthlyPaymentModal";
import { fetchBooking } from "../../store/slices/booking/bookingSlice";
import { AppDispatch } from "../../store/slices/properties/types";
import { RootState } from "../../store/store";

const Payment = () => {
    const [ bankDepositModalActive, setBankDepositModalActive ] = useState<boolean>(false);
    const [ monthlyDepositModalActive, setMonthlyDepositModalActive ] = useState<boolean>(false);
    const [ selectedMethod, setSelectedMethod ] = useState<string>('paystack');
    const router = useNavigate();
    var balance

    let { id } = useParams();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (id) {
            dispatch(fetchBooking(id))
        }
    },[ dispatch, id ])

    const booking = useSelector((state: RootState) => state.booking )

    const amountArray = booking.activeBooking?.amount.split('/')
    const rentAdvancePaid = booking.rentAdvancePaid
    if (amountArray?.length){
        if(rentAdvancePaid === true){
            balance = 0.9* amountArray[0].match(/\d+/g)?.join('');
        }
        else{
            balance = amountArray[0].match(/\d+/g)?.join('');
        }
    }
    const handleMethodChange = (e: any) => {
        setSelectedMethod(e.target.value)
    }
    const property = booking?.activeBooking.name + ", " + booking?.activeBooking.location.area + ", " + booking?.activeBooking.location.state

    const balanceString = Number(balance).toLocaleString()
    return(
        <VerificationLayout>
            { bankDepositModalActive && <BankDepositModal setBankDepositModalActive={setBankDepositModalActive}/> }
            { monthlyDepositModalActive && <MonthlyDepositModal setMonthlyDepositModalActive={setMonthlyDepositModalActive}/> }
            <div className="payment">
                <CongratulationsCard
                    text="Proceed to secure your apartment by making payment in the next 48 hours as failure to do so may result in the forfeiture of the apartment"
                    title="Congratulations!, you have successfully passed the tenant verification stage and have been selected  for the apartment."
                />
                <img className="payment__img" src={PAYMENT_IMG} alt=""/>
                <div className="payment__top">
                    <h2>Make payment</h2>
                    <p>
                        Congratulations! You have been selected as a suitable tenant for the {property} apartment and can proceed to make payment and secure your apartment. Please note that you have to make payment within the next 48 hours.
                    </p>
                </div>
                {/* <h5>
                    *Note that if after two days payment is not made, the apartment will have to be given to someone else.
                </h5> */}
                <h6>Amount Due</h6>
                <h3>₦{balanceString}</h3>
                <div className="payment__options payment__options__full">
                    <h4>Full Payment Options</h4>
                    <div className="payment__options__yearly">
                        <div className="payment__options__option">
                            <input
                                type="radio"
                                id="bank-deposit"
                                name="payment-option"
                                value="bank"
                                checked={selectedMethod === 'bank'}
                                onChange={(e) => handleMethodChange(e)}
                            />
                            <label htmlFor="bank-deposit">Bank Deposit</label>
                        </div>
                        <div className="payment__options__option">
                            <input
                                type="radio"
                                id="paystack"
                                name="payment-option"
                                value="paystack"
                                checked={selectedMethod === 'paystack'}
                                onChange={(e) => handleMethodChange(e)}
                            />
                            <label htmlFor="paystack">Paystack</label>
                        </div>
                        {/* <FullPaymentButton className="payment__button-wrapper--payment" amount={balance}/> */}
                    </div>
                </div>
                <div className="payment__options">
                    <h4>Monthly Payment Options</h4>
                    <div className="payment__options__monthly">
                        <div className="payment__options__monthly__wrapper">
                            <div className="payment__options__option">
                                <input
                                    type="radio"
                                    id="stacs"
                                    name="payment-option"
                                    value="stacs"
                                    checked={selectedMethod === 'stacs'}
                                    onChange={(e) => handleMethodChange(e)}
                                />
                                <label htmlFor="stacs">Stacs</label>
                            </div>
                            <div className="payment__options__option">
                                <input
                                    type="radio"
                                    id="sterling"
                                    name="payment-option"
                                    value="sterling"
                                    checked={selectedMethod === 'sterling'}
                                    onChange={(e) => handleMethodChange(e)}
                                />
                                <label htmlFor="sterling">Sterling</label>
                            </div>
                            <div className="payment__options__option">
                                <input
                                    type="radio"
                                    id="zilla"
                                    value="zilla"
                                    name="payment-option"
                                    checked={selectedMethod === 'zilla'}
                                    onChange={(e) => handleMethodChange(e)}
                                />
                                <label htmlFor="zilla">Zilla</label>
                            </div>
                        </div>
                        <div className="payment__options__monthly__info" onClick={() => router('/monthly-rent-information')}>
                            <p><small>Find out more about our monthly rent</small></p>
                            <img src={BLUE_INFO_ICON} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="payment__button-wrapper">
                    <FullPaymentButton className="payment__button-wrapper--payment" setMonthlyDepositModalActive={setMonthlyDepositModalActive} bookingId={booking?.id} selected={selectedMethod} unitId={booking?.activeBooking?.id} setBankDepositModalActive={setBankDepositModalActive} amount={balance}/>
                </div>
            </div>
        </VerificationLayout>
    )
}
export default Payment;