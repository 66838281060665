import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CLOSE_ICON, LOGIN_SUCCESSFUL } from "../assets/image"
import AuthLayout from "../layout/authLayout"


const PaymentSuccess = (props: any) => {
    let { slug } = useParams();
    const [ unitId, setUnitId ] = useState<string>();
    const setPaymentMade = props.setPaymentMade;

    useEffect(() => {
        if (slug) {
            let bookingIdrray = slug.split("-");
            const slugLength = bookingIdrray.length
            const unitId = bookingIdrray[slugLength-1]
            setUnitId(unitId)
        }
    },[ slug ])
   return(
     <AuthLayout>
        <div className="payment-successful">
            <div className="payment-successful__top">
                <img src={CLOSE_ICON} alt="" onClick={() => setPaymentMade(false)}/>
            </div>
            <div className="payment-successful__main">
                <img src={LOGIN_SUCCESSFUL} alt=""/>
                <p>Your payment was successful.</p>
                <a className="continue-button" href={process.env.REACT_APP_TENANT_VERIFICATION_LINK + '?booking_id=' + unitId} target="_blank" rel='noreferrer'>
                    Continue to verifiction
                </a>
            </div>
        </div>
    </AuthLayout>
   )
}
export default PaymentSuccess