import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BOOK_VIEWING } from "../../assets/image";
import { setFrom, setSignUpActive } from "../../store/slices/modal/modalSlice";
import { RootState } from "../../store/store";

const BookViewingButton = (props: React.HTMLAttributes<HTMLButtonElement>) => {
    const user = localStorage.getItem('liveableUser');
    const dispatch = useDispatch();
    const router = useNavigate();   
    const property = useSelector((state: RootState) => state.property);
    return(
        <button className="book-viewing-button" {...props}
            onClick={() => { 
                user
                ?
                router(`/booking/${property.property.slug}`)
                :
                dispatch(setSignUpActive()); dispatch(setFrom(`/booking/${property.property.slug}`))
            }}
        >
            <img src={BOOK_VIEWING} alt=""/>
            BOOK A VIEWING
        </button>
    )
}
export default BookViewingButton;