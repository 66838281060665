import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import storeData from '../../../components/storeData';

interface AuthPayload {
    access_token: string;
    user: string;
    userId: string;
    email: string;
}
const initialState: AuthPayload = {
    access_token: '',
    user: '',
    userId: '',
    email: '',
}

const authSlice = createSlice({
    'name': 'auth',
    initialState,
    reducers: {
        setAccessToken: (state, action: PayloadAction<string>) => { state.access_token = action.payload; storeData(action.payload, 'liveableAccessToken', process.env.REACT_APP_DECRYPT_USER_ACCESS_TOKEN_SECRET_KEY ) },
        setUserEmail: (state, action:PayloadAction<string>) => { state.email = action.payload; storeData(action.payload, 'liveableUserEmail', process.env.REACT_APP_DECRYPT_USER_EMAIL_SECRET_KEY ) },
        setUser: (state, action:PayloadAction<string>) => { state.user = action.payload; storeData(action.payload, 'liveableUser', process.env.REACT_APP_DECRYPT_USER_SECRET_KEY ) },
        clearUser: ( state ) => { state.user = ''},
        setUserId: (state, action:PayloadAction<string>) => { state.userId = action.payload; storeData(action.payload, 'liveableUserId', process.env.REACT_APP_DECRYPT_USER_ID_SECRET_KEY ) },
    },
})

export const { setAccessToken, setUser, setUserId, setUserEmail, clearUser } = authSlice.actions

const authReducer = authSlice.reducer
export default authReducer;