import React, { useEffect, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { MAP_LOCATION } from '../assets/image';

const containerStyle = {
  width: '100%',
  height: '500px'
};


interface IMap {
    location: {
        lat: string | any,
        lng: string | any,
    }
}

const Map = (props: IMap) => {
    const [ zoom, setZoom ] = useState<number>(0);

    useEffect(() => {
        setInterval(() => {
          zoom === 0 && setZoom(19)
        }, 1000);
      },[])

    const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY || '',
    })
    const center = {
        lat: props?.location?.lat, lng:  props?.location?.lng,
    };

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onUnmount = React.useCallback(function callback(map : any) {
    setMap(null)
    }, [])

    return isLoaded 
    ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Marker key="marker_1"
                position={center}
                icon={MAP_LOCATION}
            />
            { /* Child components, such as markers, info windows, etc. */ }
            <></>
        </GoogleMap>
    ) : <></>
}
export default Map;