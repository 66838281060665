import { FC } from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    availableAt: string;
}

const AvailableButton:FC<ButtonProps> = ({ availableAt, ...props }) => {
    return(
        <button className="available-button" {...props}>
            { 
                availableAt 
                    ? 
                    availableAt === 'Available' 
                        ? 
                        'Available now' 
                        : 
                        availableAt
                    :
                    'Available now'
            }
        </button>
    )
}

export const RecentlyRentedButton = ({...props }) => {
    return(
        <button className="recently-rented" {...props}>
            Occupied
        </button>
    )
}
export const UnavailableButton = ({...props }) => {
    return(
        <button className="unavailable-button" {...props}>
            Not Available
        </button>
    )
}
export default AvailableButton;