import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContinueButton from "../../components/buttons/continueButton";
import ErrorMessage from "../../components/errorMessage";
import SummaryCard from "../../components/cards/summaryCard";
import { RootState } from "../../store/store";


const Confirmation = ():JSX.Element => {
    const router = useNavigate();

    const basicInfo = useSelector((state:RootState) => state.basicInfo );
    const apartment = useSelector((state:RootState) => state.apartment);

    const [ informationConfirmed, setInformationConfirmed ] = useState(false);
    const [ triedToSubmit, setTriedToSubmit ] = useState(false)

    const [ pageValid, setPageValid ] = useState(true)

    const handleSubmit = () => {
        pageValid ? router ('/list/complete') : setTriedToSubmit(true)
    }

    useEffect(()=> {
        informationConfirmed ? setPageValid(true) : setPageValid(false)
    },[informationConfirmed])

    return(   
        <div className="confirmation">             
            <div className="confirmation-wrapper">
                <SummaryCard
                    section="Basic Information"
                    header1="First Name"
                    header2="Last Name"
                    header3="Email"
                    header4="Phone Number"
                    cell1={basicInfo.firstName}
                    cell2={basicInfo.lastName}
                    cell3={basicInfo.email}
                    cell4={basicInfo.phoneNumber}
                />
                <SummaryCard
                    section="Identification"
                    header1="Apartment Location"
                    header2="Apartment Type"
                    header3="Number of Rooms"
                    header4="Rent"
                    header5="Landlord's contact"
                    header6="Move Out Date"
                    header7="Additional Information"
                    cell1={apartment.location.name}
                    cell2={apartment.type.name}
                    cell3={apartment.roomsNumber}
                    cell4={apartment.rent}
                    cell5={apartment.landlordContact}
                    cell6={apartment.availableAt}
                    cell7={apartment.additionalInfo}
                />
                <div className="confirmation-wrapper__confirm-info">
                    <input
                        type="checkbox"
                        id="confirm-info"
                        onChange={(e) => setInformationConfirmed(e.target.checked)}
                    />
                    <label htmlFor="confirm-info" >
                        I confirm that the information provided above is valid & up to date, 
                        and I consent to have them be used for all neccesary validations & verifications.
                    </label>
                </div>
                { triedToSubmit && !pageValid && <ErrorMessage message="confirm your information"/>}
            </div>
            <ContinueButton
                onClick={() => handleSubmit()}
            >
                continue
            </ContinueButton>
        </div>
    )
}
export default Confirmation;