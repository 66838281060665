import { RED_ARROW_LEFT, WARNING_ICON } from "../../assets/icons";

interface IApartmentUnvailableCard {
    property: string,
}

const ApartmentUnvailableCard = (props: IApartmentUnvailableCard) => {
    return(
        <div className="apartment-unavailable-card">
            <div className="apartment-unavailable-card__icon">
                <WARNING_ICON/>
            </div>
            <div className="apartment-unavailable-card__content">
                <h4>Oops! Seems someone else beat you to it.</h4>
                <p>This apartment is no longer available.</p>
                <div className="apartment-unavailable-card__content__options">
                    <a rel="noreferrer" target="_blank" href={"https://api.whatsapp.com/send/?phone=2347031175938&text=I have questions about this property - " + props.property }>
                        Contact us
                        <RED_ARROW_LEFT/>
                    </a>
                </div>
            </div>
        </div>
    )
}
export default ApartmentUnvailableCard;