import { useState } from "react";
import { COLLAPSE_ICON, EXPAND_ICON } from "../../assets/image";


interface ISummaryCard{
    section: string,
    header1: string,
    header2: string,
    header3?: string,
    header4?: string,
    header5?: string,
    header6?: string,
    header7?: string,
    cell1: string | undefined,
    cell2: string | undefined,
    cell3?: string | number,
    cell4?: string,
    cell5?: string,
    cell6?: string | Date,
    cell7?: string,
}

const SummaryCard = (props: ISummaryCard):JSX.Element => {
    const [ isOpen, setIsOpen ] = useState(false);
    return(
        <div className="summary-card">
            <div className="summary-card__header">
                <h4>{props.section}</h4>
                <img src={ isOpen ? COLLAPSE_ICON : EXPAND_ICON } onClick={() => setIsOpen(!isOpen)} alt=""/>
            </div>          
            <div className={ isOpen ? 'active' : 'inactive'}>
                <div className="summary-card__table">
                    <div>
                        <h3>{props.header1}</h3>
                        <h4>{props.cell1}</h4>
                    </div>
                    <div>
                        <h3>{props.header2}</h3>
                        <h4>{props.cell2}</h4>
                    </div>
                    { props.header3 &&
                        <div>
                            <h3>{props.header3}</h3>
                            <h4>{props.cell3}</h4>
                        </div>
                    }
                    { props.header4 &&
                        <div >
                            <h3>{props.header4}</h3>
                            <h4>{props.cell4}</h4>
                        </div>
                    }
                    { props.header5
                        && 
                        <div>
                            <h3>{props.header5}</h3>
                            <h4>{props.cell5}</h4>
                        </div>
                    }
                    { props.header6 
                        && 
                        <div>
                            <h3>{props.header6}</h3>
                            <h4>{props.cell6?.toString()}</h4>
                        </div>
                    }
                    { props.header7
                        && 
                        <div>
                            <h3>{props.header7}</h3>
                            <h4>{props.cell7}</h4>
                        </div>
                    }
                </div>
            </div>           
    </div>
    )
}
export default SummaryCard;