import AvailableButton from "../buttons/availabeButton";
import { useRef, useState } from "react";
import JoinWaitlistModal from "../../modal/joinWaitlistModal";
import JoinWaitlistSuccessful from "../../modal/joinWaitlistSuccessful";

interface IApartmentCard {
    id?: string,
    location: string,
    img: string,
    availableAt: string,
    unit_summary: string,
    name: string,
    numberOfUnits?: number,
}


const UpcomingApartmentCard = (props: IApartmentCard) => {
    const waitlistModalRef = useRef<HTMLDivElement>(null);
    const successModalRef = useRef<HTMLDivElement>(null);
    const [ waitlistModalActive, setWaitlistModalActive ] = useState<boolean>(false);
    const [ successModalActive, setSuccessModalActive ] = useState<boolean>(false);
    return(
        <div className="apartment-card">
            { waitlistModalActive && <JoinWaitlistModal setSuccessModalActive={setSuccessModalActive} setWaitlistModalActive={setWaitlistModalActive} reference={waitlistModalRef} propertyName={props.name} apartment={props?.name} location={props?.location}/> }
            { successModalActive && <JoinWaitlistSuccessful setSuccessModalActive={setSuccessModalActive} reference={successModalRef} /> }
            <AvailableButton availableAt={props.availableAt} style={{ position: 'absolute', marginTop: '11px', marginRight: '11px'}}/>
            <div className="apartment-card__img">
                <img src={props.img} alt=""/>
            </div>
            <div className="apartment-card__details">
                <h4>{props.location}</h4>
                <h6 className="apartment-card__details__summary top-space">{props.name}</h6>
                <h6 className="apartment-card__details__summary top-space">{props.unit_summary}</h6>
                <h6 className="apartment-card__details__summary top-space">{props?.numberOfUnits ? props?.numberOfUnits : 1} Unit(s)</h6>
            </div>
            <button className="join-button" onClick={() => setWaitlistModalActive(true)}>
                Join waitlist
            </button>
        </div>
    )
}
export default UpcomingApartmentCard;
