import { CLOSE_ICON } from "../assets/image";

interface IBankDepositModal{
    setBankDepositModalActive: React.Dispatch<React.SetStateAction<boolean>>
}

const BankDepositModal = (props: IBankDepositModal) => {
    const setBankDepositModalActive = props.setBankDepositModalActive

    return(
        <div className="overlay">
            <div className="bank-deposit">
                <div className="bank-deposit__top">
                    <img onClick={() => setBankDepositModalActive(false)} src={CLOSE_ICON} alt=""/>
                </div>
                <div className="bank-deposit__main">
                    <h4 className="text-center">Thanks for choosing to pay to our bank</h4>
                    <p>Kindly make payment by transferring your rent to the following account </p>
                    <div className="bank-deposit__main__account">
                        <p>Account name: <b>Liveable Limited</b></p>
                        <p>Account number: <b>1012311401</b></p>
                        <p>Bank: <b>Keystone Bank</b></p>
                    </div>
                    <p className="text-center">After payment is made, please send the proof of payment upon completion to <b>doris@liveable.ng</b></p>
                </div>
            </div>
        </div>
    )
}
export default BankDepositModal;