const upcomingLocations = [
    'Lekki',
    'Abuja',
    'Abeokuta',
    'Yaba' , 
    'VGC',
    'Iponri',
    'Orchid Rd',
    'South Point, Ikota',
    'Lekki Phase I',
    'Maryland',
    'Beachwood Estate',
    'Ikota',
    'Magodo Phase II',
    'Ikorodu, Agric',
    'Omole',
]

export default upcomingLocations