import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { DateObject } from "react-multi-date-picker";
import { $api } from "../../../services";
import { RootState } from "../../store";

interface IBooking {
    activeBooking: any,
    bookingLoading: boolean,
    bookingDate: IBookingDate,
    bookingStatus: string,
    rentAdvancePaid: any,
    isAvailableForBooking: boolean,
    id: string | undefined,
}

interface IBookingDate {
    prevBookingDate: DateObject | DateObject[] | null | any,
    prevBookingTime: DateObject | DateObject[] | null | any,
}

const initialState: IBooking = {
    activeBooking: {
        slug: '',
        amount: '',
        additional_info: '',
        availability: '',
        description: '',
        name: '',
        address: '',
        location: {
            area: '',
            sub_area: '',
            state: '',
        },
        ammenities: undefined,
        bedroom: '',
        unit_summary: '',
        files: undefined,
        id: undefined,
        available_dates: [], 
    },
    id: undefined,
    bookingStatus: '',
    bookingDate: {
        prevBookingDate: null,
        prevBookingTime: null,
    },
    bookingLoading: true,
    rentAdvancePaid: false,
    isAvailableForBooking: true,
}

type AppThunk = ThunkAction<void, RootState, unknown, Action>;

const bookingSlice = createSlice({
    'name': 'booking',
    initialState,
    reducers: {
        setActiveBooking: (state, action:PayloadAction<any>) => { state.activeBooking = action.payload },
        setBookingLoading: (state, action:PayloadAction<boolean>) => { state.bookingLoading = action.payload },
        setBookingId: (state, action:PayloadAction<string | undefined>) => { state.id = action.payload },
        setBookingDate: (state, action:PayloadAction<DateObject | DateObject[] | null>) => { state.bookingDate.prevBookingDate = action.payload },
        setBookingTime: (state, action:PayloadAction<DateObject | DateObject[] | null>) => { state.bookingDate.prevBookingTime = action.payload },
        setBookingStatus: (state, action:PayloadAction<string>) => { state.bookingStatus = action.payload},
        setRentAdvance: (state, action: PayloadAction<any>) => { state.rentAdvancePaid = action.payload },
        setAvailableForBooking: (state, action:PayloadAction<boolean>) => { state.isAvailableForBooking = action.payload },
    },
})
export const { setActiveBooking, setBookingLoading, setBookingId, setBookingDate, setBookingTime, setBookingStatus, setRentAdvance, setAvailableForBooking } = bookingSlice.actions;
const bookingReducer = bookingSlice.reducer
export default bookingReducer;

export const fetchUnitBooking = (id: string | null):AppThunk => 
    async(dispatch) => {
        dispatch(setBookingStatus(''))
        dispatch(setBookingId(undefined))
        dispatch(setAvailableForBooking(true))
        dispatch(setRentAdvance(false))
        dispatch(setBookingDate(null))
        dispatch(setBookingTime(null))
        dispatch(setBookingLoading(true))
        let endpoint = `v2/lv/unit/${id}`
        const response:any = await $api.fetch(endpoint)
        dispatch(setActiveBooking(response?.data))
        dispatch(setBookingLoading(false))
    }

export const fetchBooking = (id: string | null):AppThunk => 
    async(dispatch) => {
        dispatch(setBookingStatus(''))
        dispatch(setBookingDate(null))
        dispatch(setRentAdvance(false))
        dispatch(setBookingLoading(true))
        dispatch(setAvailableForBooking(true))
        dispatch(setBookingId(undefined))
        let endpoint = `v2/lv/booking/${id}`
        const response:any = await $api.fetch(endpoint)
        dispatch(setBookingId(response?.data.id))
        dispatch(setActiveBooking(response?.data?.unit))
        dispatch(setRentAdvance(response?.data?.rent_advance))
        dispatch(setBookingLoading(false))
        dispatch(setBookingDate(response?.data?.visit_date))
        dispatch(setBookingTime(response?.data?.visit_time))
        dispatch(setBookingStatus(response?.data?.status))
        dispatch(setAvailableForBooking(response?.data?.is_available_for_booking))
    }