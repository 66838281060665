import { INVALID_FIELD_ICON } from "../assets/image";

interface IErrorMessage {
    message: string,
}

const ErrorMessage = (props:IErrorMessage) => {
    return(
        <div className="error-message">
            <img src={INVALID_FIELD_ICON} alt=""/>
            <p>{props.message}</p>
        </div>
    )
}
export default ErrorMessage