import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import MyApartmentCard, { MyApartmentCardLoader } from "../../components/cards/myApartmentCard";
import { $api } from "../../services";
import { setBookings } from "../../store/slices/bookings/bookingsSlice";
import { RootState } from "../../store/store";

const Apartment = () => {
    const { bookings } = useSelector((state: RootState) => state.bookings)
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const dispatch = useDispatch();
    const fetchBookings = async() => {
        setIsLoading(true)
        try{
            const response = await $api.fetch('v2/lv/booking')
            dispatch(setBookings(response?.data))
            setIsLoading(false)
        }
        catch(err){
            toast.error("Couldn't fetch apartments")
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <div className="apartment">
            <h2>My Apartments</h2>
            <p>You’re one step closer to your dream home. Secure it before someone else beats you to it.</p>
            <div className="apartment__card-wrapper">
                <>
                    {
                        isLoading
                            ?
                            <MyApartmentCardLoader/>
                            :
                            bookings?.length > 0
                                ?
                                bookings.map((booking: any) => {
                                    return (
                                            <MyApartmentCard
                                                slug={booking.unit.slug}
                                                key={booking.id}
                                                id={booking.id}
                                                bookingDate={booking.visit_date}
                                                availableAt={booking.unit.availability}
                                                status={booking.status}
                                                location={booking.unit.name + ", " + booking.unit.location.area + ", " + booking.unit.location.state}
                                                rooms={[booking.unit.location.area , `${booking.unit.bedroom} bedroom`, `${booking.unit.bathroom} bathroom`]}
                                                pricing={booking.unit.amount}
                                                img={booking.unit.main_image?.thumbnail}
                                                additionalFeatures={booking.unit.ammenities}
                                                isAvailableForBooking={booking.is_available_for_booking}
                                            />
                                    )
                                })
                                :
                                <h3>No bookings available.</h3>
                    }
                </>
            </div>
        </div>
    )
}
export default Apartment;