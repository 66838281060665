import { ReactNode } from 'react';

interface IAuthLayout {
    children: ReactNode,
}
const AuthLayout = ({children}:IAuthLayout) => {
    return(
        <div className="auth-layout">
            <div className="auth-layout__wrapper" >
                {children}
            </div>
        </div>
    )
}
export default (AuthLayout);