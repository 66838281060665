import { GREEN_CHECKMARK } from "../../assets/image";

interface ICongratulationsCard {
    title: string,
    text: string,
}
const CongratulationsCard = (props:ICongratulationsCard) => {
    return(
        <div className="congratulations-card">
            <img src={GREEN_CHECKMARK} alt=""/>
            <div className="congratulations-card__text">
                <h4>{props.title}</h4>
                <h5>{props.text}</h5>
            </div>
        </div>
    )
}
export default CongratulationsCard;