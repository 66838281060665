interface IHowWeWork {
    img: string,
    title: string,
    text: string,
}

const HowWeWork = ( props:IHowWeWork ) => {
    return(
        <div className="how-we-work">
            <img src={props.img} alt=""/>
            <h4>{props.title}</h4>
            <p>{props.text}</p>
        </div>
    )
}
export default HowWeWork;