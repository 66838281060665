import { CLOSE_ICON } from "../assets/image";
import AuthLayout from "../layout/authLayout";

const WaitlistSuccessful = ({setWaitlistSuccessfulModalActive} : {setWaitlistSuccessfulModalActive: React.Dispatch<React.SetStateAction<boolean>>}) => {
    return(
        <AuthLayout>
            <div className="waitlist-successful">
                <div className="waitlist-successful__top">
                    <img onClick={() => setWaitlistSuccessfulModalActive(false)} src={CLOSE_ICON} alt=""/>
                </div>
                <div className="waitlist-successful__main">
                    <h4>You have successfully joined the waitlist for this property, we will be in touch once it is available</h4>
                </div>
            </div>
        </AuthLayout>
    )
}
export default WaitlistSuccessful;