import { Fragment } from "react";
import upcomingApartments from "../assets/data/upcomingApartments";
import UpcomingApartmentCard from "../components/cards/upcomingApartmentCard";
import PageLayout from "../layout/pageLayout";

const UpcomingApartments = () => {

    const renderUpcomingApartments = () => {
        return(
            upcomingApartments.map((apartment, index) => {
                return(
                    <UpcomingApartmentCard key={index} {...apartment}/>
                )
            })
        )
    }

    return(
        <PageLayout>
            <div className="search">
                <div className="search__cards">
                    <div className="search__top"></div>
                    <div className="search__apartment-card-wrapper">
                        <>
                            {
                                renderUpcomingApartments()
                            }
                        </>
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}
export default UpcomingApartments;