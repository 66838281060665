const upcomingApartments = [
    {
        id: '0',
        location: 'Folagoro, Shomolu',
        numberOfUnits: 10,
        img: '/shomolu.jpeg',
        availableAt: 'Available October',
        unit_summary: 'Experience the Pro-Max version of our serviced Folagoro Studio Community with a personal kitchen and bathroom. Enjoy seamless living solutions while residing among ambitious professionals like yourself.',
        name: 'Folagoro Studio Community',
    },
    {
        id: '1',
        location: 'Lekki Phase I ',
        numberOfUnits: 2,
        img: 'https://drive.google.com/uc?export=view&id=1rTiEepxu8Hh4NxorJ3jeFmRFH8qPp4Mx',
        availableAt: 'Available October',
        unit_summary: 'We know you deserve a community of ambitious professionals such as yourself.  The real question is how ready are you for it?',
        name: 'Studio Community Lekki',
    },
    {
        id: '2',
        location: 'Lekki Phase I ',
        numberOfUnits: 2,
        img: 'https://drive.google.com/uc?export=view&id=1pc1jEpFL-ylon5IiN_zNrGPdZQrsv-IL',
        availableAt: 'Available October',
        unit_summary: 'This spacious three-bedroom apartment in Lekki is a befitting home for a family or group of friends/colleagues.',
        name: '3 bedroom',
    },
    {
        id: '3',
        location: 'Lekki Phase I',
        numberOfUnits: 2,
        img: 'https://drive.google.com/uc?export=view&id=1r7laEjDGJy0gHACmnx8k7D-5qvyFZtYi',
        availableAt: 'Available October',
        unit_summary: 'In this Studio Apartment, you get a home, a serene community, and the comfort you deserve as you are close to nature and in a secure environment.',
        name: 'Boys Quarter',
    },
    {
        id: '4',
        location: 'Beachwood Estate, Behind Lawke Golf',
        numberOfUnits: 1,
        img: 'https://drive.google.com/uc?export=view&id=1KxMjyH8_x89udoljPLSDCNBuKdcU9MkI',
        availableAt: 'Available September',
        unit_summary: 'Power outages are the least of your worries in this cozy 3 bedroom space as you are guaranteed between 18-24 hours of power daily.',
        name: '3 bedroom bungalow',
    },
    {
        id: '5',
        location: 'Abuja',
        numberOfUnits: 1,
        img: 'https://res.cloudinary.com/liveable/image/upload/q_auto:low/v1660627074/lafw6ntllclpclqo1wpl.jpg',
        availableAt: 'Available November',
        unit_summary: 'Taste comfort and community in our Abuja Studio Community. Center to major work districts, staying close to work & entertainment just became a reality.',
        name: 'Studio Community',
    },
    {
        id: '6',
        location: 'Abeokuta',
        numberOfUnits: 1,
        img: 'https://res.cloudinary.com/liveable/image/upload/q_auto:low/v1657295472/tafwwzfvdkvupelehkxy.jpg',
        availableAt: 'Coming soon',
        unit_summary: 'Planning to move out of Lagos, or reside in an apartment with all the amenities needed to improve your productivity, this Studio Community has your name on it.',
        name: 'Studio Community',
    },
    {
        id: '7',
        location: 'Yaba, Akoka',
        numberOfUnits: 12,
        img: 'https://res.cloudinary.com/liveable/image/upload/q_auto:low/v1662840932/dqkdpze4r6rcwhv31pgi.jpg',
        availableAt: 'Coming soon',
        unit_summary: 'Lovers of soft life, get in here. You are guaranteed 18 - 24 hours of power daily, maximum security, & a host of other amenities. Not forgetting the close proximity to the island and center of entertainment in Yaba.',
        name: 'Mini flats',
    },
    {
        id: '8',
        location: 'Iponri',
        numberOfUnits: 1,
        img: 'https://res.cloudinary.com/liveable/image/upload/v1676971202/ct6qsjxw2uiamgagbarx.jpg',
        availableAt: 'Available October',
        unit_summary: 'Aside from the natural lights and wealth of nature that greets you every morning, the living room in this 3 bedroom apartment is spacious to host you and yours to a fun-filled evening any day.',
        name: '3 bedroom flat',
    },
    {
        id: '9',
        location: 'Orchid Rd',
        numberOfUnits: 1,
        img: 'https://res.cloudinary.com/liveable/image/upload/v1680244117/qv4ujmw5qeckrfhdrkji.jpg',
        availableAt: 'Available September',
        unit_summary: 'With all rooms ensuite and spacious, this terrace is best enjoyed in groups of friends, family, or individually. ',
        name: '4 Bedroom Terrace',
    },
    {
        id: '10',
        location: 'South Point, Ikota',
        numberOfUnits: 1,
        img: 'https://res.cloudinary.com/liveable/image/upload/v1680244144/djr0ofnuw8mgmllhnbuc.jpg',
        availableAt: 'Available September',
        unit_summary: 'This two bedroom apartment has two rooms and a hundred and one opportunities for you to create a home and memories in it.',
        name: 'Fully Furnished 2 Bedroom bungalow',
    },
    {
        id: '11',
        location: 'Maryland',
        numberOfUnits: 5,
        img: 'https://res.cloudinary.com/liveable/image/upload/v1680246185/zujpxyfk2df0vil5wuio.jpg',
        availableAt: 'Available October',
        unit_summary: 'This Studio Apartment in Maryland affords you the perfect blend of homeliness and communal living.',
        name: 'Studio Apartments',
    },
    {
        id: '12',
        location: 'Ikota',
        numberOfUnits: 7,
        img: 'https://res.cloudinary.com/liveable/image/upload/v1652791690/ihv78zbpyotz7rv4k5kz.jpg',
        availableAt: 'Available November',
        unit_summary: 'Shout “spacious” three times and this apartment will appear. It affords ample space for a family to create new  and lifelong memories. ',
        name: '3 bedroom',
    },
    {
        id: '13',
        location: 'Lekki Phase I',
        numberOfUnits: 1,
        img: 'https://res.cloudinary.com/liveable/image/upload/v1653862592/ltlp6sjzrsjqci1er1xk.jpg',
        availableAt: 'Available November',
        unit_summary: "Are you a family that wants to reside in an apartment spacious enough to fit each family member's needs? This home has your surname on it.",
        name: '5 bedroom duplex',
    },
    {
        id: '14',
        location: 'Magodo Phase II',
        numberOfUnits: 6,
        img: 'https://res.cloudinary.com/liveable/image/upload/v1653863039/vnlcfdrdk4kw2hyqeslx.jpg',
        availableAt: 'Coming soon',
        unit_summary: 'Say bye bye to electricity problems as you reside in an area with a priority line and with a good road network. ',
        name: 'Studio Apartment',
    },
    {
        id: '15',
        location: 'Magodo Phase II',
        numberOfUnits: 1,
        img: 'https://res.cloudinary.com/liveable/image/upload/v1653875662/cmff9mfsl34qiguevnic.jpg',
        availableAt: 'Coming soon',
        unit_summary: 'From the spacious living room to a fully equipped kitchen, there are plenty of areas to relax, chat, and do your best work.',
        name: 'Mini flat',
    },
    {
        id: '16',
        location: 'Ikorodu, Agric',
        numberOfUnits: 5,
        img: 'https://res.cloudinary.com/liveable/image/upload/v1653874738/ounenkcbogzz3c9pgcxy.webp',
        availableAt: 'Available November',
        unit_summary: 'This two-bedroom apartment in Agric offers a delightful living experience with a range of desirable features and amenities.',
        name: '2 Bedroom',
    },
    {
        id: '17',
        location: 'Ikorodu, Agric',
        numberOfUnits: 1,
        img: 'https://res.cloudinary.com/liveable/image/upload/q_auto:low/v1655331217/w1jlf5cj73il1ukbycqs.jpg',
        availableAt: 'Available November',
        unit_summary: 'This Studio Community is ideal for young professionals who work in Ikorodu and its environments. Amenities, a community, and peace of mind awaits you. ',
        name: 'Studio Apartment',
    },
    {
        id: '18',
        location: 'Omole ',
        numberOfUnits: 17,
        img: 'https://res.cloudinary.com/liveable/image/upload/q_auto:low/v1655418396/qdpjcbhjalekuvnqb3o6.jpg',
        availableAt: 'Available November',
        unit_summary: 'This is where innovation meets productivity. Focus on what really matters in a home while being surrounded by a community of like minded individuals.',
        name: 'Studio Apartment',
    },
    {
        id: '19',
        location: 'Omole ',
        numberOfUnits: 2,
        img: 'https://res.cloudinary.com/liveable/image/upload/q_auto:low/v1659520712/qulhq3va5ojkv6payezp.jpg',
        availableAt: 'Available November',
        unit_summary: 'The apartment is ideal to accommodate your inner Picasso; not forgetting the wardrobes which offer plenty of storage for a clutter-free environment.',
        name: 'Mini flat',
    },
    {
        id: '20',
        location: 'Dolphin Estate Ikoyi',
        numberOfUnits: 1,
        img: 'https://res.cloudinary.com/liveable/image/upload/q_auto:low/v1659649433/awyl36o6iybl1moavlvj.jpg',
        availableAt: 'Available November',
        unit_summary: 'The residents can rest easy in the gated and secured estate which offers peace of mind and a safe haven. This apartment is best enjoyed in groups of friends, family, or individually.',
        name: '4 Bedroom Duplex',
    },
    {
        id: '21',
        location: 'Dolphin Estate Ikoyi',
        numberOfUnits: 1,
        img: 'https://res.cloudinary.com/liveable/image/upload/q_auto:low/v1659653421/mnwcrurjwis49ljgy3kf.jpg',
        availableAt: 'Available November',
        unit_summary: 'An ideal alternative for those who work on the island and are looking to avoid the daily traffic. Enjoy the best of living on the island while saving your body from any traffic-related stress.',
        name: 'Miniflat (BQ)',
    },
    {
        id: '22',
        location: 'Alpha Beach Road, Lekki',
        numberOfUnits: 3,
        img: 'https://res.cloudinary.com/liveable/image/upload/q_auto:low/v1661893441/dvswp3ixn6niwqkbsn7c.jpg',
        availableAt: 'Available November',
        unit_summary: 'You deserve to live in a serene and tranquil environment and this apartment affords you that. Not forgetting the proximity to work, gyms, and entertainment hubs. ',
        name: '2 bedroom flat',
    },
    {
        id: '23',
        location: 'Alpha Beach Road, Lekki',
        numberOfUnits: 1,
        img: 'https://res.cloudinary.com/liveable/image/upload/q_auto:low/v1662096096/gsghbxyoggknanrnucg5.jpg',
        availableAt: 'Available November',
        unit_summary: 'This fully serviced mini flat is ideal for lovers of soft life. It comes with a spacious living room, ensuite room, and kitchen.',
        name: 'Miniflat',
    },
    {
        id: '24',
        location: 'Founders Community, Omole',
        numberOfUnits: 19,
        img: '/omole-img.jpeg',
        availableAt: 'Available November',
        unit_summary: 'Ideas need an enabling environment to thrive, and this community provides founders with a dedicated space, including co-working spaces and other essential facilities, to transform their ideas into reality',
        name: 'Studio Apartment',
    },
]

export default upcomingApartments;