import { Dispatch, RefObject, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { CLOSE_ICON, LOGIN_BTN_ICON, LOGIN_SUCCESSFUL } from "../assets/image"
import AuthLayout from "../layout/authLayout"


const JoinWaitlistSuccessful = ({setSuccessModalActive, reference }: { setSuccessModalActive: Dispatch<SetStateAction<boolean>>, reference: RefObject<HTMLDivElement> }) => {
    const router = useNavigate();
   return(
     <AuthLayout>
        <div className="payment-successful" ref={reference}>
            <div className="payment-successful__top">
                <img src={CLOSE_ICON} alt="" onClick={() => setSuccessModalActive(false)}/>
            </div>
            <div className="payment-successful__main">
                <img src={LOGIN_SUCCESSFUL} alt=""/>
                <p>You have successfully joined the waitlist for this property, we will be in touch once it is  available</p>
                <button className="signup__main__form__signup" style={{ cursor: 'pointer'}} onClick={() => { setSuccessModalActive(false); router('/')}}>
                    <img src={LOGIN_BTN_ICON} style={{ transform: 'rotate(180deg)' }} alt=""/>
                    Back to home
                </button>
            </div>
        </div>
    </AuthLayout>
   )
}
export default JoinWaitlistSuccessful;