import { useEffect, useState } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {  CLOSE_ICON, LOGIN_BTN_ICON } from "../../assets/image"
import ErrorMessage from "../../components/errorMessage";
import AuthLayout from "../../layout/authLayout"
import { $api } from "../../services";
import { setAccessToken, setUser, setUserEmail, setUserId } from "../../store/slices/auth/authSlice";
import ReactGa from 'react-ga';
import { events } from "../../components/analyticsEvents";
import { useParams } from "react-router-dom";

const EMAIL_REGEX = /^(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}$/;
const JoinWaitlist = ({setJoinWaitlistModalActive, setWaitlistSuccessfulModalActive} : { setJoinWaitlistModalActive: React.Dispatch<React.SetStateAction<boolean>>,setWaitlistSuccessfulModalActive: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const dispatch = useDispatch();
    const [ email, setEmail ] = useState<string>('');
    const [ firstName, setFirstName ] = useState<string>('');
    const [ lastName, setLastName ] = useState<string>('');
    const [ phoneNumber, setPhoneNumber ] = useState<string>();
    const [ phoneNumberValid, setPhoneNumberValid ] = useState<boolean>(false);
    const [ emailValid, setEmailValid ] = useState<boolean>(false)
    const [ pageValid, setPageValid ] = useState<boolean>(false)
    const [ triedToSubmit, setTriedToSubmit ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    let unit_id: string
    let { slug } = useParams();

    if (slug) {
        let slugArray = slug.split("-");
        const slugLength = slugArray.length
        unit_id = slugArray[slugLength-1]
    }

    useEffect(() => {
        EMAIL_REGEX.test(email) ? setEmailValid(true) : setEmailValid(false)
        phoneNumber
            ?
            phoneNumber.length >= 14 ? setPhoneNumberValid(true) : setPhoneNumberValid(false)
            :
            setPhoneNumberValid(false)
    },[ email, phoneNumber ])

    useEffect(() => {
        emailValid && firstName && lastName && phoneNumberValid ? setPageValid(true) : setPageValid(false)
    },[ emailValid, firstName, lastName, phoneNumberValid ])

    const handleValidSubmit = async() => {
        setIsLoading(true)
        const response = await $api.post('v2/lv/join-waitlist', { email, first_name: firstName, last_name: lastName, phone: phoneNumber, unit_id })
        if ($api.isSuccessful(response)){
            setJoinWaitlistModalActive(false)
            setWaitlistSuccessfulModalActive(true)
            dispatch(setAccessToken(response?.data?.token?.access_token))
            dispatch(setUserId(response?.data?.user?.id))
            dispatch(setUserEmail(email))
            const Capitalize = (text: string) => {
                    text.charAt(0).toUpperCase();
                return text;
            }
            const capitalizedFirstName = Capitalize(firstName)
            const capitalizedLastName = Capitalize(lastName)
            dispatch(setUser(capitalizedFirstName +" "+ capitalizedLastName))
            ReactGa.event(events.userSignup)
            setEmail('')
            setFirstName('')
            setLastName('')
            setPhoneNumber('')
        }
        else{
            toast.error('Sign up failed')
        }
        setIsLoading(false)
    }
    const handleSubmit = () => {
        pageValid
            ? 
            handleValidSubmit()
            :
            setTriedToSubmit(true)
    }


    return(
        <AuthLayout>
            <div className="signup" style={{ width: '850px', maxWidth: '100%', paddingBottom: '30px', height: 'auto', overflowY: 'hidden' }}>
                <div className="signup__top">
                    <div className="signup__top__close-icon" style={{ cursor: 'pointer', alignSelf: 'flex-end'}} onClick={() => { setJoinWaitlistModalActive(false) }}>
                        <img src={CLOSE_ICON} alt=""/>
                    </div>
                </div>
                <div className="signup__main">
                    <div className="signup__main__form" style={{ width: '100%',marginLeft: 0 }}>
                        <div className="signup__main__form__main" style={{ width: '100%', flexDirection: 'row', justifyContent: 'center', maxWidth: '90%', marginTop: '20px' }}>
                            <div className="signup__main__form__main__email">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                { triedToSubmit && !firstName && <ErrorMessage message="Enter your first name"/>}
                            </div>
                            <div className="signup__main__form__main__email">
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                                { triedToSubmit && !lastName && <ErrorMessage message="Enter your last name"/>}
                            </div>
                            <div className="signup__main__form__main__email">
                                <input
                                    type="email"
                                    placeholder="Email Address"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                                { triedToSubmit && !emailValid && <ErrorMessage message={ email === '' ? "Enter your email" : "Enter a valid email"}/>}
                            </div>
                            <div className="signup__main__form__main__email">
                                <PhoneInputWithCountrySelect
                                    value={phoneNumber}
                                    onChange={(value) => setPhoneNumber(value)}
                                    defaultCountry="NG"
                                    placeholder="08175435854"
                                />
                                { triedToSubmit &&  !phoneNumberValid && <ErrorMessage message={ phoneNumber === '' ? "Enter your phone number" : "Enter a valid phone number"}/>}
                            </div>
                        </div>
                        <button className="signup__main__form__signup" style={{ cursor: 'pointer', marginBottom: 0, marginTop: '30px' }} onClick={() => handleSubmit()}>
                            {
                                isLoading
                                    ? 
                                    <div className="loading"></div>
                                    :
                                    <>
                                        <img src={LOGIN_BTN_ICON} alt=""/>
                                        Join Waitlist
                                    </>

                            }
                        </button>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}
export default JoinWaitlist;