import { useState } from "react"
import { PaystackButton } from "react-paystack"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { $api } from "../../services"
import { setBookingStatus } from "../../store/slices/booking/bookingSlice"
import decryptData from "../decryptData"

const RentAdvanceButton = (props) => {
    const unit_id = props.unit_id
    const setPaymentMade = props.setPaymentMade
    const router = useNavigate();
    const email = decryptData(localStorage.getItem('liveableUserEmail'), process.env.REACT_APP_DECRYPT_USER_EMAIL_SECRET_KEY )
    const dispatch = useDispatch();
    const [ isLoading, setIsLoading ] = useState(false)

    const handlePayment = async(reference) => {
        setIsLoading(true)
        const response = await $api.post(`v2/lv/booking/rent-advance`, {
            reference: reference.reference, unit_id: unit_id
        })
        if($api.isSuccessful(response)){
            setPaymentMade(true)
            router(`/verify/${response?.data?.id}`)
            setIsLoading(false)
            dispatch(setBookingStatus(response?.data?.status))
        }
        else{
            setIsLoading(false)
            toast.error('Payment not completed')
        }
    }

    return(
        <PaystackButton
            amount={props.rentAdvance}
            email={email}
            publicKey={process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || ''}
            onSuccess={(reference) => handlePayment(reference)} 
            onClose={() => toast.info('payment not completed')}
            className="pay-button"
            text={ isLoading ? "Please wait...." : "Pay rent advance" }
        />
    )
}
export default RentAdvanceButton;