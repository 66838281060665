import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom"
import { LOGO, MENU_CLOSE, MENU_ICON, WHITE_LOGIN_ICON } from "../assets/image"
import { setLogoutActive } from "../store/slices/modal/modalSlice";
import { RootState } from "../store/store";

const DashboardNav = () => {
    const [ menuActive, setMenuActive ] = useState(false);
    const router = useNavigate();
    const { user } = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch();
    const location = useLocation()
    const currentRoute = location.pathname
    return(
        <nav className="navbar">
            <div className="navbar__wrapper">
                <div className="navbar__logo">
                    <img src={LOGO} style={{ cursor: 'pointer'}} onClick={() => router('/')} alt=""/>
                </div>
                <div className="navbar__links">
                    <ul>
                        <li><a href="/#upcoming-apartments">Upcoming Apartments</a></li>
                        { !(currentRoute === '/' || currentRoute === '/search') 
                            && 
                            <li><Link to="/">Find Apartment</Link></li>
                        }
                        { !(currentRoute.includes('/landlord')) && <li onClick={() => router('/landlord')}>Landlord</li>}
                        { !(currentRoute.includes('/list')) && <li onClick={() => router('/list')} style={{ cursor: 'pointer'}}>Move and Earn</li>}
                        { !(currentRoute.includes('/events')) && <li><Link to="/events">Events</Link></li> }
                    </ul>
                    {/* <button className="navbar__links__list" onClick={() => router('/list')}>
                        Move and Earn
                        <img src={LIST_HOME_ICON} alt=""/>
                    </button> */}
                    <button className="navbar__links__login" style={{ backgroundColor: '#083AEB', color: '#ffffff', cursor: 'pointer'}} onClick={() => dispatch(setLogoutActive(true))}>
                        <p>{user}</p>
                        <img src={WHITE_LOGIN_ICON} alt=""/>
                    </button>
                </div>
                <div className="navbar__sm">
                    {
                        menuActive
                            ?
                        <div className="navbar__sm__links">
                            <img className="navbar__sm__links__img" src={MENU_CLOSE} alt="" onClick={() => setMenuActive(false)}/>
                            <ul>
                                <li><a href="/#upcoming-apartments" onClick={() => setMenuActive(false)}>Upcoming Apartments</a></li>
                                { !(currentRoute === '/' || currentRoute === '/search') 
                                    && 
                                    <li><Link to="/">Find Apartment</Link></li>
                                }
                                { !(currentRoute.includes('/landlord')) && <li onClick={() => router('/landlord')}>Landlord</li>}
                                { !(currentRoute.includes('/list')) && <li onClick={() => router('/list')}>Move and Earn</li>}
                                { !(currentRoute.includes('/events')) && <li><Link to="/events">Events</Link></li> }
                            </ul>
                            {/* <button className="navbar__sm__links__list" onClick={() => { router('/list/basic-info'); setMenuActive(true)}}>
                                Move and Earn
                                <img src={LIST_HOME_ICON} alt=""/>
                            </button> */}
                            <button className="navbar__links__login" style={{ backgroundColor: '#083AEB', color: '#ffffff', marginTop: '2.3125rem', cursor: 'pointer'}} onClick={() => { setMenuActive(false); dispatch(setLogoutActive(true))}}>
                                <p>{user}</p>
                                <img src={WHITE_LOGIN_ICON} alt=""/>
                            </button>
                        </div>
                        :
                        <div className="navbar__sm__menu" onClick={() => setMenuActive(true)}>
                            <img src={MENU_ICON} alt=""/>
                        </div>
                    }
                </div>
            </div>
        </nav>
    )
}
export default DashboardNav