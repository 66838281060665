import { ReactNode, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardNav from "../components/dashboardNav";
import decryptData from "../components/decryptData";
import Footer from "../components/footer";
import Nav from "../components/nav";
import ConfirmEmail from "../modal/auth/confirmEmail";
import Login from "../modal/auth/login";
import LoginSuccess from "../modal/auth/loginSuccess";
import Logout from "../modal/auth/logout";
import SetPassword from "../modal/auth/setPassword";
import SignUp from "../modal/auth/signUp";
import { setUser } from "../store/slices/auth/authSlice";
import { setLogoutActive } from "../store/slices/modal/modalSlice";
import { RootState } from "../store/store";

interface IChildren {
    children: ReactNode,
}
const PageLayout = ({children}: IChildren):JSX.Element => {
    const dispatch = useDispatch();
    const encryptedUser = localStorage.getItem('liveableUser') || undefined;

    useEffect(() => {
        if (encryptedUser){
            const decryptedUser = decryptData(encryptedUser, process.env.REACT_APP_DECRYPT_USER_SECRET_KEY)
            dispatch(setUser(decryptedUser))
        }
    },[ dispatch, encryptedUser ])

    const { user } = useSelector((state: RootState) => state.auth)
    const modal = useSelector((state:RootState) => state.modal);
    const { logoutActive } = useSelector((state:RootState) => state.modal)
    const logoutRef = useRef<any>(null);


    const handleClick = (e:any) => {
        if (logoutActive && logoutRef.current && !logoutRef.current.contains(e.target)) {
            dispatch(setLogoutActive((false)))
        }
    }
    

    return(
        <div className="page-layout" onClick={(e) => handleClick(e)}>
            {modal.confirmEmail && <ConfirmEmail/>}
            {modal.login && <Login/>}
            {modal.loginSuccess && <LoginSuccess text={modal.successMessage}/>}
            {modal.setPassword && <SetPassword/>}
            {modal.signUp && <SignUp/>}
            { logoutActive && <Logout reference={logoutRef}/>}
            {
                user
                    ?
                    <DashboardNav/>
                    :
                    <Nav/>
            }
            {children}
            <Footer/>
        </div>
    )
}
export default PageLayout;