import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface IPictureSlice {
    pictureModalActive: boolean,
    activePicture: number,
    picture: IPicture[],
}
interface IPicture {
    high: string,
    thumbnail: string,
}
const initialState:IPictureSlice = {
    pictureModalActive: false,
    picture: [],
    activePicture: 0,
}

const pictureSlice = createSlice({
    name: 'picture',
    initialState,
    reducers: {
        setPictureModalActive : (state, action:PayloadAction<boolean>) => { state.pictureModalActive = action.payload },
        nextPicture: ( state ) => { state.activePicture = state.activePicture+ 1 },
        prevPicture: ( state ) => { state.activePicture = state.activePicture - 1 },
        setActivePicture: ( state, action:PayloadAction<number> ) => { state.activePicture = action.payload },
        setPicture: (state, action:PayloadAction<IPicture[]>) => { state.picture = action.payload }
    }
})
export const { setPictureModalActive, nextPicture, prevPicture, setPicture, setActivePicture } = pictureSlice.actions
export default pictureSlice.reducer