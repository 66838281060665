import React from "react";
import { WHITE_RIGHT_ARROW } from "../../assets/image"

const ContinueProcess = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    return(
        <button className="continue-process" {...props} style={{cursor: 'pointer'}}>
            CONTINUE PROCESS
            <img src={WHITE_RIGHT_ARROW} alt=""/>
        </button>
    )
}
export default ContinueProcess;