import {Provider} from "react-redux";
import Router from "../router/router";
import store from "../store/store";
import '../styles/global.scss';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import {FloatingWhatsApp} from "react-floating-whatsapp";
import {FAVICON} from "../assets/image";
import ErrorBoundary from "../components/error-boundary/errorBoundary";

const App = (): JSX.Element => {
    return (
        <div className="app">
            <ErrorBoundary>
                <Provider store={store}>
                    <ToastContainer/>
                    <Router/>
                </Provider>
                <FloatingWhatsApp
                    phoneNumber="2348175435860"
                    accountName="LiveableNG"
                    avatar={FAVICON}
                    allowEsc
                    allowClickAway
                    notification
                    notificationSound
                />
            </ErrorBoundary>
        </div>
    )
}
export default App;
