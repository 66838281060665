import { Dispatch, RefObject, SetStateAction, useEffect, useState } from "react";
import { $api } from "../services";
import { toast } from "react-toastify";
import ErrorMessage from "../components/errorMessage";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { CLOSE_ICON, LOGIN_BTN_ICON } from "../assets/image";

const EMAIL_REGEX = /^(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}$/;
const JoinWaitlistModal = ({propertyName, setSuccessModalActive, setWaitlistModalActive, reference, location, apartment}: { 
    propertyName: string, 
    setSuccessModalActive: Dispatch<SetStateAction<boolean>>, 
    setWaitlistModalActive: Dispatch<SetStateAction<boolean>>,
    reference: RefObject<HTMLDivElement>,
    location: string,
    apartment: string,
    }) => {
    const [ email, setEmail ] = useState<string>('');
    const [ firstName, setFirstName ] = useState<string>('');
    const [ lastName, setLastName ] = useState<string>('');
    const [ phoneNumber, setPhoneNumber ] = useState<string>();
    const [ phoneNumberValid, setPhoneNumberValid ] = useState<boolean>(false);
    const [ emailValid, setEmailValid ] = useState<boolean>(false)
    const [ pageValid, setPageValid ] = useState<boolean>(false)
    const [ triedToSubmit, setTriedToSubmit ] = useState<boolean>(false);
    const [ company, setCompany ] = useState<string>('');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    useEffect(() => {
        EMAIL_REGEX.test(email) ? setEmailValid(true) : setEmailValid(false)
        phoneNumber
            ?
            phoneNumber.length >= 14 ? setPhoneNumberValid(true) : setPhoneNumberValid(false)
            :
            setPhoneNumberValid(false)
    },[ email, phoneNumber ])

    useEffect(() => {
        emailValid && firstName && lastName && company && phoneNumberValid ? setPageValid(true) : setPageValid(false)
    },[ emailValid, firstName, lastName, phoneNumberValid, company ])

    const handleValidSubmit = async() => {
        setIsLoading(true)
        const response = await $api.post('v2/lv/upcoming', { email, first_name: firstName, last_name: lastName, phone: phoneNumber, company, interest: propertyName })
        if ($api.isSuccessful(response)){
            clearForm()
            setWaitlistModalActive(false)
            setSuccessModalActive(true)
        }
        else{
            toast.error('Failed to join apartment waitlist')
        }
        setIsLoading(false)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        pageValid
            ? 
            handleValidSubmit()
            :
            setTriedToSubmit(true)
    }

    const clearForm = () => {
        setEmail('')
        setFirstName('')
        setLastName('')
        setPhoneNumber('')
        setCompany('')
    }

    return(
        <div className="overlay">
            <div ref={reference} className="waitlist-form-wrapper">
                <div className="close" >
                    <img src={CLOSE_ICON} alt="" onClick={() => setWaitlistModalActive(false)}/>
                </div>
                <form onSubmit={(e) => handleSubmit(e)} className="waitlist-form" id="form" >
                    <h2>Join waitlist</h2>
                    <h4>Fill the form below to join the waitlist for {apartment}, {location}</h4>
                    <div className="signup__main__form__main">
                        <div className="signup__main__form__main__email">
                            <input
                                type="text"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            { triedToSubmit && !firstName && <ErrorMessage message="Enter your first name"/>}
                        </div>
                        <div className="signup__main__form__main__email">
                            <input
                                type="text"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            { triedToSubmit && !lastName && <ErrorMessage message="Enter your last name"/>}
                        </div>
                        <div className="signup__main__form__main__email">
                            <input
                                type="email"
                                placeholder="Email Address"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                            { triedToSubmit && !emailValid && <ErrorMessage message={ email === '' ? "Enter your email" : "Enter a valid email"}/>}
                        </div>
                        <div className="signup__main__form__main__email">
                            <PhoneInputWithCountrySelect
                                value={phoneNumber}
                                onChange={(value) => setPhoneNumber(value)}
                                defaultCountry="NG"
                                placeholder="08175435854"
                            />
                            { triedToSubmit &&  !phoneNumberValid && <ErrorMessage message={ phoneNumber === '' ? "Enter your phone number" : "Enter a valid phone number"}/>}
                        </div>
                        <div className="signup__main__form__main__email">
                            <input
                                type="text"
                                placeholder="Company"
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                            />
                            { triedToSubmit && !company && <ErrorMessage message="Enter your company"/>}
                        </div>
                    </div>
                    <button className="signup__main__form__signup" style={{ cursor: 'pointer'}}>
                        {
                            isLoading
                                ? 
                                <div className="loading"></div>
                                :
                                <>
                                    <img src={LOGIN_BTN_ICON} alt=""/>
                                    Join Waitlist
                                </>

                        }
                    </button>
                </form>
            </div>
        </div>
    )
}
export default JoinWaitlistModal;