import { CLOSE_ICON } from "../../assets/image"
import AuthLayout from "../../layout/authLayout";

interface IRentBreakdown {
    setRentBreakdownActive: React.Dispatch<React.SetStateAction<boolean>>
    serviceCharge: number,
    legalFee: number,
    agencyFee: number,
    cautionDeposit: number,
    rent: string,
}
const RentBreakdown = (props: IRentBreakdown) => {
    const setRentBreakdownActive = props.setRentBreakdownActive

    return(
        <AuthLayout>
            <div className="rent-breakdown">
                <div className="rent-breakdown__top">
                    <h4>Rent Breakdown</h4>
                    <img src={CLOSE_ICON} alt="" onClick={() => setRentBreakdownActive(false)}/>
                </div>
                <div className="rent-breakdown__main">
                    <div className="rent-breakdown__main__section-1">
                        <div className="rent-breakdown__main__section-1__rent">
                            <p>Rent</p>
                            <div><h3>{props.rent}</h3></div>
                        </div>
                        <div className="rent-breakdown__main__section-1__service">
                            <p>Service Charge</p>
                            <h3>{props.serviceCharge}/Month</h3>
                        </div>
                    </div>
                    <div className="rent-breakdown__main__section-2">
                        <div className="rent-breakdown__main__section-1__service">
                            <p>Agency fees</p>
                            <h3>{props.agencyFee}</h3>
                        </div>
                        <div className="rent-breakdown__main__service">
                            <p>Legal fee</p>
                            <h3>{props.legalFee}</h3>
                        </div>
                        {/* <div className="rent-breakdown__main__power">
                            <p>Power</p>
                            <h3>Pay-as-you-go</h3>
                        </div> */}
                    </div>
                    <div className="rent-breakdown__main__section-3">
                        <div className="rent-breakdown__main__service">
                            <p>Refundable caution deposit</p>
                            <h3>{props.cautionDeposit}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}
export default RentBreakdown;