import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet } from "react-router-dom"
import DashboardNav from "../components/dashboardNav"
import Footer from "../components/footer"
import Logout from "../modal/auth/logout"
import { setLogoutActive } from "../store/slices/modal/modalSlice"
import { RootState } from "../store/store"
// import QuestionSection from "../components/question-section"


const DashboardLayout = () => {
    const { logoutActive } = useSelector((state:RootState) => state.modal)
    const logoutRef = useRef<any>(null);
    const dispatch = useDispatch();

    const handleClick = (e:any) => {
        if (logoutActive && logoutRef.current && !logoutRef.current.contains(e.target)) {
            dispatch(setLogoutActive((false)))
        }
    }

    return(
        <div className="dashboard-layout" onClick={(e) => handleClick(e)}>
            <DashboardNav/>
            { logoutActive && <Logout reference={logoutRef}/>}
            <Outlet/>
            {/* <QuestionSection background='#ffffff'/> */}
            <Footer/>
        </div>
    )
}
export default DashboardLayout;