import React from "react";
import { BLUE_DOT, EARN_COMMISSION, FIND_HOME, LIST_HOME } from "../assets/image";
import HowWeWork from "./cards/howWeWorkCard";


interface IListHome {
    header: string,
    subHeader: string,
    textWidth: number,
    displayAbout: boolean,
}
const ListHome = (props:IListHome) => {
    return(
        <div className="list-home">
            <div className="list-home__parent">
                <h2>{props.header}</h2>
                <p style={{ maxWidth: `${props.textWidth}px`}}>
                    {props.subHeader}
                </p>
                <div className="list-home__parent__card-wrapper">
                    <HowWeWork 
                        img={LIST_HOME} 
                        title="List your current apartment" 
                        text="Provide information about the apartment including your landlord’s details and we will take it from there."
                    />
                    <HowWeWork
                        img={EARN_COMMISSION}
                        title="Earn Commission"
                        text="Get paid as soon as soon as your listed apartment gets rented"
                    />
                    <HowWeWork
                        img={FIND_HOME}
                        title="Find a Home"
                        text="Reduce your search time for a new apartment by browsing our array of liveable apartments. Book a viewing, rent a home, & move in. "
                    />
                </div>
                {
                    props.displayAbout && 
                    <div className="list-home__parent__about">
                        <h3>No extortion</h3>
                        <img src={BLUE_DOT} alt=""/>
                        <h3>Easy renting</h3>
                        <img src={BLUE_DOT} alt=""/>
                        <h3>Earn Income</h3>
                    </div>
                }
            </div>
        </div>
    )
}
export default ListHome;