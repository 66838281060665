import axios, { AxiosInstance } from "axios";
import decryptData from "../components/decryptData";

export const baseURL = process.env.REACT_APP_API_URL;

// const Api = axios.create({
//     baseURL: process.env.REACT_APP_API_URL
// })
// export default Api;

const axiosConfig = {
	withCredentials: false,
	headers: {
		Accept: 'application/json'
	}
}

const axiosClient:AxiosInstance = axios.create(axiosConfig);

class ServiceApi {
	public url = baseURL;

	appendToURL(url: string) {
		return `${this.url}${url}`;
	}

	setupHeaders(){
		const accessToken = localStorage.getItem('liveableAccessToken') || ''
		const decryptedAccessToken = decryptData(accessToken, process.env.REACT_APP_DECRYPT_USER_ACCESS_TOKEN_SECRET_KEY)
		return {
			headers: {
			  "Content-Type": "application/json",
			  Authorization: `Bearer ${decryptedAccessToken}`,
			},
		};
	}

	async fetch (url: string) {
		try{
			const response = await axiosClient.get(
				this.appendToURL(url),
				this.setupHeaders(),
			)
			return response
		}
		catch(err:any){
			return err
		}
	}

	async fetchWithCancelToken (url: string, cancelToken:any) {
		try{
			const response = await axiosClient.get(
				this.appendToURL(url),
				{
					cancelToken: cancelToken,
				}
			)
			return response
		}
		catch(err:any){
		}
	}

	async post (url: string, data: any) {
		try{
			const response = await axiosClient.post(
				this.appendToURL(url), data, this.setupHeaders()
			)
			return response
		}
		catch(err:any){
			return err
		}
	}

	async update (url: string, data: any) {
		try{
			const response = await axiosClient.put(
				this.appendToURL(url), data, this.setupHeaders()
			)
			return response
		}
		catch(err:any){
			return err
		}
	}
	
	isSuccessful(response: any): boolean {
		const codes = [200, 201, 202, 204];
		return codes.includes(
		  response?.status || response?.statusCode || response?.code
		)
		  ? true
		  : false;
	}
}
// eslint-disable-next-line 
export default new ServiceApi();