import {IconPickerItem} from "react-fa-icon-picker";
import Skeleton from "react-loading-skeleton";
import {useNavigate} from "react-router-dom";
import {DOT} from "../../assets/image";
import AvailableButton, {UnavailableButton} from "../buttons/availabeButton";
import ContinueProcess from "../buttons/continueProcess";
import ApartmentUnvailableCard from "./apartmentUnavailableCard";
import ProgressBar from "../progressBar";


interface IMyApartmentCard {
    location: string,
    pricing: string,
    additionalFeatures?: IMyApartmentCardFeatures[],
    rooms?: string[],
    img: string,
    status: string,
    id: string,
    slug: string,
    availableAt: string,
    isAvailableForBooking: boolean,
    bookingDate: string,
}

interface IMyApartmentCardFeatures {
    feature: string,
    icon: string
}

export const MyApartmentCardLoader = () => {
    return (
        <div className="my-apartment-card">
            <div className="my-apartment-card__top">
                <span className="my-apartment-card__top__img-container">
                    <Skeleton width={419} height={240} style={{maxWidth: '100%'}}/>
                </span>
                <div className="my-apartment-card__top__details">
                    <h4><Skeleton height={15} style={{maxWidth: '95%'}}/></h4>
                    <div className="my-apartment-card__top__details__rooms">
                        <Skeleton height={15} width={200}/>
                    </div>
                    <h4 className="my-apartment-card__top__details__pricing"><Skeleton width={200}/></h4>
                    <div className="my-apartment-card__top__details__features">
                        <Skeleton width={121} height={20} style={{borderRadius: '17px'}}/>
                        <Skeleton width={121} height={20} style={{borderRadius: '17px'}}/>
                        <Skeleton width={121} height={20} style={{borderRadius: '17px'}}/>
                        <Skeleton width={121} height={20} style={{borderRadius: '17px'}}/>
                        <Skeleton width={121} height={20} style={{borderRadius: '17px'}}/>
                    </div>
                </div>
            </div>
            <div className="my-apartment-card__bottom">
                <Skeleton width={'100%'} height={72}/>
            </div>
        </div>
    )
}
const MyApartmentCard = (props: IMyApartmentCard) => {
    const apartmentUnavailable = !props.isAvailableForBooking
    const router = useNavigate();
    return (
        <div className="my-apartment-card">
            <div className="my-apartment-card__top">
                <span className="my-apartment-card__top__img-container">
                    {
                        apartmentUnavailable
                            ?
                            <UnavailableButton style={{position: 'absolute', marginTop: '11px', marginRight: '11px'}}/>
                            :
                            <AvailableButton availableAt={props.availableAt}
                                             style={{position: 'absolute', marginTop: '11px', marginRight: '11px'}}/>
                    }
                    <img src={props.img} alt=""/>
                </span>
                <div className="my-apartment-card__top__details">
                    <h4>{props.location}</h4>
                    <div className="my-apartment-card__top__details__rooms">
                        {
                            props.rooms
                                ?
                                props.rooms.map((room, index) => {
                                    return (
                                        <div key={index} className="room">
                                            <h6>{room}</h6>
                                            <img src={DOT} alt=""/>
                                        </div>
                                    )
                                })
                                :
                                ''
                        }
                    </div>
                    <h4 className="my-apartment-card__top__details__pricing">{props.pricing}</h4>
                    <div className="my-apartment-card__top__details__features">
                        {
                            props.additionalFeatures
                                ?
                                props.additionalFeatures.map((feature: any, index) => {
                                    return (
                                        <ApartmentFeature key={index} feature={feature.name} icon={feature.icon_class}/>
                                    )
                                })
                                :
                                ''
                        }
                    </div>
                </div>
            </div>

            { apartmentUnavailable !== true &&
                <div className="my-apartment-card__bottom">
                    <ProgressBar status={props.status} has_booking={  (props.bookingDate !== undefined && props.bookingDate !== '1970-01-01') ? true : false}/>
                    <ContinueProcess
                        disabled={apartmentUnavailable}
                        onClick={() => {
                            props.status === 'booking' && router(`/booking/${props.id}`)
                            props.status === 'booking-confirmed' && router(`/booking-confirmed/${props.id}`);
                            ( props.status === 'verification-form-pending' || props.status === 'verification-form-in-progress' || props.status === 'verification' ) && router(`/verify/${props.id}`);
                            ( props.status === 'verification-form-completed' || props.status === 'ongoing-verification') && router(`/verification-in-progress/${props.id}`);
                            ( props.status === 'verification-failed' || props.status === 'verification-restart' ) && router(`/verification-failed/${props.id}`);
                            props.status === 'verification-completed' && router(`/selection/${props.id}`)
                            props.status === 'selected' && router(`/payment/${props.id}`)
                        }}
                    />
                </div>
            }
            {apartmentUnavailable && <ApartmentUnvailableCard property={props.location}/>}
        </div>
    )
}
export default MyApartmentCard;

const ApartmentFeature = (props: any): JSX.Element => {
    const feature = props.feature
    const icon = props.icon
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <IconPickerItem icon={icon} size={16} color="#616E9E" onClick={() => {
            }}/>
            <h5>{feature}</h5>
        </div>
    )
}