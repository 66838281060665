import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface IModalSlice  {
    confirmEmail: boolean,
    login: boolean,
    loginSuccess: boolean,
    successMessage: string,
    setPassword: boolean,
    signUp: boolean,
    from: string,
    logoutActive: boolean,
}
const initialState:IModalSlice = {
    confirmEmail: false,
    login: false,
    loginSuccess: false,
    setPassword: false,
    signUp: false,
    successMessage: '',
    from: '',
    logoutActive: false,
}
const ModalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setConfirmEmailActive : ( state ) => { 
            state.confirmEmail = true ; 
            state.login = false; 
            state.loginSuccess = false;
            state.setPassword = false;
            state.signUp = false
        },
        setLoginActive : ( state ) => { 
            state.confirmEmail = false; 
            state.login = true; 
            state.loginSuccess = false;
            state.setPassword = false;
            state.signUp = false
        },
        setLoginSuccessActive : ( state ) => { 
            state.confirmEmail = false; 
            state.login = false; 
            state.loginSuccess = true;
            state.setPassword = false;
            state.signUp = false
        },
        setPasswordActive : ( state ) => { 
            state.confirmEmail = false; 
            state.login = false; 
            state.loginSuccess = false;
            state.setPassword = true;
            state.signUp = false
        },
        setSignUpActive : ( state ) => { 
            state.confirmEmail = false; 
            state.login = false; 
            state.loginSuccess = false;
            state.setPassword = false;
            state.signUp = true;
        },
        setModalInactive : ( state ) => {
            state.confirmEmail = false; 
            state.login = false; 
            state.loginSuccess = false;
            state.setPassword = false;
            state.signUp = false;
        },
        setSuccessMessage: ( state, action:PayloadAction<string>) => {
            state.successMessage = action.payload
        },
        setFrom: ( state, action:PayloadAction<string>) => {
            state.from = action.payload
        },
        setLogoutActive: (state, action:PayloadAction<boolean>) => {
            state.logoutActive = action.payload
        }
    }
})
export const { setLoginActive, setConfirmEmailActive, setLoginSuccessActive, setPasswordActive, setSignUpActive, setSuccessMessage, setModalInactive, setFrom, setLogoutActive } = ModalSlice.actions
export default ModalSlice.reducer