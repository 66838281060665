import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRequest {
    pending: boolean,
    failed: boolean,
    failedMessage: string,
}

const initialState:IRequest = {
    pending: false,
    failed: false,
    failedMessage: "Check your internet connection"
}
const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {
        setRequestPending: (state, action:PayloadAction<boolean>) => { state.pending = action.payload },
        setRequestFailed: (state, action:PayloadAction<boolean>) => { state.failed = action.payload},
        setFailedMessage: (state, action:PayloadAction<string>) => { state.failedMessage = action.payload }
    }
})
export const { setRequestPending, setRequestFailed, setFailedMessage } = requestSlice.actions
export default requestSlice.reducer;