import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthLayout from "../layout/authLayout"
import { $api } from "../services";

const StartVerificationModal = (props: any) => {
    const setStartVerificationModalActive = props.setStartVerificationModalActive;
    const booking_id = props.bookingId
    const router = useNavigate();
    const [ isLoading, setIsLoading ] = useState<boolean>(false) 

    const handleSubmit = async() => {
        setIsLoading(true)
        const response = await $api.update(`v2/lv/booking/start-verification/${booking_id}`,{booking_id})
        if($api.isSuccessful(response)){
            setIsLoading(false)
            router(`/verify/${booking_id}`)
        }
        else{
            setIsLoading(false)
            toast.error("Couldn't start verification")
        }
    }

    return(
        <AuthLayout>
            <div className="start-verification-modal" >
                <h4>This signifies that you are comfortable with the apartment you viewed and want to move forward. <br/>  <br/> Do you still want to continue?</h4>
                <div className="start-verification-modal__button-container">
                    <button className="start-verification-modal__button-container__no" onClick={() => setStartVerificationModalActive(false)}>No</button>
                    <button className="start-verification-modal__button-container__yes" onClick={() => handleSubmit()}>
                        {
                            isLoading
                                ?
                                <div className="loading"></div>
                                :
                                'Yes'
                        }
                    </button>
                </div>
            </div>
        </AuthLayout>
    )
}
export default StartVerificationModal