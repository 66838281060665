import { IProperty, IProperties } from './types';
import { Action, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { $api } from '../../../services';
import { RootState } from '../../store';

const initialState:IProperties = {
    landingPageProperties: undefined,
    recentlyRentedApartments: undefined,
    properties: undefined,
    propertiesLoading: true,
    landingPagePropertiesLoading: true,
    recentlyRentedApartmentsLoading: true,
    morePropertiesLoading: false,
    meta: {
        current_page: 0,
        last_page: 0,
    }
}

type AppThunk = ThunkAction<void, RootState, unknown, Action>;

const propertiesSlice = createSlice({
    'name': 'property',
    initialState,
    reducers: {
        setProperties: (state, action:PayloadAction<IProperty[]>) => { state.properties = action.payload },
        updateProperties: (state, action:PayloadAction<IProperty[]>) => { state.properties = state.properties?.concat(action.payload) },
        setLandingPageProperties: (state, action:PayloadAction<IProperty[]>) => {state.landingPageProperties = action.payload},
        setRecentlyRentedApartments: (state, action:PayloadAction<IProperty[]>) => {state.recentlyRentedApartments = action.payload},
        setPropertiesLoading: (state, action:PayloadAction<boolean>) => { state.propertiesLoading = action.payload},
        setLandingPagePropertiesLoading: (state, action:PayloadAction<boolean>) => { state.landingPagePropertiesLoading = action.payload },
        setRecentlyRentedApartmentsLoading: (state, action:PayloadAction<boolean>) => { state.recentlyRentedApartmentsLoading = action.payload },
        setMorePropertiesLoading: (state, action:PayloadAction<boolean>) => { state.morePropertiesLoading = action.payload },
        setCurrentPage: (state, action: PayloadAction<number>) => { state.meta.current_page = action.payload },
        setLastPage: (state, action: PayloadAction<number>) => { state.meta.last_page = action.payload },
    }
});

export default propertiesSlice.reducer
export const { setProperties, setLandingPageProperties, setPropertiesLoading, setLandingPagePropertiesLoading, setCurrentPage, setLastPage, updateProperties, setMorePropertiesLoading, setRecentlyRentedApartments, setRecentlyRentedApartmentsLoading } = propertiesSlice.actions;

export const fetchLandingPageProperties = ():AppThunk =>
    async (dispatch) => {
        let endpoint = `v2/lv/landing-page-units/`
        const response:any = await $api.fetch(endpoint)
        dispatch(setLandingPageProperties(response?.data?.data))
        dispatch(setLandingPagePropertiesLoading(false))
    }

export const fetchRecentlyRentdApartments = ():AppThunk =>
    async (dispatch) => {
        let endpoint = `v2/lv/recently-rented-units/`
        const response:any = await $api.fetch(endpoint)
        dispatch(setRecentlyRentedApartments(response?.data))
        dispatch(setRecentlyRentedApartmentsLoading(false))
    }

export const fetchProperties = ():AppThunk => 
    async(dispatch) => {
        let endpoint = `v2/lv/available-units`
        const response:any = await $api.fetch(endpoint)
        dispatch(setProperties(response?.data?.data))
        dispatch(setPropertiesLoading(false))
        dispatch(setCurrentPage(response?.data?.meta?.current_page))
        dispatch(setLastPage(response?.data?.meta?.last_page))
    }

export const filterProperties = (search:any):AppThunk => 
    async(dispatch) => {
        let endpoint = `v2/lv/available-units?page=1&${
            search.location_id ? `location_id=${search.location_id}&` : ''
          }${search.budget_id ? `budget_id=${search.budget_id}&` : ''}${
            search.type_id ? `type_id=${search.type_id}&` : ''
          }${search.room ? `room=${search.room}` : ''}`
        dispatch(setPropertiesLoading(true));
        const response:any = await $api.fetch(endpoint)
        dispatch(setPropertiesLoading(false));
        dispatch(setProperties(response?.data?.data))
        dispatch(setCurrentPage(response?.data?.meta?.current_page))
        dispatch(setLastPage(response?.data?.meta?.last_page))
    }

export const fetchMoreProperties = (search:any):AppThunk => 
    async(dispatch) => {
        let endpoint = `v2/lv/available-units?page=${search.page}&${
            search.location_id ? `location_id=${search.location_id}&` : ''
          }${search.budget_id ? `budget_id=${search.budget_id}&` : ''}${
            search.type_id ? `type_id=${search.type_id}&` : ''
          }${search.room ? `room=${search.room}` : ''}`
        const response:any = await $api.fetch(endpoint)
        dispatch(updateProperties(response?.data?.data))
        dispatch(setCurrentPage(response?.data?.meta?.current_page))
        dispatch(setLastPage(response?.data?.meta?.last_page))
        dispatch(setMorePropertiesLoading(false))
    }