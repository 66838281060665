import { Component, ErrorInfo } from "react";
import ErrorComponent from "./errorComponent";

class ErrorBoundary extends Component<{children: React.ReactNode}>{
    constructor(props: any){
        super(props)
        this.state = { hasError: false, error: null, errorInfo: null }
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        this.setState({
            hasError: true,
            error,
            errorInfo
        })
    }
    render (){
        if((this.state as any).hasError){
            return(
                <ErrorComponent/>
            )
        }
        return this.props.children;
    }
}
export default ErrorBoundary;