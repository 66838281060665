import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { 
    ARROW_DOWN_ICON, 
    FIND_HOME_ICON, 
    SEARCH_LOCATION_ICON, 
    SEARCH_MONEY_ICON, 
    BLUE_SEARCH_ICON,
    SAMSUNG,
    PAYSTACK,
    KUDA,
    HEIRS_HOLDINGS,
    BLK_HUT,
    KONGA,
    GTCO,
    VETIVA,
    KOBO,
    ARROW_LEFT,
} from "../assets/image";
import ApartmentCard, { ApartmentCardLoader } from "../components/cards/apartmentCard";
import PageLayout from "../layout/pageLayout";
import { fetchLandingPageProperties, fetchRecentlyRentdApartments } from "../store/slices/properties/propertiesSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { AppDispatch } from "../store/slices/properties/types";
import { clearFilter, fetchAvailableLocations, fetchAvailablePrice, setLocationId, setLocationName, setPriceId, setPriceName } from "../store/slices/filter/filterSlice";
import UpcomingApartmentCard from "../components/cards/upcomingApartmentCard";
import upcomingApartments from "../assets/data/upcomingApartments";
import upcomingLocations from "../assets/data/upcomingLocations";
import { Typewriter } from 'typewriting-react';

const Home = ():JSX.Element => {
    const [ currentUpcomingLocation, setCurrentUpcomingLocation ] = useState<number>(0);
    const properties = useSelector((state:RootState) => state.properties )
    const filter = useSelector((state:RootState) => state.filter)
    const dispatch = useDispatch<AppDispatch>();
    const [ locationFilterActive, setLocationFilterActive ] = useState<boolean>(false);
    const [ priceFilterActive, setPriceFilterActive ] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    useEffect(() => {
        const changeLocationInterval = setInterval(() => {
            currentUpcomingLocation >= upcomingLocations.length - 1 ? setCurrentUpcomingLocation(0) : setCurrentUpcomingLocation(currentUpcomingLocation + 1) 
        },1000)

        return() => {
            clearInterval(changeLocationInterval)
        }
    },[ currentUpcomingLocation ])

    useEffect(() => {
        dispatch(clearFilter())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        !properties.landingPageProperties && dispatch(fetchLandingPageProperties());
        !properties.recentlyRentedApartments && dispatch(fetchRecentlyRentdApartments());
        !filter.availableLocations && dispatch(fetchAvailableLocations());
        !filter.availablePrice && dispatch(fetchAvailablePrice());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const locationRef = useRef<any>(null);
    const priceRef = useRef<any>(null);

    const handleClick = (e:any) => {
        if (locationFilterActive && locationRef.current && !locationRef.current.contains(e.target)) {
            setLocationFilterActive(false);
        }
        else if (priceFilterActive && priceRef.current && !priceRef.current.contains(e.target)) {
            setPriceFilterActive(false);
        }
    }

    const renderProperties = () => {
        return(
            properties.landingPageProperties
                ?
                properties.landingPageProperties.map((property: any) => {
                    return(
                        <ApartmentCard 
                            key={property.id}
                            id={property.id}
                            location={property.name + ", " + property.location.sub_area + ", " + property.location.state } 
                            pricing={property.amount}
                            img={property?.main_image?.high}
                            lowResImg={property?.main_image?.thumbnail}
                            unit_summary={property?.unit_summary}
                            rooms={[
                                `${property?.location?.area}`,
                                `${property.bedroom} bedroom`,
                                `${property.bathroom} bathroom`
                            ]} 
                            slug={property.slug}
                            availableAt={property?.availability}
                            additionalFeatures={property?.ammenities}
                        />
                    )
                    })
                :
                ''
        )
    }

    const renderUpcomingApartments = () => {
        return(
            upcomingApartments.slice(0,6).map((apartment, index) => {
                return(
                    <UpcomingApartmentCard key={index} {...apartment}/>
                )
            })
        )
    }

    const renderRecentlyRentedApartments = () => {
        return(
            properties.recentlyRentedApartments
                ?
                properties.recentlyRentedApartments.map((property: any) => {
                    return(
                        <ApartmentCard 
                            isRecentlyRented={true}
                            key={property.id}
                            id={property.id}
                            location={property.name + ", " + property.location.sub_area + ", " + property.location.state } 
                            pricing={property.amount}
                            img={property?.main_image?.high}
                            lowResImg={property?.main_image?.thumbnail}
                            unit_summary={property?.unit_summary}
                            rooms={[
                                `${property?.location?.area}`,
                                `${property.bedroom} bedroom`,
                                `${property.bathroom} bathroom`
                            ]} 
                            slug={property.slug}
                            availableAt={property?.availability}
                            additionalFeatures={property?.ammenities}
                        />
                    )
                    })
                :
                ''
        )
    }

    const router = useNavigate();

    return(
        <div onClick={(e) => handleClick(e)}>
            <PageLayout>
                <div className="home" id="home">
                    <div className="home__top-section">
                        <div className="hoem__top-section__header">
                            <h1>
                                Where housing meets {" "}
                                <Typewriter 
                                    words={[ 'Ambition', 'Comfort', 'Community', 'Memories', 'Productivity...']} 
                                    cursorClassName="typewriter-cursor"
                                    typingSpeed={100}
                                />
                            </h1>
                        </div>
                        <h4>
                            With us, it's beyond renting a home. 
                        </h4>
                        <div className="home__top-section__search-bar">
                            <div className="home__top-section__search-bar__location">
                                <div onClick={() => setLocationFilterActive(!locationFilterActive)} className="pointer">
                                    <img src={SEARCH_LOCATION_ICON} alt=""/>
                                    <p>{!filter.location.name ?  "Search & Select Location" : filter.location.name }</p>
                                    <img src={ARROW_DOWN_ICON} alt=""/>
                                </div>
                            { locationFilterActive &&
                                <ul ref={locationRef}>
                                    {
                                        filter.availableLocations
                                            ?
                                            filter.availableLocations.map((location: any) => {
                                                return <li key={location.id} className="pointer" onClick={() => { setLocationFilterActive(false); dispatch(setLocationId(location.id)); dispatch(setLocationName(location.name))}}>{location.name}</li>
                                            }
                                            )
                                            : 
                                            <li>No result</li>
                                    }
                                </ul>
                            } 
                            </div>
                            <div className="home__top-section__search-bar__price">
                                <div onClick={() => setPriceFilterActive(!priceFilterActive)} className="pointer">
                                    <img src={SEARCH_MONEY_ICON} alt=""/>
                                    <p>{ !filter.price.name ? 'Select Price Range' : filter.price.name }</p>
                                    <img src={ARROW_DOWN_ICON} alt=""/>
                                </div>
                                { priceFilterActive &&
                                    <ul ref={priceRef}>
                                        {
                                            filter.availablePrice
                                                ?
                                                filter.availablePrice.map((price: any) => {
                                                    return <li key={price.id} className="pointer" onClick={() => { setPriceFilterActive(false); dispatch(setPriceId(price.id)); dispatch(setPriceName(price.name))}}>{price.name}</li>
                                                }
                                                )
                                                : 
                                                <li>No result</li>
                                        }
                                    </ul>
                            } 
                            </div>
                            <div className="home__top-section__search-bar__button">
                                <button onClick={() => router('/search')} className="pointer">
                                    <img src={FIND_HOME_ICON} alt=""/>
                                    Find a home
                                </button>
                            </div>
                        </div>
                    </div> 
                    <div className="home__apartment-card-wrapper">
                        <div className="home__apartment-card-wrapper__main">
                            <>
                                {
                                    properties.landingPageProperties
                                        ?
                                        renderProperties()
                                        :
                                        ''
                                }
                            </>
                            {
                                (properties.landingPagePropertiesLoading || !properties.landingPageProperties)
                                    && 
                                <>
                                    <ApartmentCardLoader/>
                                    <ApartmentCardLoader/>
                                    <ApartmentCardLoader/>
                                    <ApartmentCardLoader/>
                                    <ApartmentCardLoader/>
                                    <ApartmentCardLoader/>
                                </>

                            }
                        </div>
                        <div className="home__apartment-card-wrapper__search-more">
                            <button onClick={() => router('/search')} className="pointer">
                                <img src={BLUE_SEARCH_ICON} alt=""/>
                                SEARCH MORE
                            </button>
                        </div>
                    </div>
                    <div className="home__upcoming-apartments" id="upcoming-apartments">
                        <div className="header"><h1>Join the waitlist for our upcoming apartments in <span>{upcomingLocations[currentUpcomingLocation]}</span></h1></div>
                        <div className="home__apartment-card-wrapper">
                            <div className="home__apartment-card-wrapper__main">
                                {renderUpcomingApartments()}
                            </div>
                        </div>
                        <button className="home__upcoming-apartments__view-more" onClick={() => router('/upcoming-apartments')}>
                            View All
                            <img src={ARROW_LEFT} alt=""/>
                        </button>
                    </div>
                    <div className="home__recently-rented">
                        { properties?.recentlyRentedApartments
                            ?
                                properties?.recentlyRentedApartments?.length > 0 
                                &&
                                <>
                                    <div className="home__recently-rented__top">
                                        <h2>Recently Rented Apartments</h2>
                                    </div>
                                    <div className="home__recently-rented__main">
                                        { renderRecentlyRentedApartments() }
                                    </div>
                                </>
                            :
                            ''
                        }
                    </div>
                    <div className="home__testimonials">
                        <div className="home__testimonials__top">
                            <h2>What Our Customers Are Saying</h2>
                        </div>
                        <div>
                            <div className='home__testimonials__videosection'>
                                <iframe src="https://youtube.com/embed/i_CWKbWChrI?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                <iframe src="https://youtube.com/embed/chjdiNQJzFw?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                        <section>
                            <div className="home__testimonials__section">
                                <div className="home__testimonials__section__child">
                                    <h4 className="home__testimonials__section__child__first">Liveable made life easy for me, I didn’t have to deal with the challenges of agents and co. One call, google doc with all the details, online bookings, onsite viewings with no agency fee, modern facility, payment and onboarding.</h4>
                                    <div className="home__testimonials__section__child__bottom">
                                        <h3>Dr Joy Aifuo - </h3>
                                        <p>mydoctorpadi</p>
                                    </div>
                                </div>
                                <div className="home__testimonials__section__child">
                                    <h4 className="home__testimonials__section__child__first">Dear Liveable, thank you for being decent in your dealings and not being overtaken by greed consuming realtors these days. Allowing affordable housing for young people starting their lives. Your prices are fair compared to otters.You are changing the game</h4>
                                    <div className="home__testimonials__section__child__bottom">  
                                        <h3>Nnenna - </h3>
                                        <p>Everything Interior Deco</p>
                                    </div>
                                </div>
                                <div className="home__testimonials__section__child">
                                    <h4 className="home__testimonials__section__child__first">I thought you guys would be the ones managing,  one of the reasons I am interested in the apartment is because I am comfortable dealing with Liveable. Any issues that have come up with the Yaba apartment have been handled satisfactorily by you guys.</h4> 
                                    <div className="home__testimonials__section__child__bottom">
                                        <h3>Efe - </h3>
                                        <p>squarepeg multimedia</p>
                                    </div>
                                </div>
                            </div>
                       </section>
                    </div>
                    <div className="home__potential-customers">
                        <h2>Our tenants work for companies like</h2>
                        <div className="home__potential-customers__img">
                            <span><img src={SAMSUNG} alt=""/></span>
                            <span><img src={PAYSTACK} alt=""/></span>
                            <span><img src={KUDA} alt=""/></span>
                            <span><img src={HEIRS_HOLDINGS} alt=""/></span>
                            <span><img src={BLK_HUT} alt=""/></span>
                            <span><img src={KONGA} alt=""/></span>
                            <span><img src={KOBO} alt=""/></span>
                            <span><img src={VETIVA} alt=""/></span>
                            <span><img src={GTCO} alt=""/></span>
                        </div>
                        <button onClick={() => window.scrollTo(0,0)} className="pointer">
                            <img src={BLUE_SEARCH_ICON} alt=""/>
                            FIND A HOME
                        </button>
                    </div>
                </div>
            </PageLayout>
        </div>
    )
}
export default Home;