import { useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { WHITE_RIGHT_ARROW } from "../../assets/image";
import { $api } from "../../services";
import decryptData from "../decryptData";

const FullPaymentButton = (props) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const unitId = props.unitId
    const user_id = decryptData(localStorage.getItem('liveableUserId'), process.env.REACT_APP_DECRYPT_USER_ID_SECRET_KEY)
    const router = useNavigate();
    const email = decryptData(localStorage.getItem('liveableUserEmail'), process.env.REACT_APP_DECRYPT_USER_EMAIL_SECRET_KEY );
    const setBankDepositModalActive = props.setBankDepositModalActive
    const setMonthlyDepositModalActive = props.setMonthlyDepositModalActive

    const handlePayment = async(reference) => {
        const response = await $api.post('v2/lv/pay-rent', {
            user_id: user_id,
            unit_id: unitId,
            payment_method: 'paystack',
            trx_ref: reference.trxref,
        })
        // router(`/onboarding/${props.bookingId}`);
    }


    const config = {
        reference: (new Date()).getTime().toString(),
        email: email,
        amount: props.amount * 100,
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || '',
    };
    const initializePayment = usePaystackPayment(config);

    const onSuccess = (reference) => {
        handlePayment(reference)
    };
    
    const onClose = () => {
        toast.info('payment not completed')
    }

    const handleFullPayment = async() => {
        if(props.selected === 'paystack'){
            initializePayment(onSuccess, onClose)
        }
        else {
            setIsLoading(true)
            const response = await $api.post('v2/lv/pay-rent', {
                user_id: user_id,
                unit_id: unitId,
                payment_method: props.selected,
            })
            setIsLoading(false)
            if($api.isSuccessful(response)){
                if(props.selected === 'bank'){
                    setBankDepositModalActive(true)
                }
                if(props.selected === 'bank'){
                    setBankDepositModalActive(true)
                }
                else{
                    setMonthlyDepositModalActive(true)
                }
            }
        }
    }

    return(
        <button 
            onClick={() => handleFullPayment()}
            className={props.className}
        >
        {
            isLoading
                ?
                <div className="loading"></div>
                :
                <>
                    MAKE PAYMENT
                    <img src={WHITE_RIGHT_ARROW} alt=""/>
                </>
        }
        </button>
    )
}
export default FullPaymentButton;