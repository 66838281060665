export const events = {
    userLogin:{
    
          category:'User',
          action:'User LogIn',
       label:'User Login'
    },
    userSignup:{
          category:'User',
          action:' User Signup',
          label:'User signup'
    },
}