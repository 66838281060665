import React, { Suspense, useEffect, useState } from "react";
import { IconPickerItem } from "react-fa-icon-picker";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { 
    ARROW_LEFT,
    BLUE_VIEW, 
    PROPERTY_LOCATION_ICON,
    PROPERTY_MONEY_ICON,
    SHOW_PHOTO,
} from "../assets/image";
import AvailableButton, { RecentlyRentedButton } from "../components/buttons/availabeButton";
import BookViewingButton from "../components/buttons/bookViewing";
import RentButton from "../components/buttons/rentNow";
import RentBreakdown from "../components/cards/rentBreakdown";
import PageLayout from "../layout/pageLayout";
import { setActivePicture, setPictureModalActive } from "../store/slices/picture/pictureSlice";
import { AppDispatch } from "../store/slices/properties/types";
import { fetchProperty } from "../store/slices/property/propertySlice";
import { RootState } from "../store/store";
import "react-loading-skeleton/dist/skeleton.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setActiveBooking } from "../store/slices/booking/bookingSlice";
import { InlineShareButtons } from "sharethis-reactjs";
import ViewProgress from "../components/buttons/viewProgress";
import Map from "../components/map";
import WaitlistButton from "../components/buttons/waitlistButton";
import WaitlistSuccessful from "../modal/waitlistsuccessful";
import JoinWaitlist from "../modal/auth/joinWaitlist";
import ApartmentNotAvailable from "../components/cards/apartmentNotAvailable";

const PicturesModal = React.lazy(() => import('../modal/picturesModal'))

const Property = () => {
    const [ rentBreakdownActive, setRentBreakdownActive ] = useState(false);
    const [ waitlistSuccessfulModalActive, setWaitlistSuccessfulModalActive ] = useState<boolean>(false);
    const [ joinWaitlistModalActive, setJoinWaitlistModalActive ] = useState<boolean>(false);
    const router = useNavigate();
    const picture = useSelector((state:RootState) => state.picture)
    const property = useSelector((state:RootState) => state.property)
    const dispatch = useDispatch<AppDispatch>();

    let { slug } = useParams();
    const location = useLocation();
    const isRecentlyRented = location.pathname.includes('recently-rented')
    const isWaitlist = location.pathname.includes('join-waitlist')
    // eslint-disable-next-line eqeqeq
    const isNotPublished = property?.property?.published == false

    useEffect(() => {
        fetchUnit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ slug ])

    const fetchUnit= async() => {
        if (slug) {
            let slugArray = slug.split("-");
            const slugLength = slugArray.length
            const propertyId = slugArray[slugLength-1]
            if (propertyId){
                const response:any = await dispatch(fetchProperty( propertyId ))
                response?.response?.status === 404 && router('/not-found')
            }
            else{
                router('/not-found')
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
        dispatch(setPictureModalActive(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ property ])

    useEffect(() => {
        dispatch(setActiveBooking(property.property))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>  
            <Suspense>
                { picture.pictureModalActive && <PicturesModal/>}
            </Suspense>
            { waitlistSuccessfulModalActive && <WaitlistSuccessful setWaitlistSuccessfulModalActive={setWaitlistSuccessfulModalActive} /> }
            { joinWaitlistModalActive && <JoinWaitlist setJoinWaitlistModalActive={setJoinWaitlistModalActive} setWaitlistSuccessfulModalActive={setWaitlistSuccessfulModalActive}/> }
            { rentBreakdownActive && <RentBreakdown rent={property.property.amount} setRentBreakdownActive={setRentBreakdownActive} agencyFee={property.property.agency_fee} serviceCharge={property.property.service_charge} cautionDeposit={property.property.caution_deposit} legalFee={property.property.legal_fee}/> }
            <SkeletonTheme baseColor='#F5F5F5' highlightColor='#ffffff'>
                <PageLayout>
                    <div className="property-page">
                        <div className="property-page__top" onClick={() => router(-1)}>  
                            <>
                                <img src={ARROW_LEFT} alt=""/>
                                <p>
                                    <span>Search</span> <span className="">/</span>
                                    { 
                                        (property.propertyLoading || !property.property?.address)
                                        ? 
                                        <Skeleton width={'100%'} height={15}/> 
                                        : 
                                        <>{" "}{property.property?.name}, {property.property.location.sub_area}, {property.property.location.area}, {property.property.location.state}</>
                                    }
                                </p>
                            </>
                        </div>
                        <div className="property-page__main">
                            {
                                (property.propertyLoading || !property.property?.address)
                                    ?
                                    <Skeleton width={470} height={30}/>
                                    :
                                    <div className="property-page__main__about">
                                        <div className="property-page__main__about__address">
                                            <h4>{property.property?.name}, {property.property?.location.sub_area}, {property.property.location.area}, {property.property?.location.state} </h4>
                                            {
                                                property?.property?.coordinates?.lat !== undefined && property.property?.coordinates?.lat !== "0.00000000"
                                                    &&
                                                <a href="#map" className="property-page__main__rent--breakdown" style={{ textDecoration: 'none' }}>
                                                    View Location
                                                    <img src={BLUE_VIEW} alt=""/>
                                                </a>
                                            }
                                            { isRecentlyRented ? <RecentlyRentedButton style={{ backgroundColor: '#EFF2FE' }}/> : <AvailableButton availableAt={property.property.availability}/> }
                                        </div>
                                        <div className="property-page__main__about__options">
                                        {   !isRecentlyRented && !isWaitlist && !isNotPublished
                                                &&
                                            (
                                                property?.property?.booking?.id
                                                    ?
                                                <ViewProgress/>
                                                :
                                                <>
                                                    <RentButton/>
                                                    <BookViewingButton/>
                                                </>
                                            )
                                        }
                                        { isWaitlist && <WaitlistButton setWaitlistSuccessfulModalActive={setWaitlistSuccessfulModalActive} setJoinWaitlistModalActive={setJoinWaitlistModalActive}/> }
                                        { isNotPublished &&  <ApartmentNotAvailable/> }
                                        </div>
                                    </div>
                            }
                            <div className="property-page__main__address">
                                {
                                    (property.propertyLoading || !property.property?.address)
                                        ?
                                        <Skeleton width={470} height={30}/>
                                        :
                                        <>
                                            <img src={PROPERTY_LOCATION_ICON} alt=""/>
                                            <h4>{property.property.address}, {property.property.location.sub_area}, {property.property.location.area}, {property.property.location.state}.</h4>
                                        </>
                                }
                            </div>
                            <div className="property-page__main__rent">
                                {
                                    (property.propertyLoading || !property.property?.amount)
                                        ?
                                        <Skeleton width={300} height={30}/>
                                        :
                                        <>
                                            <div className="property-page__main__rent--amount">
                                                <img src={PROPERTY_MONEY_ICON} alt=""/>
                                                <h4>{property?.property?.amount}</h4>
                                            </div>
                                            <button className="property-page__main__rent--breakdown" onClick={() => setRentBreakdownActive(true)}>
                                                RENT BREAKDOWN
                                                <img src={BLUE_VIEW} alt=""/>
                                            </button>
                                        </>
                                }
                            </div>
                            {
                                (property.propertyLoading || !property.property?.address)
                                    ?
                                    ''
                                    :
                                <div className="property-page__main__sm-options">

                                    {   !isRecentlyRented && !isWaitlist && !isNotPublished
                                            &&
                                        (
                                            property?.property?.booking?.id 
                                                ?
                                            <ViewProgress/>
                                            :
                                            <>
                                                <RentButton/>
                                                <BookViewingButton/>
                                            </>
                                        )
                                    }
                                    {  isWaitlist && <WaitlistButton setWaitlistSuccessfulModalActive={setWaitlistSuccessfulModalActive} setJoinWaitlistModalActive={setJoinWaitlistModalActive}/>}
                                    {  isNotPublished &&  <ApartmentNotAvailable/> }
                                </div>
                            }
                            <div className="property-page__main__amenities">
                                <h3>Amenities</h3>
                                {
                                    (property?.propertyLoading || !property?.property?.ammenities)
                                        ?
                                        <div style={{ display: 'flex', gap: '60px', rowGap: '45px', flexWrap: 'wrap', justifyContent: 'center'}}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                                                <Skeleton width={35} height={35} borderRadius={'50%'}/>
                                                <Skeleton height={12} width={80}/>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                                                <Skeleton width={35} height={35} borderRadius={'50%'}/>
                                                <Skeleton height={12} width={80}/>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                                                <Skeleton width={35} height={35} borderRadius={'50%'}/>
                                                <Skeleton height={12} width={80}/>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                                                <Skeleton width={35} height={35} borderRadius={'50%'}/>
                                                <Skeleton height={12} width={80}/>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                                                <Skeleton width={35} height={35} borderRadius={'50%'}/>
                                                <Skeleton height={12} width={80}/>
                                            </div>
                                        </div>
                                        :
                                        <ul>
                                            <>
                                                {
                                                    property?.property?.ammenities
                                                        ?
                                                        property?.property?.ammenities?.map((ammenity, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <IconPickerItem icon={ammenity.icon_class} size={16} color="#616E9E" onClick={()=> {}} />
                                                                    <p>{ammenity.name}</p>
                                                                </li>
                                                            )
                                                        })
                                                        :
                                                        ''
                                                }
                                            </>
                                        </ul>
                                }
                            </div>
                            <div className="property-page__main__photos">
                                {
                                    property.property?.files
                                    &&
                                    <div className="property-page__main__photos--1">
                                        <button onClick={() => dispatch(setPictureModalActive(true))} style={{ cursor: 'pointer'}}>
                                            <img src={SHOW_PHOTO} alt=""/>
                                            SHOW ALL PHOTOS
                                        </button>
                                        {
                                            (property?.propertyLoading || !property?.property?.files)
                                                ?
                                                <Skeleton width={521} height={558}/>
                                                :
                                                <LazyLoadImage
                                                    height="auto"
                                                    effect="blur"
                                                    placeholderSrc={property?.property?.files[0]?.thumbnail}
                                                    src={property?.property?.files[0]?.thumbnail}
                                                    onClick={() => { dispatch(setPictureModalActive(true))}}
                                                    style={{cursor: 'pointer'}}
                                                />
                                        }
                                    </div>
                                }
                                {   
                                    (property?.propertyLoading || !property?.property?.files)
                                        ?
                                        <Skeleton style={{ maxWidth: '90%'}} width={521} height={558}/>
                                        :   property.property.files.length > 1 &&
                                        <div className="property-page__main__photos--wrap">
                                            <div className={ property.property?.files.length > 3 ? "property-page__main__photos--2" : "w-100 property-page__main__photos--2" }>
                                                {
                                                    property.property.files.length > 1 &&
                                                    <div className={ property.property?.files.length === 2 ? "h-100" : ''}>
                                                        <LazyLoadImage
                                                            effect="blur"
                                                            placeholderSrc={property?.property?.files[1]?.thumbnail}
                                                            src={property?.property?.files[1]?.thumbnail}
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => { dispatch(setActivePicture(1)); dispatch(setPictureModalActive(true))}}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    property.property.files.length > 2 &&
                                                    <LazyLoadImage
                                                        effect="blur"
                                                        placeholderSrc={property?.property?.files[2]?.thumbnail}
                                                        src={property?.property?.files[2]?.thumbnail}
                                                        onClick={() => { dispatch(setActivePicture(2)); dispatch(setPictureModalActive(true))}}
                                                        style={{cursor: 'pointer'}}
                                                    />
                                                }
                                            </div>
                                            {
                                                property.property?.files?.length  && property.property?.files.length > 3 &&    
                                                <div className="property-page__main__photos--3" style={{cursor: 'pointer'}}>                                             
                                                    <LazyLoadImage
                                                        height="auto"
                                                        effect="blur"
                                                        placeholderSrc={property?.property?.files[3]?.thumbnail}
                                                        src={property?.property?.files[3]?.thumbnail}
                                                        onClick={() => { dispatch(setActivePicture(3)); dispatch(setPictureModalActive(true))}}
                                                    />
                                                </div>
                                            }
                                        </div>
                                }
                            </div>
                            {   property?.propertyLoading 
                                    ?
                                    ''
                                    :
                                property.property?.coordinates?.lat !== undefined && property.property?.coordinates?.lat !== "0.00000000"
                                    &&
                                <div className="property-page__main__info" id="map">
                                    <div className="property-page__main__info__description">
                                        <h3>Location:</h3>
                                        <div className="map"><Map location={{ lat: Number(property.property?.coordinates?.lat), lng: Number(property.property?.coordinates?.lng) }}/></div>
                                    </div>
                                </div>
                            }
                            <div className="property-page__main__info">
                                <div className="property-page__main__info__description">
                                    <h3>Description</h3>
                                    {
                                        (property.propertyLoading || !property.property?.description)
                                            ?
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px'}}>
                                                <Skeleton height={17}/>
                                                <Skeleton height={17}/>
                                                <Skeleton height={17}/>
                                            </div>
                                            :
                                            <>
                                                <div dangerouslySetInnerHTML={{ __html: property.property.description }}/>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="property-page__main__info__description" style={{ marginTop: '50px'}}>
                                <h3>Additional Info</h3>
                                {
                                    (property.propertyLoading || !property.property?.description)
                                        ?
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px'}}>
                                            <Skeleton height={17}/>
                                            <Skeleton height={17}/>
                                            <Skeleton height={17}/>
                                        </div>
                                        :
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: property.property.additional_info }}/>
                                        </>
                                }
                            </div>
                            {
                                !isRecentlyRented
                                    &&
                                <div className="property-page__main__share">
                                    <div>
                                        <h4>Share with friends:</h4>
                                        <InlineShareButtons
                                            config={{
                                                alignment: "left", // alignment of buttons (left, center, right)
                                                color: "social", // set the color of buttons (social, white)
                                                enabled: true, // show/hide buttons (true, false)
                                                font_size: 16, // font size for the buttons
                                                labels: null, // button labels (cta, counts, null)
                                                language: "en", // which language to use (see LANGUAGES)
                                                networks: [
                                                // which networks to include (see SHARING NETWORKS)
                                                "twitter",
                                                "linkedin",
                                                "whatsapp",
                                                //   "instagram",
                                                "facebook",
                                                //   "telegram"
                                                ],
                                                padding: 12, // padding within buttons (INTEGER)
                                                radius: 4, // the corner radius on each button (INTEGER)
                                                show_total: false,
                                                size: 40, // the size of each button (INTEGER)
    
                                                // OPTIONAL PARAMETERS // (defaults to current url)
                                                image: `${ property?.property?.files[0]?.thumbnail}`, // (defaults to og:image or twitter:image)
                                                description: `${property?.property?.description}`, // (defaults to og:description or twitter:description)
                                                title: `${property?.property?.name}`, // (defaults to og:title or twitter:title)
                                                // message: "Liveable House Message", // (only for email sharing)
                                                // subject: "Liveable House Subject" // (only for email sharing)
                                                username: "LiveableNg" // (only for twitter sharing)
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="property-page__main__options">
                            {  !isRecentlyRented && !isWaitlist && !isNotPublished
                                    &&
                                (
                                    !(property?.propertyLoading || !property?.property?.slug)
                                    ?
                                        property?.property?.booking?.id
                                        ?
                                        <ViewProgress/>
                                        :
                                        <>
                                            <RentButton />
                                            <BookViewingButton />
                                        </>
                                    :
                                    ''
                                )
                            }
                            </div>
                        </div>
                    </div>
                </PageLayout>
            </SkeletonTheme>
        </>
    )
}
export default Property;