import axios from "axios";
import { useEffect, useState } from "react";
import { BLUE_ARROW_DOWN, SEARCH_LOCATION } from "../../assets/image";
import { $api } from "../../services";
import ErrorMessage from "../errorMessage";

interface ILocationSearchSelect {
    setQueryModalActive: React.Dispatch<React.SetStateAction<boolean>>,
    setLocation: React.Dispatch<React.SetStateAction<string>>,
    setLocationId: React.Dispatch<React.SetStateAction<string>>,
    queryModalActive: boolean,
    location: string,
    triedToSubmit: boolean,
    reference: React.RefObject<HTMLInputElement>,
    placeholder: string,
}
interface IOptions {
    id: string,
    name: string,
}

interface IOptionsCard {
    setLocation: React.Dispatch<React.SetStateAction<string>>
    setLocationId: React.Dispatch<React.SetStateAction<string>>
    setQueryModalActive: React.Dispatch<React.SetStateAction<boolean>>,
    option: string,
    id: string,
}

const SearchSelect = (props: ILocationSearchSelect) => {
    const [ options, setOptions ] = useState([]);
    const setQueryModalActive = props.setQueryModalActive 
    const queryModalActive = props.queryModalActive
    const [ query, setQuery ] = useState<string>('')
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const location = props.location
    const setLocation = props.setLocation
    const setLocationId = props.setLocationId
    const triedToSubmit = props.triedToSubmit

    const source = axios.CancelToken.source();
    
    useEffect(() => {
        query.length > 2 && fetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ query ])

    const fetchLocation = async() => {
        setIsLoading(true)
        const response = await $api.fetchWithCancelToken(`form-data/all-locations?search=${query}`, source.token)
        if($api.isSuccessful(response)){
            setOptions(response?.data)
            setIsLoading(false)
        }
        else{
            setIsLoading(false)
        }
    }
    
    return(
        <div className="search-select" style={{ margin: '0'}}>
            <div className="search-select__location">
                <div className="search-select__location__field" onClick={() => setQueryModalActive(true)} style={{ margin: '0'}}>
                    <input
                        type="text"
                        placeholder={props.placeholder}
                        id="location"
                        disabled
                        value={location}
                    />
                    <img src={BLUE_ARROW_DOWN} alt=""/>
                </div>
                { triedToSubmit && !location && <ErrorMessage message="location is required"/>}
            </div>
            {
                queryModalActive &&
                <div className="search-select__dropdown" ref={props.reference}>
                    <div className="search-select__dropdown__searchbar">
                        <img src={SEARCH_LOCATION} alt=""/>
                        <input
                            type="text"
                            placeholder="Search for a location"
                            onChange={(e) => { source.cancel(); setQuery(e.target.value) }}
                            value={query}
                        />
                    </div>
                    {
                        isLoading
                            ?
                            <div className="loading"></div>
                            :
                            options.length > 0
                                ?
                                options.map((option:IOptions) => {
                                    return (
                                        <OptionsCard key={option.id} setLocation={setLocation} setLocationId={setLocationId} option={option.name} id={option.id} setQueryModalActive={setQueryModalActive}/>
                                    )
                                })
                                :
                                <p>No results...</p>
                    }
                </div>
            }
        </div>
    )
}

const OptionsCard = (props: IOptionsCard) => {
    const setLocation = props.setLocation
    const setLocationId = props.setLocationId
    const setQueryModalActive = props.setQueryModalActive
    return(
        <div 
        onClick={() => {
            setLocationId(props.id)
            setLocation(props.option)
            setQueryModalActive(false)
        }}
        className="option-card"
        >
            <p>{props.option}</p>
        </div>
    )
}
export default SearchSelect;