import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { APARTMENT_ICON, BASIC_INFO_ICON, CONFIRMATION_ICON } from "../../../assets/image";
import { IProgressBarState } from "./type";


const initialState:IProgressBarState = {
    basicInfoStatus: {
        img: BASIC_INFO_ICON,
        status: 1,
        initialRequestMade: false,
    },
    apartmentInfoStatus: {
        img: APARTMENT_ICON,
        status: 0,
        initialRequestMade: false,
    },
    confirmationStatus: {
        img: CONFIRMATION_ICON,
        status: 0,
        initialRequestMade: false,
    },
    formIncomplete: false,
}
const ProgressBarSlice = createSlice({
    name : 'progressBar',
    initialState,
    reducers: {
        setBasicInfoImg: (state, action:PayloadAction<string> ) => { state.basicInfoStatus.img = action.payload },
        setBasicInfoStatus: (state, action:PayloadAction<number> ) => { state.basicInfoStatus.status = action.payload },
        setBasicInfoInitialRequest: (state, action:PayloadAction<boolean> ) => { state.basicInfoStatus.initialRequestMade = action.payload },
        setApartmentStatus: (state, action: PayloadAction<number>) => { state.apartmentInfoStatus.status = action.payload },
        setApartmentstatusImg: (state, action: PayloadAction<string>) => { state.apartmentInfoStatus.img = action.payload },
        setApartmentInitialRequest: (state, action:PayloadAction<boolean> ) => { state.apartmentInfoStatus.initialRequestMade = action.payload },
        setConfirmationStatus: (state, action: PayloadAction<number>) => { state.confirmationStatus.status = action.payload },
        setConfirmationstatusImg: (state, action: PayloadAction<string>) => { state.confirmationStatus.img = action.payload },
        setFormIncomplete: (state, action:PayloadAction<boolean>) => { state.formIncomplete = action.payload },
        setConfirmationInitialRequest: (state, action:PayloadAction<boolean> ) => { state.confirmationStatus.initialRequestMade = action.payload },
    }
})
export const { setBasicInfoImg, setBasicInfoStatus,setApartmentstatusImg, setApartmentStatus, setConfirmationstatusImg, setConfirmationStatus, setFormIncomplete, setBasicInfoInitialRequest, setApartmentInitialRequest, setConfirmationInitialRequest } = ProgressBarSlice.actions
export default ProgressBarSlice.reducer;