import { ACTIVE_APARTMENT_ICON, COMPLETE_BASIC_INFO_ICON } from '../../assets/image'
import { useEffect, useRef, useState } from "react";
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from 'react-redux'
import { 
    setBasicInfoStatus,
    setBasicInfoImg,
    setApartmentstatusImg,
    setApartmentStatus,
} from '../../store/slices/progressBar/progressBarSlice';
import { useNavigate } from 'react-router-dom';
import { setApartmentId, setEmail, setFirstName, setLastName, setPhoneNumber } from '../../store/slices/basicInfo/basicInfoSlice';
import ErrorMessage from '../../components/errorMessage';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import ContinueButton from '../../components/buttons/continueButton';
import { $api } from '../../services';
import { toast } from 'react-toastify';
import { setRequestPending } from '../../store/slices/request/requestSlice';


const EMAIL_REGEX = /^(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}$/;
const BasicInfo = ():JSX.Element => {
    const progressBar = useSelector((state:RootState) => state.progressBar)
    const firstNameRef = useRef<HTMLInputElement>(null);

    const router = useNavigate();
    const [ firstNameValid, setFirstNameValid ] = useState(false);
    const [ firstNameFocus, setFirstNameFocus ] = useState(false);

    const [ lastNameValid, setLastNameValid ] = useState(false);
    const [ lastNameFocus, setLastNameFocus ] = useState(false);

    const [ emailValid, setEmailValid ] = useState(false);
    const [ emailFocus, setEmailFocus ] = useState(false);

    const [ phoneNumberValid, setPhoneNumberValid ] = useState(false);
    const [ phoneNumberFocus, setPhoneNumberFocus ] = useState(false);

    const [ triedToSubmit, setTriedToSubmit ] = useState(false);

    const [ pageValid, setPageValid ] = useState<boolean>(false);

    const basicInfo = useSelector((state:RootState) => state.basicInfo)
    const dispatch = useDispatch();

    const handleSubmit = (e:any) => {
        e.preventDefault()
        pageValid 
            ? 
            handleValidSubmit()
            : 
            setTriedToSubmit(true)
    }
    
    const handleValidSubmit = async() =>  {
        dispatch(setRequestPending(true))
        const response = await $api.post('v2/lv/list/basic-info',{
            first_name: basicInfo.firstName, 
            last_name: basicInfo.lastName, 
            email: basicInfo.email, 
            phone: basicInfo.phoneNumber, 
        })
        dispatch(setRequestPending(false))
        if ($api.isSuccessful(response)){
            dispatch(setApartmentId(response?.data?.data?.user_id))
            dispatch(setBasicInfoImg(COMPLETE_BASIC_INFO_ICON));
            dispatch(setBasicInfoStatus(2));
            router('/list/apartment-info');
            progressBar.basicInfoStatus.status < 2 
            && dispatch(setApartmentstatusImg(ACTIVE_APARTMENT_ICON)) 
            && dispatch(setApartmentStatus(1))
        }
        else{
            toast.error('Network error')
        }
    }

    useEffect(() => {
        basicInfo.firstName !== '' ? setFirstNameValid(true) : setFirstNameValid(false);
        basicInfo.lastName !=='' ? setLastNameValid(true) : setLastNameValid(false);
        EMAIL_REGEX.test(basicInfo.email) ? setEmailValid(true) : setEmailValid(false);
        basicInfo.phoneNumber?.length === 14 ? setPhoneNumberValid(true) : setPhoneNumberValid(false);
    }, [
        basicInfo.firstName, 
        basicInfo.lastName, 
        basicInfo.email, 
        basicInfo.phoneNumber, 
        progressBar.basicInfoStatus.initialRequestMade, 
        basicInfo.firstRequestMade,
        pageValid ,
    ]);
  
    useEffect(() => {
        firstNameValid && 
        lastNameValid && 
        emailValid && 
        phoneNumberValid 
            ? 
            setPageValid(true) 
            : 
            setPageValid(false)
    },[
        firstNameValid, 
        lastNameValid, 
        emailValid, 
        phoneNumberValid, 
    ])

    return(
        <div className="basic-info-wrapper" id='list'>
            <form className="basic-info-wrapper__form">
                <div className="basic-info-wrapper__form__input">
                    <label htmlFor="first-name">First Name</label>
                    <input
                        ref={firstNameRef}
                        type="text"
                        placeholder="Ronald"
                        spellCheck="false"
                        id="name"
                        onChange={(e) =>  { dispatch(setFirstName(e.target.value))}}
                        onFocus={() => setFirstNameFocus(true)}
                        onBlur={() => setFirstNameFocus(false)}
                        value={basicInfo.firstName || ''}
                        className={ triedToSubmit && !firstNameValid ? 'invalid' : ''}
                    />  
                    { triedToSubmit && !firstNameValid && !firstNameFocus && <ErrorMessage message="please enter your first name"/>}
                </div>
                <div className="basic-info-wrapper__form__input" >
                    <label htmlFor="last-name">Last Name</label>
                    <input
                        type="text"
                        placeholder="Brown"
                        spellCheck="false"
                        id="last-name"
                        onChange={(e) =>  { dispatch(setLastName(e.target.value)) }}
                        onFocus={() => setLastNameFocus(true)}
                        onBlur={() => setLastNameFocus(false)}
                        value={basicInfo.lastName || ''}
                        className={ triedToSubmit && !lastNameValid ? 'invalid' : ''}
                    />
                    { triedToSubmit && !lastNameValid && !lastNameFocus && <ErrorMessage message="please enter your last name"/>}
                </div>
                <div className="basic-info-wrapper__form__input" >
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        placeholder="brown.ronald@gmail.com"
                        id="email"
                        onChange={(e) => { dispatch(setEmail(e.target.value)) }}
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        value={basicInfo.email || ''}
                        className={ triedToSubmit && !emailValid ? 'invalid' : ''}
                    />
                    { triedToSubmit && !emailValid && !emailFocus && <ErrorMessage message={ basicInfo.email !== '' ? "Enter correct email format" : "Enter your email"}/>}
                </div>
                <div className="basic-info-wrapper__form__input" >
                    <label htmlFor="phone-number">Phone number (Whatsapp preferred)</label>
                    <PhoneInputWithCountrySelect
                        defaultCountry="NG"
                        value={basicInfo.phoneNumber || ''}
                        onChange={(value) => { dispatch(setPhoneNumber(value)) }}
                        placeholder="08177886674"
                        className={ triedToSubmit && !phoneNumberValid ? 'invalid' : ''}
                        onFocus={() => setPhoneNumberFocus(true)}
                        onBlur={() => setPhoneNumberFocus(false)}
                    />
                    { triedToSubmit && !phoneNumberValid && !phoneNumberFocus && <ErrorMessage message={ basicInfo.phoneNumber === undefined ? "Enter your phone number" : "Enter correct phone number" }/>}
                </div>
                <ContinueButton onClick={(e) => handleSubmit(e)}/>
            </form>
        </div>
    )
}
export default BasicInfo;