import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RESCHEDULE_ICON, VERIFICATION_IN_PROGRESS } from "../../assets/image";
import VerificationLayout from "../../layout/apartmentVerificationLayout"
import { fetchBooking } from "../../store/slices/booking/bookingSlice";
import { AppDispatch } from "../../store/slices/properties/types";
import { RootState } from "../../store/store";

const VerificationFailed = () => {
    
    let { id } = useParams();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (id) {
            dispatch(fetchBooking(id))
        }
    },[ dispatch, id ])

    const { bookingStatus } = useSelector((state: RootState) => state.booking)

    return(
        <VerificationLayout>
            <div className="verification-in-progress">
                <img src={VERIFICATION_IN_PROGRESS} alt=""/>
                <h2>Your Verification Failed</h2>
                {
                    bookingStatus === 'verification-restart'
                        ?
                    <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_TENANT_VERIFICATION_LINK + `/?booking_id=${id}`}>
                        UPDATE VERIFICATION
                        <img src={RESCHEDULE_ICON} alt=""/>
                    </a>
                    :
                    ''
                }
            </div>
        </VerificationLayout>
    )
}
export default VerificationFailed;