import { configureStore } from "@reduxjs/toolkit";
import apartmentInfoReducer from "./slices/apartmentInfo/apartmentInfoSlice";
import authReducer from "./slices/auth/authSlice";
import basicInfoReducer from "./slices/basicInfo/basicInfoSlice";
import bookingReducer from "./slices/booking/bookingSlice";
import bookingsReducer from "./slices/bookings/bookingsSlice";
import filterReducer from "./slices/filter/filterSlice";
import modalReducer from "./slices/modal/modalSlice";
import pictureReducer from "./slices/picture/pictureSlice";
import progressBarReducer from "./slices/progressBar/progressBarSlice";
import propertiesReducer from "./slices/properties/propertiesSlice";
import propertyReducer from "./slices/property/propertySlice";
import requestReducer from "./slices/request/requestSlice";

const store = configureStore({
    reducer:{
        progressBar: progressBarReducer,
        basicInfo: basicInfoReducer,
        apartment: apartmentInfoReducer,
        request: requestReducer,
        modal: modalReducer,
        picture: pictureReducer,
        property: propertyReducer,
        properties: propertiesReducer,
        filter: filterReducer,
        auth: authReducer,
        booking: bookingReducer,
        bookings: bookingsReducer,
    }
})
export type RootState = ReturnType<typeof store.getState>

export default store