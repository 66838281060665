import { useEffect, useRef, useState } from "react";
import DatePicker from "react-date-picker";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ACTIVE_CONFIRMATION_ICON, COMPLETE_APARTMENT_ICON, DATE_ICON } from "../../assets/image";
import ContinueButton from "../../components/buttons/continueButton";
import ErrorMessage from "../../components/errorMessage";
import SearchSelect from "../../components/forms/searchSelect";
import { $api } from "../../services";
import { setAdditionalInfo, setDateAvailable, setLandlordContact, setLocation, setLocationId, setRent, setRoomsNumber, setType, setTypeId } from "../../store/slices/apartmentInfo/apartmentInfoSlice";
import { setApartmentStatus, setApartmentstatusImg, setBasicInfoInitialRequest, setConfirmationStatus, setConfirmationstatusImg } from "../../store/slices/progressBar/progressBarSlice";
import { setRequestPending } from "../../store/slices/request/requestSlice";
import { RootState } from "../../store/store";


const ApartmentInfo = ():JSX.Element => {
    const router = useNavigate();

    const progressBar = useSelector((state:RootState) => state.progressBar )
    const apartment = useSelector((state:RootState) => state.apartment);

    const [ types, setTypes ] = useState([]);

    const [ triedToSubmit, setTriedToSubmit ] = useState(false)

    const locationRef = useRef<HTMLInputElement>(null);

    const [ apartmentTypeValid, setApartmentTypeValid ] = useState(false);
    const [ apartmentTypeFocus, setApartmentTypeFocus ] = useState(false);

    const [ rentValid, setRentValid ] = useState(false)
    const [ rentFocus, setRentFocus ] = useState(false)

    const [ roomsNumberValid, setRoomsNumberValid ] = useState(false)
    const [ roomsNumberFocus, setRoomsNumberFocus ] = useState(false)

    const [ landlordContactValid, setLandlordContactValid ] = useState(false)
    const [ landlordContactFocus, setLandlordContactFocus ] = useState(false)

    const [ availableAtValid, setAvailableAtValid ] = useState(false)
    const [ availableAtFocus, setAvailableAtFocus ] = useState(false)

    const [ queryModalActive, setQueryModalActive ] = useState<boolean>(false)

    const basicInfo = useSelector((state: RootState) => state.basicInfo )

    const [ pageValid, setPageValid ] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = () => {
        pageValid 
            ? 
        handleValidSubmit()
            : 
        setTriedToSubmit(true)
    }

    useEffect(()=> {
        dispatch(setBasicInfoInitialRequest(true)) 
        fetchTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const fetchTypes = async() => {
        const response = await $api.fetch('form-data/all-types')
        if($api.isSuccessful(response)){
            setTypes(response?.data)
        }
        else{
        }
    }

    const Today = new Date();

    var month: string | number = Today.getMonth() + 1;
    var day: string | number = Today.getDate();
    var year = Today.getFullYear();
    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();
    const TodayDate = new Date(year + '-' + month + '-' + day)

    const handleValidSubmit = async() => {
        dispatch(setRequestPending(true))
        const response = await $api.post('v2/lv/list/apartment-info',{
            location_id: apartment.location.id,
            user_id: basicInfo.id,
            type_id: apartment.type.id,
            room: apartment.roomsNumber,
            rent: apartment.rent.replace(/\D/g,''),
            landlord_contact: apartment.landlordContact,
            rent_expire: apartment.availableAt,
            more_details: apartment.additionalInfo
        })
        if ($api.isSuccessful(response)){
            dispatch(setApartmentstatusImg(COMPLETE_APARTMENT_ICON)); 
            dispatch(setRequestPending(false));
            dispatch(setApartmentStatus(2));
            router('/list/confirmation'); 
            progressBar.apartmentInfoStatus.status < 2 
            && dispatch(setConfirmationStatus(1))
            && dispatch(setConfirmationstatusImg(ACTIVE_CONFIRMATION_ICON))
        }
        else{
            dispatch(setRequestPending(false));
            if(!basicInfo.id){
                toast.error('Restart the lisiting')
            }
            else{
                toast.error('Network error')
            }
        }
    }

    useEffect(() => {
        apartment.type ? setApartmentTypeValid(true) : setApartmentTypeValid(false)
        apartment.roomsNumber ? setRoomsNumberValid(true) : setRoomsNumberValid(false)
        apartment.rent !== '' ? setRentValid(true) : setRentValid(false)
        apartment.landlordContact?.length === 14 ? setLandlordContactValid(true) : setLandlordContactValid(false)
        apartment.availableAt !== undefined ? setAvailableAtValid(true) : setAvailableAtValid(false)
    }, [ apartment.location, apartment.type, apartment.roomsNumber, apartment.rent, apartment.landlordContact, apartment.availableAt ]);

    useEffect(() => {
        apartment.location && apartmentTypeValid && roomsNumberValid && rentValid && landlordContactValid && availableAtValid ? setPageValid(true) : setPageValid(false)
    },[ apartment.location, apartmentTypeValid, roomsNumberValid, rentValid, landlordContactValid, availableAtValid  ])
    

    const handleClick = (e:any) => {
        if (queryModalActive && locationRef.current && !locationRef.current.contains(e.target)) {
            setQueryModalActive(false);
        }
    }

    return(
        <div className="apartment-wrapper" onClick={(e) => handleClick(e)}>
            <div className="apartment-wrapper__form">
                <div className="apartment-wrapper__form__input">
                    <label htmlFor="location">Apartment Location</label>
                    <SearchSelect placeholder="select a location" reference={locationRef} setQueryModalActive={setQueryModalActive} queryModalActive={queryModalActive}  location={apartment.location.name || ''} 
                        setLocationId={
                            (value: any) => {
                                dispatch(setLocationId(value))
                            }
                        }
                        setLocation={
                            (value: any) =>{
                                dispatch(setLocation(value))
                            }
                        } 
                        triedToSubmit={triedToSubmit}
                    />
                    { triedToSubmit && !apartmentTypeValid && !apartmentTypeFocus &&  <ErrorMessage message= 'Enter The Apartment Type'/>}
                </div>
                <div className="apartment-wrapper__form__input">
                    <label htmlFor="work_email">Apartment type</label>
                    <select onFocus={() => setApartmentTypeFocus(true)} onBlur={() => setApartmentTypeFocus(false)}
                        className={ 
                            apartment.type === undefined 
                            ? 
                            triedToSubmit 
                                ? 
                                'invalid undefined' 
                                : 
                                'undefined'
                            :
                            ''
                        }
                        onChange = { (e) => { 
                            dispatch(setType(e.target.options[e.target.selectedIndex].innerHTML))
                            dispatch(setTypeId(e.target.value))
                        }}
                        value={apartment.type.id ? apartment.type.id : 'select an option'}
                        defaultValue={undefined}
                    >
                        <option disabled style={{ color: '#616E9E'}}>select an option</option>
                        {
                            types.length > 0
                                ?
                                types.map((type:any) => {
                                    return(
                                        <option key={type.id} value={type.id}>{type.name}</option>
                                    )
                                })
                                :
                                <option disabled>No result</option>
                        }
                    </select>
                    { triedToSubmit && !apartmentTypeValid && !apartmentTypeFocus &&  <ErrorMessage message= 'Enter The Apartment Type'/>}
                </div>
                <div className="apartment-wrapper__form__input rooms-number">
                    <label htmlFor="rooms-number">Number of rooms</label>
                    <select 
                        onFocus={() => setRoomsNumberFocus(true)} 
                        onBlur={() => setRoomsNumberFocus(false)} 
                        onChange = { (e) => dispatch(setRoomsNumber(parseInt(e.target.value)))}
                        className={ 
                            apartment.roomsNumber === undefined 
                            ? 
                            triedToSubmit 
                                ? 
                                'invalid undefined' 
                                : 
                                'undefined'
                            :
                            ''
                        }
                        id="rooms-number"
                        value={apartment.roomsNumber ? apartment.roomsNumber : 'select an option'}
                        defaultValue={undefined}
                    >
                        <option disabled value={undefined}>select an option</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                        <option value={13}>13</option>
                        <option value={14}>14</option>
                        <option value={15}>15</option>
                    </select>
                    { triedToSubmit && !roomsNumberFocus && !roomsNumberValid &&  <ErrorMessage message= 'Select the number of rooms in the apartment'/>}
                </div>
                <div className="apartment-wrapper__form__input right-inputs">
                    <label htmlFor="rent">Rent</label>
                    <div className={ triedToSubmit && !rentValid ? 'invalid rent' : 'rent'}>
                        <p style={{ marginRight: '7px'}}>₦</p>
                        <input
                            type="text"
                            placeholder="800,000"
                            id="rent"
                            spellCheck={false}
                            value={apartment.rent || ''}
                            onChange={(e) => { dispatch(setRent(e.target.value)) }}
                            onFocus={() => setRentFocus(true)}
                            onBlur={() => setRentFocus(false)}
                        />
                    </div>
                    { triedToSubmit && !rentValid && !rentFocus && <ErrorMessage message="Enter the apartments rent"/>}
                </div>
                <div className="apartment-wrapper__form__input rooms-number" >
                    <label htmlFor="landlord-contact">Landlord or caretakers contract</label>
                    <PhoneInputWithCountrySelect
                        defaultCountry="NG"
                        value={apartment.landlordContact || ''}
                        onChange={(value) => { dispatch(setLandlordContact(value)) }}
                        placeholder="08177886674"
                        className={ triedToSubmit && !landlordContactValid ? 'invalid' : ''}
                        onFocus={() => setLandlordContactFocus(true)}
                        onBlur={() => setLandlordContactFocus(false)}
                        id='landlord-contact'
                    />
                    { triedToSubmit && !landlordContactValid && !landlordContactFocus && <ErrorMessage message={ apartment.landlordContact === undefined ? "Enter caretakers phone number" : "Enter vaalid phone number" }/>}
                </div>
                <div className="apartment-wrapper__form__input right-inputs">
                    <label htmlFor="move-date">Move out date</label>
                    <div className={ triedToSubmit && !availableAtValid ? 'invalid move-date' : 'move-date'}>
                        <DatePicker
                            minDate={TodayDate}
                            value={new Date()}
                            onChange={(value: any) => {
                                const date = new Date(value).toLocaleDateString()
                                dispatch(setDateAvailable(date))
                            }}
                            format={"y-mm-dd"}
                        />
                        {/* <input 
                            type='date'
                            min={TodayDate}
                            value={apartment.availableAt}
                            onChange={(e) => { dispatch(setDateAvailable(e.target.value)) }}
                        /> */}
                        <img src={DATE_ICON} alt=""/>
                        <input
                            type="text"
                            placeholder="Enter the move out date"
                            id="move-date"
                            spellCheck={false}
                            value={apartment.availableAt}
                            readOnly
                            onFocus={() => setAvailableAtFocus(true)}
                            onBlur={() => setAvailableAtFocus(false)}
                        />
                    </div>
                    { triedToSubmit && !availableAtValid && !availableAtFocus && <ErrorMessage message="Enter the apartments move out date"/>}
                </div>
                <div className="apartment-wrapper__form__no-email">
                    <div className="apartment-wrapper__form__no-email__text-area">
                        <label htmlFor="nin">Additional information</label>
                        <textarea 
                            onChange={(e) => { dispatch(setAdditionalInfo(e.target.value))}}
                            spellCheck={false}
                            value={apartment.additionalInfo || ''}
                            placeholder="Any other details worth mentioning?"
                        >
                        </textarea>
                    </div>
                </div>
                <ContinueButton onClick={() => handleSubmit()}/>
            </div>
        </div>
    )
}
export default ApartmentInfo;