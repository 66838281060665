import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";


const ContinueButton = (props:React.HTMLAttributes<HTMLButtonElement>):JSX.Element => {
    const request = useSelector((state:RootState) => state.request)
    return(
        <button {...props} className="continue-button">
            {
                !request.pending
                    ?
                "Continue"
                :
                <div className="loading"></div>
            }
        </button>
    )
}
export default ContinueButton;