import { STACS, STERLING, ZILLA } from "../assets/image";
import PageLayout from "../layout/pageLayout";

const MonthlyRentInfo = () => {
    return(
        <PageLayout>
            <div className="monthly-rent-info">
                <div className="monthly-rent-info__main">
                    <div className="monthly-rent-info__main__top">
                        <h2>Monthly Rent Financing Options</h2>
                        <p className="text-center">
                            Finances should not be what stops you from experiencing the best of Lagos living.
                            You deserve a liveable apartment and our financial partners are here to make it happen.
                        </p>
                    </div>
                    <div className="monthly-rent-info__option-wrapper">
                        <div className="monthly-rent-info__option">
                            <div className="monthly-rent-info__option__logo-container">
                                <img src={STERLING} alt=""/>
                                <div>
                                    <h3>Sterling Bank [One Home]</h3>
                                    <p>
                                        Sterling Bank is a top financial institution offering renters manageable 
                                        monthly installments via One Home, a cost efficient living experience.
                                    </p>
                                    <div>
                                        <h4>Features</h4>
                                        <ul>
                                            <li>Pay on your terms: choose to pay in 3 months, 6 months, 9, or 12 months</li>
                                            <li>Reducing Balance repayment: Outstanding principal and interest will reduce as loan is repaid</li>
                                            <li>Zero Equity Contribution: No down payment is required; however it is encouraged. </li>
                                            <li>No limit on rent amount accessible.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="monthly-rent-info__even-option" id="zilla">
                            <div className="monthly-rent-info__option">
                                <div className="monthly-rent-info__option__logo-container">
                                    <img src={ZILLA} alt=""/>
                                    <div>
                                        <h3>Zilla Africa Rent Financing</h3>
                                        <p>
                                            Zilla Africa is a fintech company offering simple, transparent, and flexible rent finance options.
                                        </p>
                                        <div>
                                            <h4>Features</h4>
                                            <ul>
                                                <li>Maximum rent amount accessible is ₦3,000,000 </li>
                                                <li>Maximum duration is 12 months</li>
                                                <li>Equity Contribution Required: Renter provides a minimum of 30% rental value as equity contribution while Zilla finances the balance.</li>
                                                <li>Rate is set at 4.5% monthly for the duration of the loan</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="monthly-rent-info__option" id="stacs">
                            <div className="monthly-rent-info__option__logo-container">
                                <img src={STACS} alt=""/>
                                <div>
                                    <h3>Stacs</h3>
                                    <p>
                                        Stacs is making adulting easier by providing simple, transparent, 
                                        and flexible rent loans and credits to the Nigerian working class.
                                    </p>
                                    <div>
                                        <h4>Features</h4>
                                        <ul>
                                            <li>Rent approvals within 24 hours</li>
                                            <li>Equity Contribution of 48.5% is required [down payment is required]</li>
                                            <li>Interest available on your savings in Stacs Vault</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="monthly-rent-info__faq-wrapper">
                        <div className="monthly-rent-info__faq">
                            <div><h2>FAQ</h2></div>
                            <div>
                                <h4>Q: Do I need to have an account with a financial partner?</h4>
                                <p>A: Yes, an account is opened specifically for the repayment and disbursement of rent.</p>
                            </div>
                            <div>
                                <h4>Q: Does my salary account have to be domiciled with them?</h4>
                                <p>A:No, it does not.</p>
                            </div>
                            <div>
                                <h4>Q: Is the interest rate fixed and the same?</h4>
                                <p>A:The interest rates are fixed by our financial partners and vary according to each.</p>
                            </div>
                            <div>
                                <h4>Q: Is a monthly rent option more expensive than a full rent payment?</h4>
                                <p>A: Yes, Monthly rent is slightly more expensive than the full rent payment due to interest charge on rent.</p>
                            </div>
                            <div>
                                <h4>Q: How long do I pay?</h4>
                                <p>A: Typically, tenants can pay up for 12 months. However, it can be structured based on individual preference and agreement.</p>
                            </div>
                            <div>
                                <h4>Q: Are utilities/service charges included in monthly rent?</h4>
                                <p>A: It depends on the terms of the rental agreement. Our financial partners can include the service charge alongside the rent amount required.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}
export default MonthlyRentInfo;