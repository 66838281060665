import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { setBookingId, setBookingStatus } from "../store/slices/booking/bookingSlice"
import { RootState } from "../store/store"

interface IRouteToCurrentStatus {
    children: React.ReactNode,
}
const RouteToCurrentStatus = ({children}: IRouteToCurrentStatus) => {
    const booking = useSelector((state: RootState) => state.booking);
    const router = useNavigate();
    const location = useLocation();
    const currentRoute = location.pathname;
    const dispatch = useDispatch();

    useEffect(() => {
        booking.bookingStatus === 'booking' &&  !currentRoute.includes('/booking') && router(`/booking/${booking.id}`)
        booking.bookingStatus === 'booking-confirmed' && !currentRoute.includes('/booking-confirmed') && router(`/booking-confirmed/${booking.id}`);
        ( booking.bookingStatus === 'verification-form-pending' || booking.bookingStatus === 'verification-form-in-progress' || booking.bookingStatus === 'verification' ) && !currentRoute.includes('/verify') && router(`/verify/${booking.id}`);
        ( booking.bookingStatus === 'verification-form-completed' || booking.bookingStatus === 'ongoing-verification') && !currentRoute.includes('/verification-in-progress') && router(`/verification-in-progress/${booking.id}`);
        ( booking.bookingStatus === 'verification-failed' || booking.bookingStatus === 'verification-restart' ) && !currentRoute.includes('/verification-failed') && router(`/verification-failed/${booking.id}`);
        booking.bookingStatus === 'verification-completed' && !currentRoute.includes('/selection') && router(`/selection/${booking.id}`)
        booking.bookingStatus === 'selected' && !currentRoute.includes('/payment') && router(`/payment/${booking.id}`)
    },[ booking.bookingStatus, booking.id, router, currentRoute ])

    useEffect(() => {
        return () => {
            dispatch(setBookingId(undefined))
            dispatch(setBookingStatus(''))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <div>
            {children}
        </div>
    )
}
export default RouteToCurrentStatus;